/**
 * @React
 */
import {
    FC,
} from "react";
/**
 * @Router
 */
import { useParams } from 'react-router-dom';

/**
 * @MUI
 */
import {
    CardContent,
    Card,
    Alert
} from "@mui/material";
/**
 * @Hooks
 */
import { useFetchData } from "../../../hooks";
/**
 * @lodash
 */
import _ from 'lodash';
/**
 * @Components
 */
import Loader from "../../../components/Loader";
import MonitoringCgsForm from "../../../components/FormComponent/MonitoringCgsFormComponent";
import MonitoringCgsDetails from "../../../components/detailedApplication/MonitoringApplication/MonitoringCgsDetails";
/**
 * @Types
 */
import {
    IMonitoringAgreementCgsModel,
    IMonitoringCgsFields
} from "../../../types";
/**
 * @Utilities
 */
import { generateUrl } from "../../../utilities/generateRequestUrl";
import config from "../../../utilities/generateRequestUrl/config";
import { monitoring_application_cgs_initial_data } from "../../../utilities/Fields/CgsMonitoringFields/init";
/**
 *
 * @Context
 */
import { useGeneralInfoContext } from "../../../context";
import { monitoringAgreementCgsDetailsFields } from "../../../utilities/Fields/CgsMonitoringFields/fields/detailFields";
import CardsLayout from "../../../components/CustomComponents/CardsLayout";
import MessageBox from "../../../components/CustomComponents/MessageBox";


const MonitoringCgsUpdateForm: FC = () => {
    const { id } = useParams<{ id: string }>();
    const { data, load, error } = useFetchData<IMonitoringCgsFields & Partial<IMonitoringAgreementCgsModel> | null>(generateUrl(config.client, 'getApplicantCgs', { id: id }, null));
    let {
        options: GeneralInfo,
        load: OptionsLoad,
        error: OptionsError,
        generatedValues,
    } = useGeneralInfoContext();
    if (load) {
        return <Loader />;
    }
    if (error || OptionsError) {
        return (<MessageBox
            alignHorizontal="center"
            alignVertical="center"
        >
            <Alert severity="error">
                დაფიქსირდა შეცდომა, გთხოვთ, მიმართოთ საიტის ადმინისტრატორს
            </Alert>
        </MessageBox>)
    }
    return (
        <>
            {
                (load || OptionsLoad)
                    ?
                    <Loader />
                    :
                    <>
                        {
                            data
                            &&
                            GeneralInfo
                            &&
                            generatedValues
                            &&
                            <CardsLayout>
                                {
                                    generatedValues
                                        &&
                                        data?.contract
                                        ?
                                        <MonitoringCgsDetails
                                            data={data.contract as any || null}
                                            generatedValues={generatedValues}
                                            loading={load}
                                            title={''}
                                            monitoringCgsDetailsFields={monitoringAgreementCgsDetailsFields}
                                        />
                                        :
                                        <MessageBox
                                            alignHorizontal="center"
                                            alignVertical="center"
                                        >
                                            <Alert severity="error">
                                                დაფიქსირდა შეცდომა, გთხოვთ, მიმართოთ საიტის ადმინისტრატორს
                                            </Alert>
                                        </MessageBox>
                                }
                                <MonitoringCgsForm
                                    data={_.merge({}, monitoring_application_cgs_initial_data, data)}
                                    load={load}
                                    url='updateApplicationCgs'
                                    opts={GeneralInfo}
                                />
                            </CardsLayout>
                        }
                    </>

            }

        </>
    )
}

export default MonitoringCgsUpdateForm;
