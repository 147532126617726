/**
 * @React
 */
import { FC } from "react";
/**
 * @MUI
 */
import {
    Card,
    CardContent,
    Typography,
    Box,
} from "@material-ui/core";
import { Divider } from "@mui/material";
/**
 * @Types
 */
import {
    IDifferenceFromAgreement,
    IMonitoringAgreementCgsModel,
} from "../../../../types";
/**
 * @Components
 */
import TableTDListList from "../../../CustomComponents/TablesCompoent/Detail/DetailTDList";
import MonitoringLogsDetails from "../MonitoringLogsDetailsCgs";
import MonitoringApprovalHistory from "../MonitoringApprovalHistoryCgs";
import DetailsLayout from "../../../CustomComponents/DetailPageLayout";
import CardComponent from "../../../CustomComponents/CardComponent";
import DetailTableTR from "../../../CustomComponents/TablesCompoent/Detail/DetailTableTR";
import FilesContainer from "../../../CustomComponents/FilesContainer";
/**
 * @Utilities
 */
import { IMonitoringCgsFields } from "../../../../types/monitoringCgsFields";
import DetailePageStyles from "../../../../utilities/DetailePageStayles";
import { monitoringValueMappers } from "../../../../utilities/Fields/CgsMonitoringFields/monitoringDeps";
import { getMappedValue } from "../../../../utilities/mappers";
import { fieldsCgs, valueFormatCgs } from "../../../../utilities/Fields/CgsMonitoringFields/fields/changesFields";

interface MonitoringCgsDetailsProps {
    data: IMonitoringCgsFields & Partial<IMonitoringAgreementCgsModel>;
    loading: boolean;
    generatedValues?: any;
    title?: string;
    monitoringCgsDetailsFields?: any;
}

const MonitoringCgsDetails: FC<MonitoringCgsDetailsProps> = ({
    data,
    generatedValues,
    title,
    loading,
    monitoringCgsDetailsFields }: MonitoringCgsDetailsProps) => {
    const classes = DetailePageStyles();
    const propertyMappings = {
        project_activities: ["activity_code", "activity_description"],
        actual_municipality: [`region`, `municipality`],
        bank: ["name"],
    };
    const mappingPropertyToOtherProperty = {
        project_activities: "nace_activities",
        actual_municipality: "municipalities",
        bank: "banks",
    };

    const visibilityConditions = {
        is_female_entrepreneur_checked: data?.is_female_entrepreneur_checked, /// ???
        is_female_entrepreneur: data?.is_female_entrepreneur_checked, /// ???
        female_entrepreneur_comment: data?.is_female_entrepreneur_checked,
        is_mountain_region: data?.is_mountain_region_checked, /// ???
        is_mountain_region_checked: data?.is_mountain_region_checked, /// ???
        mountain_region_comment: data?.is_mountain_region_checked,
        // income_to_k_comment: data?.is_income_to_k_checked,
        is_sun_gate_beneficiary: data?.is_sun_gate_beneficiary_checked, /// ???
        is_sun_gate_beneficiary_checked: data?.is_sun_gate_beneficiary_checked, /// ???
        sun_gate_beneficiary_comment: data?.is_sun_gate_beneficiary_checked,
        is_purpose_detailed_provided: data?.is_purpose_detailed_provided, /// ???
        purpose_detailed: data?.is_purpose_detailed_provided,
        purpose: data?.is_purpose_detailed_provided,
        purpose_detailed_comment: data?.is_purpose_detailed_provided,
        project_activities: data?.is_project_activities_checked,
        is_project_activities: data?.is_project_activities_checked,
        is_project_activities_checked: data?.is_project_activities_checked,
        project_activities_comment: data?.is_project_activities_checked,
        is_purchased_activities_checked: data?.is_purchased_activities_checked,
        purchased_activities_comment: data?.is_purchased_activities_checked,
    }
    const shouldHideField = (fieldName: keyof typeof visibilityConditions) => {
        return visibilityConditions[fieldName] === true;
    };


    const gfn = (fieldName: string) => {
        const field = mappingPropertyToOtherProperty[fieldName as keyof typeof mappingPropertyToOtherProperty];
        if (field) {
            const dataSource = generatedValues[field];
            const properties = propertyMappings[fieldName as keyof typeof propertyMappings];
            const dataValue = data[fieldName as keyof (IMonitoringCgsFields & Partial<IMonitoringAgreementCgsModel>)];
            if (dataSource && properties && dataValue) {
                if (fieldName === "bank") {
                    const value = dataSource[dataValue as string][properties[0]] || `ბაზაში არ იძებნება (${dataValue})`;
                    return value;
                }
                if (fieldName === "project_activities" && Array.isArray(dataValue)) {
                    const values = dataValue?.map((activity: any) => (
                        `${dataSource[activity][properties[0]] || 'ბაზაში აქტივობა არ იძებნება'} - ${dataSource[activity][properties[1]] || `${activity}`}`
                    ));
                    return values;
                }
                if (fieldName === "actual_municipality") {
                    return [
                        {
                            label: 'რეგიონი',
                            value: dataSource[dataValue as keyof typeof dataSource][properties[0]] || 'ბაზაში არ იძებნება რეგიონი/მუნიციპალიტეტი'
                        },
                        {
                            label: 'მუნიციპალიტეტი',
                            value: dataSource[dataValue as keyof typeof dataSource][properties[1]] || 'ბაზაში არ იძებნება ასეთი რეგიონი/მუნიციპალიტეტი'
                        }
                    ];
                }

            }

        }
        return null
    }
    return (
        <>
            <DetailsLayout
                title={title}
                leftContent={
                    <>
                        {
                            monitoringCgsDetailsFields.map((group: any) => {
                                return (
                                    <CardComponent
                                        title={group.groupLabel}
                                        cardVariant="outlined"
                                    >
                                        {
                                            group.fields.map((field: any) => {
                                                if (shouldHideField(field.name)) {
                                                    return null;
                                                }
                                                if (field.name === "actual_municipality") {
                                                    const actual_municipality = gfn(field.name) as { label: string; value: string }[];
                                                    return (
                                                        <>
                                                            <table className={classes.table}>
                                                                <tbody>
                                                                    {actual_municipality.map(({ label, value }: { label: string; value: string }) => (
                                                                        <DetailTableTR
                                                                            label={label}
                                                                            loading={loading}
                                                                        >
                                                                            {value}
                                                                        </DetailTableTR>))}
                                                                </tbody>
                                                            </table >
                                                        </>
                                                    );
                                                }
                                                if (field.name === "project_activities") {
                                                    const activites = gfn(field.name) as string[] || [];
                                                    return (
                                                        <table className={classes.table}>
                                                            <tbody>
                                                                <DetailTableTR
                                                                    label={field.label}
                                                                    loading={loading}
                                                                >
                                                                    <TableTDListList items={activites} />
                                                                </DetailTableTR>
                                                            </tbody>
                                                        </table >
                                                    );
                                                }
                                                if (field.name === "bank") {
                                                    const bank = gfn(field.name) as string;
                                                    return (
                                                        <table className={classes.table}>
                                                            <tbody>
                                                                <DetailTableTR
                                                                    label={field.label}
                                                                    loading={loading}
                                                                >
                                                                    {bank}
                                                                </DetailTableTR>
                                                            </tbody>
                                                        </table >
                                                    );
                                                }
                                                return (
                                                    <table className={classes.table}>
                                                        <tbody>
                                                            {<DetailTableTR
                                                                label={field.label}
                                                                loading={loading}
                                                            >
                                                                <>
                                                                    {
                                                                        getMappedValue(field, data, monitoringValueMappers)
                                                                    }
                                                                </>
                                                            </DetailTableTR>}
                                                        </tbody>
                                                    </table >
                                                );
                                            })

                                        }
                                    </CardComponent>)
                            })}
                    </>
                }
            />
            {
                data
                &&
                data?.attached_documents
                &&
                data?.attached_documents?.monitoring_attachments.length > 0
                &&
                <Card className={classes.component} variant="outlined">
                    <CardContent>
                        <Typography className={classes.cardSummary}>{"ფაილები/ფოტოები"}</Typography>
                        <>

                            <FilesContainer
                                FilesList={data?.attached_documents.monitoring_attachments}
                                mode={'view'}
                                cols={6}
                            />

                        </>

                    </CardContent>
                </Card>
            }
            {
                data
                &&
                data?.difference_from_agreement
                &&
                <Card className={classes.component} variant="outlined">
                    <CardContent>
                        <Typography className={classes.cardSummary}>{"განაცხადის ცვლილების ისტორია"}</Typography>
                        <>
                            <MonitoringLogsDetails
                                changedDataHistory={data?.difference_from_agreement ?? [] as IDifferenceFromAgreement[]}
                                valueFormat={valueFormatCgs}
                                fields={fieldsCgs}
                            />
                            <>
                                <br />
                                <br />
                                <br />
                                <Divider />
                                <br />
                                {
                                    data.monitoring_approval_history
                                    &&
                                    data.monitoring_approval_history.length > 0
                                    &&
                                    <MonitoringApprovalHistory
                                        approvalHistory={data.monitoring_approval_history}
                                        generateValueFormat={valueFormatCgs}
                                    />
                                }
                            </>

                        </>

                    </CardContent>
                </Card>
            }


        </>
    );
}

export default MonitoringCgsDetails;
