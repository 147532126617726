import "./SideBar.css";
import { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Drawer, Toolbar, List, Divider, ListItem, ListItemIcon, ListItemText, IconButton, AppBar } from "@material-ui/core";
import { Link } from "react-router-dom";
import PeopleIcon from "@material-ui/icons/People";
import MenuIcon from "@material-ui/icons/Menu";
import AddIcon from "@material-ui/icons/Add";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import TransitEnterexitIcon from "@material-ui/icons/TransitEnterexit";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { makeStyles } from "@material-ui/core/styles";
import SummarizeIcon from "@mui/icons-material/Summarize";
import NoteAddIcon from "@mui/icons-material/NoteAdd";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    flexShrink: 0,
    [theme.breakpoints.between("md", "lg")]: {
      width: 210,
    },
    // for print, hide sidebar
    "@media print": {
      display: "none !important",
    },
  },
  drawerPaper: {
    width: 240,
    backgroundColor: "#2c5b94",
    [theme.breakpoints.between("md", "lg")]: {
      width: 210,
    },
  },
  list: {
    color: "#e0e0e0",
  },
  logo: {
    width: "80%",
    margin: "30px auto 0px auto",
  },
}));

function SideBar() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const classes = useStyles();
  let history = useHistory();
  const userRole = localStorage.getItem("role");
  // prettier-ignore
  const menuItems = [
    ...userRole !== "ROLE_MONITORING" && userRole !== "ROLE_MONITORING_CGS" ? [{ name: "მთავარი გვერდი", link: "/", icon: <FindInPageIcon /> }] : [],
    ...userRole === "ROLE_BANK" ? [{ name: "სესხის დამატება", link: "/add-application", icon: <AddIcon /> }] : [],
    ...userRole === "ROLE_AGENCY" || userRole === "ROLE_MONITORING" || userRole === "ROLE_MONITORING_CGS" || (JSON.parse(localStorage.getItem("permissions")).includes("ADD_USER") && localStorage.getItem("role") === "ROLE_BANK")
      ? [{ name: "რაპორტი", link: "/report", icon: <AssessmentIcon /> }] : [],
    ...userRole !== "ROLE_BANK" ? [{ name: "მონიტორინგი", link: "/monitoring", icon: <SummarizeIcon /> }] : [],
    ...(userRole === "ROLE_MONITORING" ? [{ name: "მონიტორინგის ფორმა", link: "/monitoring-form", icon: <NoteAddIcon /> }] : [])
  ];

  const subMenuItems = [{ name: "მომხმარებლები", link: "/users", icon: <PeopleIcon /> }];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  function logout() {
    if (!window.confirm("გსურთ გასვლა?")) return;
    localStorage.clear();
    history.push("/");
    window.location.reload();
  }

  const isMobile = window.innerWidth <= 600;
  const location = useLocation();
  return (
    <>
      {isMobile && (
        <AppBar position="fixed">
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <Drawer
        className={classes.drawer}
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile && mobileOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <img src="/logo.png" alt="logo" className={classes.logo} />
        <Toolbar className={classes.toolbarWrapper} />
        <div>
          <List className={classes.list}>
            {menuItems.map((item, index) => (
              <ListItem button key={index} selected={location.pathname === item.link} component={Link} to={item.link}>
                <ListItemIcon style={{ color: "#e0e0e0" }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List className={classes.list}>
            {subMenuItems.map((item, index) => (
              <ListItem button key={index} selected={location.pathname === item.link} component={Link} to={item.link}>
                <ListItemIcon style={{ color: "#e0e0e0" }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            ))}
            <ListItem button onClick={() => logout()}>
              <ListItemIcon style={{ color: "#e0e0e0" }}>
                <TransitEnterexitIcon />
              </ListItemIcon>
              <ListItemText primary="სისტემიდან გასვლა" />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </>
  );
}

export default SideBar;
