import dateFormat from "dateformat";
export const fieldsCgs: {
  [key: string]: { title: string; type: string };
} = {
  representative_id_number: { title: "წარმომადგენლის პირადი ნომერი", type: "text" },
  actual_address: { title: "სოფელი/ქუჩა, ქუჩის ნომერი", type: "text" }, //?
  actual_municipality: { title: "რეგიონი/ქალაქი/მუნიციპალიტეტი", type: "text" },
  representative_email: { title: "წარმომადგენლის ელ. ფოსტა", type: "text" },
  representative_name: { title: "წარმომადგენლის სახელი/გვარი", type: "text" },
  representative_phone: { title: "წარმომადგენლის ტელეფონი", type: "text" },
  representative_phone_secondary: { title: "დამატებით საკონტაქტო", type: "text" },
  representative_person_email: { title: "წარმომადგენლის ელ. ფოსტა", type: "text" },
  company_email: { title: "კომპანიის საჯარო ელ. ფოსტა", type: "text" },
  company_founder_phone: { title: "დამფუძნებლის ნომერი", type: "text" },
  approved: { title: "დადასტურებული / დახარვეზებული", type: "text" },
  comment: { title: "კომენტარი", type: "text" },
  approved_by: { title: "დაადასტურა / დახარვეზაბა", type: "text" },
  approved_at: { title: "დადასტურების / დახარვეზების თარიღი", type: "date" },
};
export const valueFormatCgs = {
  contract_signed_date: (date: string) => (date ? dateFormat(date, "dd/mm/yyyy HH:MM:ss") : ""),
  jobs_created: (date: string) => (date ? dateFormat(date, "dd/mm/yyyy HH:MM:ss") : ""),
  first_tranche_date: (date: string) => (date ? dateFormat(date, "dd/mm/yyyy HH:MM:ss") : ""),
  start_activity_date: (date: string) => (date ? dateFormat(date, "dd/mm/yyyy HH:MM:ss") : ""),
  approved_at: (date: string) => (date ? dateFormat(date, "dd/mm/yyyy HH:MM:ss") : ""),
  approved: (value: boolean | undefined) => (value ? "დადასტურდა" : value === false ? "დახარვეზდა" : "განსახილველი"),
};
