import React from 'react';
import { Grid } from '@mui/material';

interface ResponsiveLayoutProps {
    leftContent: React.ReactNode;
    rightContent?: React.ReactNode;
    title?: string;
}

const DetailsLayout: React.FC<ResponsiveLayoutProps> = ({ title, leftContent, rightContent }) => {
    return (
        <>

            <Grid container >
                {title && <Grid item xs={12} sm={12} md={12}>
                    <h1 style={{ textAlign: 'center', fontSize: '14px', fontWeight: 700 }}>{title}</h1>
                </Grid>}

                <Grid item xs={12} sm={12} md={rightContent ? 6 : 12}>
                    {leftContent}
                </Grid>
                {rightContent && <Grid item xs={12} sm={12} md={6}>
                    {rightContent}
                </Grid>}
            </Grid>
        </>

    );
};

export default DetailsLayout;