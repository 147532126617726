import { useState, useEffect, useCallback } from "react";
import "./Report.css";
import { GetData } from "../../utilities/GetData";
import {
  Input,
  Card,
  CardHeader,
  CardContent,
  Button,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  LinearProgress,
  FormControlLabel,
  Checkbox,
  Typography,
  Slider,
  Box,
} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { makeStyles } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  card: {
    [theme.breakpoints.between("md", "lg")]: {
      width: "90%",
    },
  },
  badgeApproved: {
    color: "white",
    backgroundColor: "#4E9F3D",
  },
  badgeReview: {
    color: "white",
    backgroundColor: "#C8C6C6",
  },
  filter: {
    marginTop: "25px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    padding: "15px 5px",
    [theme.breakpoints.between("md", "lg")]: {
      padding: "10px 0px",
    },
    border: "3px dotted #f3f3f3",
  },
  filterSelector: {
    minWidth: 120,
    marginRight: 20,
  },
  dateSelector: {
    marginRight: 20,
  },
  field: {
    margin: "0 30px 0 0",
  },
}));

// Reusable Report Card Component
const ReportCard = ({ header, children }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card} style={{ margin: "2rem 0" }}>
      <CardHeader title={header} style={{ borderBottom: "1px solid #f3f3f3" }} />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

const Report = () => {
  const classes = useStyles();
  const [isBank, setIsBank] = useState(false);
  const [isMonitoring, setIsMonitoring] = useState(false);
  const [banks, setBanks] = useState([]);
  const [approvalStatuses] = useState([
    { title: "ყველა", value: "all" },
    { title: "განიხილება", value: "review" },
    { title: "დამტკიცებული", value: "approved" },
    { title: "უარყოფილი", value: "rejected" },
    { title: "გაუქმებული", value: "suspended" },
  ]);
  const [components, setComponents] = useState([
    { title: "იპოთეკა", value: "mortgage" },
    { title: "ძველი იპოთეკა", value: "legacy_mortgage" },
    { title: "უნივერსალური", value: "universal" },
    { title: "ინდუსტრიული", value: "industrial" },
    { title: "უნივერსალური (რისკები)", value: "universal_risk", agency_only: true },
    { title: "მონიტორინგი", value: "monitoring", agency_only: true },
    { title: "მონიტორინგი (მიკრო)", value: "monitoring_cgs", agency_only: true },
    { title: "ყველა", value: "all" },
  ]);
  const currentYear = new Date().getFullYear();
  const defaultStartDate = new Date(currentYear, 0, 1);
  const defaultEndDate = new Date(currentYear, 11, 31);

  const [filterData, setFilterData] = useState({
    component: "all",
    status: "all",
    all_schedules: false,
    refinancing_date_from: defaultStartDate.toISOString().split("T")[0],
    refinancing_date_to: defaultEndDate.toISOString().split("T")[0],
    bank_id: "",
  });

  // For the slider
  const MIN_YEAR = 2020;
  const MAX_YEAR = currentYear + 1;
  const [dateRangeValue, setDateRangeValue] = useState([currentYear, currentYear]);
  const [reportLoading, setReportLoading] = useState(false);
  const [paymentDataLoading, setPaymentDataLoading] = useState(false);

  // Always render the dropzone container
  const onDrop = useCallback((acceptedFiles) => {
    if (window.confirm("გსურთ აიტვირთოთ ფაილი?")) {
      setPaymentDataLoading(true);
      const formData = new FormData();
      formData.append("schedule_payments", acceptedFiles[0]);
      axios
        .post("/agency/upload_payments", formData)
        .then((res) => {
          setPaymentDataLoading(false);
          alert(res.data.message);
        })
        .catch((err) => {
          setPaymentDataLoading(false);
          alert(err.response?.data?.message || "ფაილი ვერ აიტვირთა");
        });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role === "ROLE_BANK") setIsBank(true);
    if (role === "ROLE_MONITORING") {
      setIsMonitoring(true);
      setComponents((prev) => prev.filter((c) => c.value === "monitoring"));
    }
    if (role === "ROLE_MONITORING_CGS") {
      setIsMonitoring(true);
      setComponents((prev) => prev.filter((c) => c.value === "monitoring_cgs"));
    }
    GetData("general_info")
      .then((resp) => {
        const allBanks = [{ name: "ყველა", _id: undefined }, ...resp?.banks];
        setBanks(allBanks);
        setFilterData((prev) => ({ ...prev, bank_id: "" }));
      })
      .catch(() => {});
  }, []);

  // Generic function to download report
  const downloadReport = (endpoint, fileName) => {
    setReportLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}${endpoint}`,
      data: filterData,
      responseType: "blob",
    })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setReportLoading(false);
      })
      .catch((error) => {
        const reader = new FileReader();
        reader.readAsText(error.response.data);
        reader.onload = () => {
          try {
            const res = JSON.parse(reader.result);
            alert(res.message);
          } catch {
            alert("დაფიქსირდა შეცდომა");
          }
        };
        setReportLoading(false);
      });
  };

  const generateReport = () => downloadReport("generate_report", "ApplicationsReport.xlsx");
  const generateSchedulingReport = () => downloadReport("agency/generate_report_scheduling", "ScheduleReport.xlsx");
  const generatePaymentsReport = () => downloadReport("agency/generate_report_payments", "PaymentReports.xlsx");
  const generateAgencyShareReport = () => downloadReport("agency/generate_report_agency_share", "AgencyShareReport.xlsx");
  const generateRefinancingRateReport = () => downloadReport("agency/generate_report_refinancing_rate", "RefinancingRateReport.xlsx");
  const generateLegacyMortgageReport = () => downloadReport("generate_legacy_guarantee_report", "LegacyMortgageReport.xlsx");
  const generateSchedulingLogReport = () => downloadReport("agency/generate_report_scheduling_log", "SchedulingLogReport.xlsx");

  // Reusable filter section component
  const renderFilters = () => (
    <div className={classes.filter}>
      {!isBank && (
        <FormControl className={classes.filterSelector}>
          <InputLabel id="bank-label" shrink>
            ბანკი
          </InputLabel>
          <Select
            labelId="bank-label"
            id="bank-select"
            value={filterData.bank_id}
            onChange={(e) => setFilterData({ ...filterData, bank_id: e.target.value })}
            displayEmpty
            renderValue={(value) => {
              if (value === "" || value === undefined) return "ყველა";
              return banks?.find((bank) => String(bank._id) === String(value))?.name || "ყველა";
            }}
          >
            {banks?.map((bank, index) => (
              <MenuItem key={index} value={bank._id}>
                {bank.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <FormControl className={classes.filterSelector} style={{ display: isMonitoring ? "none" : "block" }}>
        <InputLabel id="status-label">სტატუსი</InputLabel>
        <Select labelId="status-label" id="status-select" value={filterData.status} onChange={(e) => setFilterData({ ...filterData, status: e.target.value })}>
          {approvalStatuses.map((status, idx) => (
            <MenuItem key={idx} value={status.value}>
              {status.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.dateSelector}>
        <InputLabel shrink>თარიღი (დან)</InputLabel>
        <Input type="date" onChange={(e) => setFilterData({ ...filterData, date_from: e.target.value })} />
      </FormControl>
      <FormControl className={classes.dateSelector}>
        <InputLabel shrink>თარიღი (მდე)</InputLabel>
        <Input type="date" onChange={(e) => setFilterData({ ...filterData, date_to: e.target.value })} />
      </FormControl>
      <FormControl className={classes.filterSelector}>
        <InputLabel id="component-label" shrink>
          კომპონენტი
        </InputLabel>
        <Select
          labelId="component-label"
          id="component-select"
          value={filterData.component}
          onChange={(e) => setFilterData({ ...filterData, component: e.target.value })}
        >
          {components.map((comp) => {
            if (comp.agency_only && isBank) return null;
            return (
              <MenuItem key={comp.value} value={comp.value}>
                {comp.title}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControlLabel
        style={{ display: isMonitoring ? "none" : "block" }}
        control={
          <Checkbox
            checked={filterData.all_schedules}
            onChange={(e) => setFilterData({ ...filterData, all_schedules: e.target.checked })}
            value="all_schedules"
          />
        }
        label="ყველა მოთხოვნილი თვე"
      />
    </div>
  );

  return (
    <>
      <Card className={classes.card}>
        <CardHeader title="რაპორტის გენერირება" style={{ borderBottom: "1px solid #f3f3f3" }} />
        <CardContent>
          {renderFilters()}
          <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
            <Button
              style={{ marginRight: 30 }}
              disabled={reportLoading}
              color="primary"
              variant="contained"
              startIcon={<CloudDownloadIcon />}
              onClick={generateReport}
            >
              ჩამოტვირთვა
            </Button>
            {isBank || (!isBank && localStorage.getItem("agency-level") > 0) ? (
              <Button
                disabled={reportLoading || filterData.component === "all" || filterData.component === "universal_risk"}
                style={{ whiteSpace: "nowrap", marginRight: 30 }}
                color="primary"
                variant="contained"
                startIcon={<CloudDownloadIcon />}
                onClick={generateSchedulingReport}
              >
                გადახდების მოთხოვნის შემაჯამებელი რაპორტი
              </Button>
            ) : null}
            {!isBank && (
              <Button
                disabled={reportLoading || filterData.component === "all" || filterData.component === "universal_risk"}
                color="primary"
                style={{ whiteSpace: "nowrap", marginRight: 30, display: isMonitoring ? "none" : "flex" }}
                variant="contained"
                startIcon={<CloudDownloadIcon />}
                onClick={generateAgencyShareReport}
              >
                სააგენტოს წილის შემაჯამებელი რაპორტი
              </Button>
            )}
          </div>
        </CardContent>
        <LinearProgress style={{ margin: "0 auto", display: reportLoading ? "block" : "none" }} />
      </Card>

      {/* Payment Upload Dropbox Section */}
      {!isBank && localStorage.getItem("agency-level") > 0 && (
        <ReportCard
          header={
            <>
              გადარიცხვების ასახვა
              <>
                <Button
                  disabled={reportLoading}
                  style={{
                    float: "right",
                    marginLeft: "auto",
                    marginRight: "1rem",
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                    whiteSpace: "nowrap",
                  }}
                  color="primary"
                  variant="contained"
                  startIcon={<CloudDownloadIcon />}
                  onClick={generatePaymentsReport}
                >
                  გადახდების შემაჯამებელი რაპორტი
                </Button>
                <Button
                  style={{
                    float: "right",
                    marginRight: "1rem",
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                  variant="outlined"
                  color="primary"
                  startIcon={<ListAltIcon />}
                  onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}public/payment_template.xlsx`)}
                >
                  გადახდების შაბლონის ჩამოტვირთვა
                </Button>
              </>
            </>
          }
        >
          {paymentDataLoading ? (
            <LinearProgress />
          ) : (
            // **Always render the dropzone container**
            <div {...getRootProps({ className: "dropbox" })}>
              <input {...getInputProps()} />
              {isDragActive ? <p>ჩააგდეთ ფაილები...</p> : <p>ჩააგდეთ ფაილები ან დააჭირეთ ფაილების ასარჩევად.</p>}
            </div>
          )}
        </ReportCard>
      )}

      {/* Other Report Cards */}
      {!isBank && localStorage.getItem("agency-level") > 0 && (
        <ReportCard
          header={
            <>
              ძველი იპოთეკის გარანტიები
              <Button
                disabled={reportLoading}
                style={{
                  float: "right",
                  marginLeft: "auto",
                  marginRight: "1rem",
                  marginTop: "0.5rem",
                  marginBottom: "0.5rem",
                  whiteSpace: "nowrap",
                }}
                color="primary"
                variant="contained"
                startIcon={<CloudDownloadIcon />}
                onClick={generateLegacyMortgageReport}
              >
                რაპორტის გადმოწერა
              </Button>
            </>
          }
        />
      )}

      {!isBank && localStorage.getItem("agency-level") > 0 && (
        <ReportCard
          header={
            <>
              გრაფიკების სერვისების ისტორია
              <Button
                disabled={reportLoading}
                style={{
                  float: "right",
                  marginLeft: "auto",
                  marginRight: "1rem",
                  marginTop: "0.5rem",
                  marginBottom: "0.5rem",
                  whiteSpace: "nowrap",
                }}
                color="primary"
                variant="contained"
                startIcon={<CloudDownloadIcon />}
                onClick={generateSchedulingLogReport}
              >
                რაპორტის გადმოწერა
              </Button>
            </>
          }
        />
      )}

      {/* Refinancing Report Section, not visible for banks or any kind of monitoring */}
      {!isBank && !isMonitoring && (
        <Card className={classes.card} style={{ margin: "2rem 0" }}>
          <CardHeader title="რეფინანსირების განაკვეთის რაპორტი" style={{ borderBottom: "1px solid #f3f3f3" }} />
          <CardContent>
            <div style={{ display: "flex", alignItems: "center", gap: "20px", marginBottom: "20px" }}>
              {!isBank && (
                <FormControl className={classes.filterSelector}>
                  <InputLabel id="bank-label-refinancing" shrink>
                    ბანკი
                  </InputLabel>
                  <Select
                    labelId="bank-label-refinancing"
                    value={filterData.bank_id}
                    onChange={(e) => setFilterData({ ...filterData, bank_id: e.target.value })}
                    displayEmpty
                    renderValue={(value) => {
                      if (value === "" || value === undefined) return "ყველა";
                      return banks?.find((bank) => String(bank._id) === String(value))?.name || "ყველა";
                    }}
                  >
                    {banks?.map((bank, index) => (
                      <MenuItem key={index} value={bank._id}>
                        {bank.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <FormControl className={classes.filterSelector}>
                <InputLabel id="component-label-refinancing" shrink>
                  კომპონენტი
                </InputLabel>
                <Select
                  labelId="component-label-refinancing"
                  value={filterData.component}
                  onChange={(e) => setFilterData({ ...filterData, component: e.target.value })}
                >
                  {components.map((comp) => {
                    if (comp.agency_only && isBank) return null;
                    return (
                      <MenuItem key={comp.value} value={comp.value}>
                        {comp.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Box sx={{ width: 300, mx: 2 }}>
                <Typography id="date-range-slider" gutterBottom>
                  პერიოდი: {dateRangeValue[0]} - {dateRangeValue[1]}
                </Typography>
                <Slider
                  value={dateRangeValue}
                  onChange={(event, newValue) => {
                    setDateRangeValue(newValue);

                    const startDate = new Date(newValue[0], 0, 1);
                    const endDate = new Date(newValue[1], 11, 31);

                    if (newValue[1] - newValue[0] > 3) {
                      const updatedEndYear = newValue[0] + 3;
                      setDateRangeValue([newValue[0], updatedEndYear]);
                      const updatedEndDate = new Date(updatedEndYear, 11, 31);
                      setFilterData({
                        ...filterData,
                        refinancing_date_from: startDate.toISOString().split("T")[0],
                        refinancing_date_to: updatedEndDate.toISOString().split("T")[0],
                      });
                    } else {
                      setFilterData({
                        ...filterData,
                        refinancing_date_from: startDate.toISOString().split("T")[0],
                        refinancing_date_to: endDate.toISOString().split("T")[0],
                      });
                    }
                  }}
                  valueLabelDisplay="auto"
                  min={MIN_YEAR}
                  max={MAX_YEAR}
                  marks={Array.from({ length: MAX_YEAR - MIN_YEAR + 1 }, (_, i) => {
                    const year = MIN_YEAR + i;
                    return {
                      value: year,
                      label: year.toString(),
                      style: {
                        color: year === currentYear ? "#1976d2" : "inherit",
                        fontWeight: year === currentYear ? "bold" : "normal",
                      },
                    };
                  })}
                />
              </Box>
              <Button
                disabled={reportLoading || !filterData.bank_id || filterData.component === "all" || filterData.component === "universal_risk"}
                color="primary"
                variant="contained"
                startIcon={<CloudDownloadIcon />}
                onClick={generateRefinancingRateReport}
              >
                რაპორტის გადმოწერა
              </Button>
            </div>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default Report;
