import { ReactNode, FC } from "react";
import { GeneralInformationContextProvider } from "../../context";

interface MonitoringCgsFormProviderProps {
  children: ReactNode;
}

const MonitoringCgsProvider: FC<MonitoringCgsFormProviderProps> = ({ children }) => {
  return (
    <GeneralInformationContextProvider>
      {children}
    </GeneralInformationContextProvider>
  );
};

export default MonitoringCgsProvider;
