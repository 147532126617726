/**
 * @React
 */
import {
    FC,
    useEffect,
    useState
} from "react";
/**
 * @Router
 */
import { useParams } from 'react-router-dom';
/**
 * @MUI
 */
import {
    CardContent,
    Card,
    Alert
} from "@mui/material";
/**
 * @Context
 */
import {
    useGeneralInfoContext,
} from "../../../context";

/**
 * @Components
 */
import Loader from "../../../components/Loader";
/**
 * @Types
 */
import {
    IMonitoringFields,
    IMonitoringAgreementModel,
    IFieldGroup,
} from "../../../types";
/**
 * @Utilities
 */
import {
    generateDetailsFields,
} from "../../../utilities/Fields/monitoringFields/utils";

import {
    dependencies,
    fieldValues,
    valueDependencies
} from "../../../utilities/Fields/monitoringFields/monitoringDeps";
import { generateUrl } from "../../../utilities/generateRequestUrl";
import config from "../../../utilities/generateRequestUrl/config";
import { useFetchData } from "../../../hooks";
import { moitoringDetailsFields } from "../../../utilities/Fields/monitoringFields/fields/detailFields";
import {
    MonitoringDetails,
} from "../../../components/detailedApplication/MonitoringApplication";

const MonitoringAgreementDetailPage: FC = () => {
    const { id } = useParams<{ id: string }>();
    const [fields, setFields] = useState<{ updatedFields: any[]; dependencies: any | null } | null>(null);
    const { data, load: loading, error: detailsError } = useFetchData<IMonitoringFields & Partial<IMonitoringAgreementModel> | null>(generateUrl(config.client, 'getAgreementById', { id: id }, null));
    const [bool, setBool] = useState<boolean>(false);
    let {
        options: GeneralInfo,
        setOptions: setGeneralInfoOptions,
        generatedValues: GeneralInfoValues,
        setGeneratedValues: setGeneralInfoValues,
        data: GeneralInfoOptions,
        load: GeneralInfoLoad,
        error: GeneralInfoError,
    } = useGeneralInfoContext();

    useEffect(() => {
        if (data && moitoringDetailsFields && GeneralInfoValues) {
            setBool(true);
            let updated = generateDetailsFields<any, keyof IMonitoringFields & Partial<IMonitoringAgreementModel>>(
                moitoringDetailsFields as IFieldGroup<IMonitoringFields & Partial<IMonitoringAgreementModel>>[],
                data as IMonitoringFields & Partial<IMonitoringAgreementModel>,
                GeneralInfoValues,
                dependencies,
                fieldValues,
                valueDependencies
            );
            setFields(updated);
            setBool(false);
        }
    }, [data, moitoringDetailsFields, GeneralInfoValues]);
    return (
        <>
            {bool || GeneralInfoLoad || loading ? <Loader />
                :
                <Card sx={{ width: "100%", margin: "15px auto 10px auto" }}>
                    <CardContent>
                        <h4 style={{ textAlign: 'center' }}>სააგენტოში დადასტურების ეტაპზე არსებული დეტალური ინფორმაცია</h4>
                        {
                            loading 
                                && 
                                <Loader />
                        }
                        {
                            detailsError && <Alert severity="error">დაფიქსირდა შეცდომა, გთხოვთ, მიმართოთ საიტის ადმინისტრატორს</Alert>
                        }
                        {
                            fields && data && <MonitoringDetails details={fields.updatedFields} dependencies={fields.dependencies} data={data} />
                        }
                    </CardContent>
                </Card>}
        </>
    )
}

export default MonitoringAgreementDetailPage;
