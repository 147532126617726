import { IFieldGroup, IMonitoringAgreementCgsModel } from "../../../../../types";
import { IMonitoringCgsFields } from "../../../../../types/monitoringCgsFields";

const monitoringCgsGroupFieldsInitValues: IFieldGroup<IMonitoringCgsFields & Partial<IMonitoringAgreementCgsModel>>[] = [
  {
    groupName: `actual_municipality_address_cadastral_code_is_building_rented`,
    groupLabel: `ბენეფიციარის საქმიანობის განხორციელების ფაქტობრივი მისამართი`,
    fields: [
      {
        name: "actual_municipality",
        label: "რეგიონი/მუნიციპალიტეტი *",
        type: "autocomplete",
        optionName: "municipalities",
        value: "",
      },
      {
        name: "actual_address",
        label: "მისამართი(სოფელი/დაბა/ქუჩა/ნომერი) *",
        type: "text",
        value: "",
      },
      {
        name: "cadastral_code",
        label: "საკადასტრო კოდი *",
        type: "text",
        value: "",
      },
    ],
  },
  {
    groupName: `representative_name_phone_position_id_number_email`,
    groupLabel: `ბენეფიციარის წარმომადგენელი პირი`,
    fields: [
      {
        name: "representative_name",
        label: "სახელი, გვარი *",
        type: "text",
        value: "",
      },
      {
        name: "representative_phone",
        label: "ტელ. ნომერი *",
        type: "text",
        value: "",
      },
      {
        name: "representative_position",
        label: "პოზიცია *",
        type: "text",
        value: "",
      },
      {
        name: "representative_id_number",
        label: "პ/ნ *",
        type: "text",
        value: "",
      },
      {
        name: "representative_email",
        label: "წარმომადგენლის ელ. ფოსტა *",
        type: "text",
        value: "",
      },
    ],
  },
  {
    groupName: `fixed_assets_capital_expenditures_funds_turnover`,
    groupLabel: `პროექტის ფარგლებში ბენეფიციარის მხრიდან შესრულებული აქტივობები`,
    fields: [
      {
        name: "used_amount",
        label: "ათვისებული თანხის მოცულობა ₾ *",
        disabled: true,
        type: "number",
        value: 0,
      },
      {
        name: "fixed_assets_capital_expenditures",
        label: "ძირითადი საშუალებები/კაპიტალური დანახარჯები",
        disabled: true,
        type: "number",
        value: 0,
      },
      {
        name: "machine_equipment_equipment",
        label: "მანქანა - დანადგარები / აღჭურვილობა",
        type: "number",
        value: 0,
      },
      {
        name: "vehicles_special_technic",
        label: "სატრანსპორტო საშუალებები / სპეც. ტექნიკა",
        type: "number",
        value: 0,
      },
      {
        name: "purchase_building_land",
        label: "შენობა ნაგებობის / მიწის შეძენა",
        type: "number",
        value: 0,
      },
      {
        name: "building_repairs",
        label: "მშენებლობა / რემონტი",
        type: "number",
        value: 0,
      },
      {
        name: "funds_turnover",
        label: "საბრუნავი საშუალებები",
        type: "number",
        value: 0,
      },
      {
        name: "project_activities",
        value: "",
        type: "autocomplete",
        optionName: "nace_activities",
        label: "საქმიანობის სფეროები",
      },
      {
        name: "is_project_activities_checked",
        label: "საქმიანობის სფეროები - აკმაყოფილებს განაცხადში მითითებული პირობას",
        type: "checkbox",
        value: false,
      },
      {
        name: "project_activities_comment",
        label: "არდაკმაყოფილების მიზეზი",
        type: "textarea",
        value: "",
      },
      {
        name: "is_purchased_activities_checked",
        label: `სესხის თანხით არ არის შეძენილი: 
გუდვილი; სატრანსპორტო საშუალებების შეძენა და რემონტი, გარდა სპეციალური საშუალებებისა, რომლებიც უშუალოდ გამოიყენება საწარმოს ტერიტორიაზე პროდუქციის/მომსახურების წარმოების პროცესში; სახელმწიფო ან/და კერძო საკუთრებაში არსებული უძრავი ქონების შეძენა ან/და საქართველოს სამოქალაქო კოდექსით გათვალისწინებული ფორმებით დროებით სარგებლობაში აღება; კერძო სამართლის იურიდიული პირის აქციების ან წილების შეძენა ან მართვაში აღება; საქართველოს კანონმდებლობის შესაბამისად გათვალისწინებული სახდელების (ჯარიმა, საურავი, პირგასამტეხლო და სხვა) გადახდა/დაფარვა.`,
        type: "checkbox",
        value: false,
      },
      {
        name: "purchased_activities_comment",
        label: "არდაკმაყოფილების მიზეზი",
        type: "textarea",
        value: "",
      },
      {
        name: "is_female_entrepreneur_checked",
        label: "ქალი მეწარმე - აკმაყოფილებს განაცხადში მითითებული პირობას",
        type: "checkbox",
        value: false,
      },
      {
        name: "female_entrepreneur_comment",
        label: "არდაკმაყოფილების მიზეზი",
        type: "textarea",
        value: "",
      },
      {
        name: "is_mountain_region_checked",
        label: "მაღალმთიანი რეგიონი - აკმაყოფილებს განაცხადში მითითებული პირობას",
        type: "checkbox",
        value: false,
      },
      {
        name: "mountain_region_comment",
        label: "არდაკმაყოფილების მიზეზი",
        type: "textarea",
        value: "",
      },
      // {
      //   name: "is_income_to_k_checked",
      //   label: "30 000 ლარამდე შემოსავალის მქონე - აკმაყოფილებს განაცხადში მითითებული პირობას",
      //   type: "checkbox",
      //   value: false,
      // },
      // {
      //   name: "income_to_k_comment",
      //   label: "30 000 ლარამდე შემოსავალის მქონე, კომენტარი ???",
      //   type: "textarea",
      //   value: "",
      // },
      {
        name: "is_sun_gate_beneficiary_checked",
        label: "მზის კარის ბენეფიციარი - აკმაყოფილებს განაცხადში მითითებული პირობას",
        type: "checkbox",
        value: false,
      },
      {
        name: "sun_gate_beneficiary_comment",
        label: "არდაკმაყოფილების მიზეზი",
        type: "textarea",
        value: "",
      },
      {
        name: "is_purpose_detailed_provided",
        label: "მიზნობრიობა და მიზნობრიობის დეტალური აღწერა - აკმაყოფილებს განაცხადში მითითებული პირობას",
        type: "checkbox",
        value: false,
      },
      {
        name: "purpose_detailed_comment",
        label: "არდაკმაყოფილების მიზეზი",
        type: "textarea",
        value: "",
      },
      {
        name: "additional_comments",
        label: "დამატებითი კომენტარი",
        type: "textarea",
        value: "",
      },
    ],
  },
];

export { monitoringCgsGroupFieldsInitValues };
