const monitoringAgreementCgsDetailsFields = [
  {
    groupName: "contract_number_contract_signed_date",
    groupLabel: "ბენეფიციარი კომპანიის დასახელება და საიდენტიფიკაციო კოდი, ბენეფიციართან გაფორმებული ხელშეკრულების სესხის ID და გაფორმების/დადასტურების თარიღი",
    fields: [
      {
        name: "company_name",
        label: "სრული სახელი",
        type: "string",
        value: "",
      },
      {
        name: "company_identification_code",
        label: "პ/ნ ან ს/კ",
        type: "text",
        value: "",
      },
      {
        name: "contract_number",
        label: "სესხის ID",
        disabled: true,
        type: "text",
        value: "",
      },
      {
        name: "contract_signed_date",
        label: "სესხის დადასტურების თარიღი",
        disabled: true,
        value: null,
      },
      {
        name: "program_component",
        label: "კომპონენტი",
        type: "select",
        value: "",
      },
      {
        name: "bank",
        label: "ბანკი",
        type: "autocomplete",
        value: "",
      },
      {
        name: "approved_amount",
        label: "დამტკიცებული თანხა",
        type: "number",
        value: 0,
      },
      {
        name: "is_female_entrepreneur",
        label: "ქალი მეწარმე",
        type: "checkbox",
        value: false,
      },
      {
        name: "is_mountain_region",
        label: "მაღალმთიანი რეგიონი",
        type: "checkbox",
        value: false,
      },
      {
        name: "is_income_to_k",
        label: "30 000 ლარამდე შემოსავალის მქონე",
        type: "checkbox",
        value: false,
      },
      {
        name: "is_sun_gate_beneficiary",
        label: "მზის კარის ბენეფიციარი",
        type: "checkbox",
        value: false,
      },
      {
        name: "purpose",
        label: "მიზნობრიობა",
        type: "textarea",
        value: "",
      },
      {
        name: "purpose_detailed",
        label: "მიზნობრიობა დეტალურად",
        type: "textarea",
        value: "",
      },
      {
        name: "project_activities",
        value: "",
        type: "text",
        label: "საქმიანობის სფეროები",
        sub_fields: [],
      },
    ],
  },
];

const monitoringCgsDetailsFields = [
  {
    groupName: "contract_number_contract_signed_date",
    groupLabel: "ბენეფიციარი კომპანიის დასახელება და საიდენტიფიკაციო კოდი, ბენეფიციართან გაფორმებული ხელშეკრულების სესხის ID და გაფორმების/დადასტურების თარიღი",
    fields: [
      {
        name: "company_name",
        label: "სრული სახელი",
        type: "string",
        value: "",
      },
      {
        name: "company_identification_code",
        label: "პ/ნ ან ს/კ",
        type: "text",
        value: "",
      },
      {
        name: "contract_number",
        label: "სესხის ID",
        disabled: true,
        type: "text",
        value: "",
      },
      {
        name: "contract_signed_date",
        label: "სესხის დადასტურების თარიღი",
        disabled: true,
        value: null,
      },
      {
        name: "program_component",
        label: "კომპონენტი",
        type: "select",
        value: "",
      },
      {
        name: "bank",
        label: "ბანკი",
        type: "autocomplete",
        value: "",
      },
      {
        name: "approved_amount",
        label: "დამტკიცებული თანხა",
        type: "number",
        value: 0,
      },
    ],
  },
  {
    groupName: `actual_municipality_address_cadastral_code_is_building_rented`,
    groupLabel: `ბენეფიციარის საქმიანობის განხორციელების ფაქტობრივი მისამართი`,
    fields: [
      {
        name: "actual_municipality",
        label: "",
        type: "autocomplete",
        value: "",
      },
      {
        name: "actual_address",
        label: "მისამართი(სოფელი/დაბა/ქუჩა/ნომერი)*",
        type: "text",
        value: "",
      },
      {
        name: "cadastral_code",
        label: "საკადასტრო კოდი",
        type: "text",
        value: "",
      },
    ],
  },
  {
    groupName: `representative_name_phone_position_id_number_email`,
    groupLabel: `ბენეფიციარის წარმომადგენელი პირი`,
    fields: [
      {
        name: "representative_name",
        label: "სახელი, გვარი",
        type: "text",
        value: "",
      },
      {
        name: "representative_phone",
        label: "ტელ. ნომერი",
        type: "text",
        value: "",
      },
      {
        name: "representative_position",
        label: "პოზიცია",
        type: "text",
        value: "",
      },
      {
        name: "representative_id_number",
        label: "პ/ნ",
        type: "text",
        value: "",
      },
      {
        name: "representative_email",
        label: "წარმომადგენლის ელ. ფოსტა",
        type: "text",
        value: "",
      },
    ],
  },
  {
    groupName: "fixed_assets_capital_expenditures_funds_turnover",
    groupLabel: "პროექტის ფარგლებში ბენეფიციარის მხრიდან შესრულებული აქტივობები",
    fields: [
      {
        name: "purpose",
        label: "მიზნობრიობა",
        type: "textarea",
        value: "",
      },
      // {
      //   name: "purpose_detailed",
      //   label: "მიზნობრიობა დეტალურად",
      //   type: "textarea",
      //   value: "",
      // },
      {
        name: "is_purpose_detailed_provided",
        label: "მიზნობრიობა და მიზნობრიობის დეტალური აღწერა - აკმაყოფილებს განაცხადში მითითებულ პირობას",
        type: "textarea",
        value: "",
      },
      {
        name: "purpose_detailed_comment",
        label: "პირობის არდაკმაყოფილების მიზეზი",
        type: "textarea",
        value: "",
      },
      {
        name: "is_female_entrepreneur",
        label: "ქალი მეწარმე",
        type: "checkbox",
        value: false,
      },
      {
        name: "is_female_entrepreneur_checked",
        label: "ქალი მეწარმე - აკმაყოფილებს განაცხადში მითითებულ პირობას",
        type: "checkbox",
        value: false,
      },
      {
        name: "female_entrepreneur_comment",
        label: "პირობის არდაკმაყოფილების მიზეზი",
        type: "checkbox",
        value: false,
      },
      {
        name: "is_mountain_region",
        label: "მაღალმთიანი რეგიონი",
        type: "checkbox",
        value: false,
      },
      {
        name: "is_mountain_region_checked",
        label: "მაღალმთიანი რეგიონი - აკმაყოფილებს განაცხადში მითითებულ პირობას",
        type: "checkbox",
        value: false,
      },
      {
        name: "mountain_region_comment",
        label: "პირობის არდაკმაყოფილების მიზეზი",
        type: "textarea",
        value: "",
      },
      {
        name: "is_sun_gate_beneficiary",
        label: "მზის კარის ბენეფიციარი",
        type: "checkbox",
        value: false,
      },
      {
        name: "is_sun_gate_beneficiary_checked",
        label: "მზის კარის ბენეფიციარი - აკმაყოფილებს განაცხადში მითითებულ პირობას",
        type: "checkbox",
        value: false,
      },
      {
        name: "sun_gate_beneficiary_comment",
        label: "პირობის არდაკმაყოფილების მიზეზი",
        type: "textArea",
        value: "",
      },
      {
        name: "project_activities",
        value: "",
        type: "autocomplete",
        optionName: "nace_activities",
        label: "საქმიანობის სფეროები",
      },
      {
        name: "is_project_activities_checked",
        label: "საქმიანობის სფეროები - აკმაყოფილებს განაცხადში მითითებულ პირობას",
        type: "checkbox",
        value: false,
      },
      {
        name: "project_activities_comment",
        label: "პირობის არდაკმაყოფილების მიზეზი",
        type: "textarea",
        value: "",
      },
      // {
      //   name: "is_purchased_activities_checked",
      //   label: `სესხის თანხით შესრულებული არ არის სამუშაოები`,
      //   type: "textarea",
      //   value: false,
      // },
      {
        name: "purchased_activities_comment",
        label: "სესხის თანხით შესრულებული სამუშაოების დეტალური აღწერა",
        type: "textarea",
        value: "",
      },
      {
        name: "used_amount",
        label: "ათვისებული თანხის მოცულობა ₾",
        type: "number",
        value: 0,
      },
      {
        name: "fixed_assets_capital_expenditures",
        label: "ძირითადი საშუალებები / კაპიტალური დანახარჯები",
        type: "number",
        value: 0,
      },
      {
        name: "machine_equipment_equipment",
        label: "მანქანა - დანადგარები / აღჭურვილობა",
        type: "number",
        value: 0,
      },
      {
        name: "vehicles_special_technic",
        label: "სატრანსპორტო საშუალებები / სპეც. ტექნიკა",
        type: "number",
        value: 0,
      },
      {
        name: "purchase_building_land",
        label: "შენობა ნაგებობის / მიწის შეძენა",
        type: "number",
        value: 0,
      },
      {
        name: "building_repairs",
        label: "მშენებლობა / რემონტი",
        type: "number",
        value: 0,
      },
      {
        name: "funds_turnover",
        label: "საბრუნავი საშუალებები",
        type: "number",
        value: 0,
      },
      {
        name: "additional_comments",
        label: "დამატებითი კომენტარი",
        type: "textArea",
        value: "",
      },
    ],
  },
];

export { monitoringCgsDetailsFields, monitoringAgreementCgsDetailsFields };
