/**
 * @React
 */
import {
    FC,
} from "react";
/**
 * @Router
 */
import { useParams } from 'react-router-dom';

/**
 * @MUI
 */
import {
    CardContent,
    Card,
    Alert
} from "@mui/material";
/**
 * @Context
 */
import {
    useGeneralInfoContext,
} from "../../../context";

/**
 * @Components
 */
import Loader from "../../../components/Loader";
/**
 * @Types
 */
import {
    IMonitoringAgreementCgsModel
} from "../../../types";
/**
 * @Utilities
 */
import { generateUrl } from "../../../utilities/generateRequestUrl";
import config from "../../../utilities/generateRequestUrl/config";
import { useFetchData } from "../../../hooks";
import MonitoringCgsDetails from "../../../components/detailedApplication/MonitoringApplication/MonitoringCgsDetails";
import { IMonitoringCgsFields } from "../../../types/monitoringCgsFields";
import MessageBox from "../../../components/CustomComponents/MessageBox";
import { monitoringAgreementCgsDetailsFields } from "../../../utilities/Fields/CgsMonitoringFields/fields/detailFields";
import CardsLayout from "../../../components/CustomComponents/CardsLayout";

const MonitoringCgsAgreementDetailPage: FC = () => {
    const { id } = useParams<{ id: string }>();
    const { data, load: loading, error: detailsError } = useFetchData<IMonitoringCgsFields & Partial<IMonitoringAgreementCgsModel> | null>(generateUrl(config.client, 'getAgreementCgsById', { id: id }, null));
    let {
        generatedValues,
    } = useGeneralInfoContext();

    return (
        <>
            {
                loading
                    ?
                    <Loader />
                    :
                    detailsError ?
                        <MessageBox
                            alignHorizontal="center"
                        >
                            <Alert severity="error">
                                დაფიქსირდა შეცდომა, გთხოვთ, მიმართოთ საიტის ადმინისტრატორს
                            </Alert>
                        </MessageBox>
                        :
                        data
                            &&
                            generatedValues
                            ?
                            <CardsLayout>
                                <MonitoringCgsDetails
                                    data={data || null}
                                    generatedValues={generatedValues}
                                    loading={loading}
                                    title={'სააგენტოში დადასტურების ეტაპზე არსებული დეტალური ინფორმაცია'}
                                    monitoringCgsDetailsFields={monitoringAgreementCgsDetailsFields}
                                />
                            </CardsLayout>

                            :
                            <MessageBox
                                alignHorizontal="center"
                            >
                                <Alert severity="warning">
                                    განაცხადი არ მოიძებნა
                                </Alert>
                            </MessageBox>

            }
        </>
    )
}

export default MonitoringCgsAgreementDetailPage;
