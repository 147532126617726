/**
 * @React
 */
import {
    useState,
    FC
} from "react";
/**
 * @Mui
 */
import {
    ImageList,
    ImageListItem,
    ImageListItemBar,
    IconButton,
    Box,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

/**
 * @Utilities
 */
import openInNewTab from "../../../utilities/newTab";
/**
 * @Components
 */
import CustomTooltipTest from "../CustomTooltip";
import DialogWindow from "../DialogWindow";
import fileImage from './images/fileimage.jpg';

interface IFilesContainerProps {
    FilesList: string[];
    mode?: 'view' | 'form';
    removeCallback?: (index: number) => void;
    cols?: number;
}

const FilesContainer: FC<IFilesContainerProps> = ({
    FilesList,
    mode = 'view',
    removeCallback,
    cols = 8
}: IFilesContainerProps) => {
    const [sendApplication, setSendApplication] = useState<number>(0);
    const [openDialogWindow, setOpenDialogWindow] = useState<boolean>(false);
    const baseUrl = `${process.env.REACT_APP_BASE_URL}`;
    const imageFileTypes = [
        "jpg", "jpeg", "png", "gif", "webp", "svg", "bmp", "tiff",
        "JPG", "JPEG", "PNG", "GIF", "WEBP", "SVG", "BMP", "TIFF",
        "HEIF", "HEIC", "JP2", "AVIF", "ICO", "PSD", "WBMP", "JFIF",
        "heif", "heic", "jp2", "avif", "ico", "psd", "wbmp", "jfif"
    ];
    return (
        <ImageList sx={{ width: '100%', height: 'auto' }} cols={cols} gap={10}>
            {
                FilesList.map((file: string, index: number) => {
                    const extension = file?.split('.').pop();
                    const isImage = imageFileTypes.includes(extension || '');
                    const url = `${baseUrl}${file}`;
                    const SRCUrl = isImage ? url : fileImage;
                    return (
                        <a
                            key={`${file}-${index}-${url}`}
                            href={url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <ImageListItem key={`${file}-${index}`}>

                                <img
                                    srcSet={SRCUrl}
                                    src={SRCUrl}
                                    alt={'File'}
                                    loading="lazy"
                                />


                                <ImageListItemBar
                                    sx={{
                                        background:
                                            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                    }}
                                    position="top"
                                    actionIcon={
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '2px',
                                        }}>
                                            {
                                                mode === 'form'
                                                &&
                                                <DialogWindow
                                                    openDialogWindow={openDialogWindow}
                                                    txt={{
                                                        dialogTextTitle: "",
                                                        cancelButton: "გაუქმება",
                                                        triggerButton: "წაშლა",
                                                        dialogTextBody:
                                                            "დარწმუნებული ხართ, რომ გინდათ ფოტოს წაშლა?",
                                                    }}
                                                    callback={() => removeCallback && removeCallback(Number(sendApplication))}
                                                    setOpen={(open) => {
                                                        if (!open) {
                                                            setOpenDialogWindow(false);
                                                        }
                                                    }}
                                                >
                                                    <CustomTooltipTest
                                                        title="ფოტოს წაშლა"
                                                        placement="bottom"
                                                        arrow
                                                        sx={{
                                                            "& .MuiTooltip-tooltip": {
                                                                backgroundColor: "#f9f9f9",
                                                                color: "rgba(0, 0, 0, 0.87)",
                                                                boxShadow: "0px 0px 10px rgba(0, 0, 0, 1)",
                                                                fontSize: "11px",
                                                                padding: "12px",
                                                                borderRadius: "5px",
                                                            },
                                                        }}
                                                    >

                                                        <IconButton
                                                            sx={{ color: 'white', ":hover": { background: 'linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%)', } }}
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                setSendApplication(index);
                                                                setOpenDialogWindow(true);
                                                            }}>
                                                            <CloseOutlinedIcon sx={{
                                                                fontSize: "14px"
                                                            }} />
                                                        </IconButton>
                                                    </CustomTooltipTest>
                                                </DialogWindow>

                                            }
                                            <CustomTooltipTest
                                                title="სურათის ჩამოტვირთვა"
                                                placement="bottom"
                                                arrow
                                                sx={{
                                                    "& .MuiTooltip-tooltip": {
                                                        backgroundColor: "#f9f9f9",
                                                        color: "rgba(0, 0, 0, 0.87)",
                                                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 1)",
                                                        fontSize: "11px",
                                                        padding: "12px",
                                                        borderRadius: "5px",
                                                    },
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        color: 'white',
                                                        ":hover": {
                                                            background: 'linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%)',
                                                        },
                                                        fontSize: "14px"
                                                    }}
                                                    onClick={() => openInNewTab(url)}
                                                >
                                                    <FileDownloadOutlinedIcon sx={{
                                                        fontSize: "14px"
                                                    }} />

                                                </IconButton>
                                            </CustomTooltipTest>

                                        </Box>

                                    }
                                    actionPosition="left"
                                />

                            </ImageListItem>
                        </a>
                    )
                })
            }
        </ImageList>
    );
}

export default FilesContainer;