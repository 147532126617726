import { UploadedAttachments } from "../Attachment";
import { IDifferenceFromAgreement, IMonitoringApprovalHistory, MyType, NestedKeys } from "../monitoringFields";
import { IMonitoringAgreementCgsModel } from "../models/monitoring_agreement_cgs.interface";
import { IUser } from "../models/user.interface";

export type ApiCgsListResponse = {
  results: IMonitoringCgsFields & Partial<IMonitoringAgreementCgsModel>[];
  total: number;
  page: number;
  pages: number;
};

export enum Type {
  monitoring = "monitoring",
  agreement = "agreement",
  approved = "approved",
}

export type Filter = {
  company_name: string;
  company_identification_code: string;
  page: number;
  amountOnPage: number;
  type: Type;
  municipality: string;
  component: string;
  is_monitorable?: boolean;
};

export type ApplicationPreview = {
  _id: string;
  company_name: string;
  company_identification_code: string;
  contract_number: string;
  approved_amount: number;
  contract_signed_date: string;
  program_component: string;
  component: string;
  monitoring_approval?: boolean;
  actual_municipality?: string;
  contract?: string;
  created_by?: IUser;
  monitoring_approval_history?: IMonitoringApprovalHistory[];
};
export interface IMonitoringCgsFields {
  contract?: string;
  used_amount?: number;
  fixed_assets_capital_expenditures?: number;
  machine_equipment_equipment?: number;
  vehicles_special_technic?: number;
  purchase_building_land?: number;
  building_repairs?: number;
  funds_turnover?: number;
  is_project_activities_checked?: boolean;
  project_activities_comment?: string;
  is_purchased_activities_checked?: boolean;
  purchased_activities_comment?: string;
  is_female_entrepreneur_checked?: boolean;
  female_entrepreneur_comment?: string;
  is_mountain_region_checked?: boolean;
  mountain_region_comment?: string;
  is_income_to_k_checked?: boolean;
  income_to_k_comment?: string;
  is_sun_gate_beneficiary_checked?: boolean;
  sun_gate_beneficiary_comment?: string;
  is_purpose_detailed_provided?: boolean;
  purpose_detailed_comment?: string;
  additional_comments?: string;
  representative_id_number?: string;
  representative_position?: string;
  monitoring_approval?: boolean;
  difference_from_agreement?: IDifferenceFromAgreement[];
  attached_documents?: UploadedAttachments;
  monitoring_approval_history?: IMonitoringApprovalHistory[];
}

export type MonitoringCgsFieldsKeys = NestedKeys<IMonitoringCgsFields>;
export type MonitoringAgreementCgsModelKeys = NestedKeys<IMonitoringAgreementCgsModel>;
