/**
 * @React
 */
import React, { useEffect, useState } from "react";
/**
 * @Router
 */

import { useHistory, useLocation, useParams } from "react-router-dom";
/**
 * @Mui
 */
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  DialogTitle,
  FormLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Typography,
  CardActions,
  CardHeader,
  Avatar,
  IconButton,
  Divider,
} from "@mui/material";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import DownloadDoneOutlinedIcon from '@mui/icons-material/DownloadDoneOutlined';
import FileDownloadDoneOutlinedIcon from '@mui/icons-material/FileDownloadDoneOutlined';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import axios from "axios";
/**
 * @Context
 */
import {
  useMonitoringApplicationContext,
  ApplicationPreview,
  Type
} from "../../../context/MonitoringApplicationContext";
import { useMonitoringFormContext, useGeneralInfoContext } from "../../../context";
/**
 * @Components
 */
import Switch from "../../../components/CustomComponents/SwitchComponent";
import Pagination from "../../../components/filterApplication/Pagination";
import TableSkeleton from "../../../components/TableSkeleton";
import CustomTooltip from "../../../components/CustomComponents/CustomTooltip";

import openInNewTab from "../../../utilities/newTab";
import { ProgramComponent } from "../../../types";

interface MonitoringDashboardProps {
  tab: Type;
  isChecked: boolean;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

const MonitoringDashboard: React.FC<MonitoringDashboardProps> = ({
  tab,
  isChecked,
  setIsChecked
}) => {
  const {
    applications,
    fetchApplications,
    filter,
    setFilter,
    totalPages,
    loading
  } = useMonitoringApplicationContext();
  const history = useHistory();
  type Column = {
    id: keyof ApplicationPreview;
    label: string;
  };

  const {
    setAgreement,
    setSelectedCompany,
    setSelectedContract,
  } = useMonitoringFormContext();
  let {
    options: GeneralInfo,
    setOptions: setGeneralInfoOptions,
    generatedValues: GeneralInforValues,
    setGeneratedValues: setGeneralInfoValues,
    data: GeneralInfoOptions,
    load: GeneralInfoLoad,
    error: GeneralInfoError,
  } = useGeneralInfoContext();
  const propertyMapping = {
    actual_municipality: "municipalities",
  }

  const columns: Column[] = [
    { id: "contract_number", label: "ხელშეკრულების ნომერი/დამტკიცებული სესხის/ლიზინგის ID" },
    { id: "company_name", label: "ბენეფიციარის დასახელება" },
    { id: "company_identification_code", label: "ბენეფიციარის ს/კ" },
    { id: "approved_amount", label: "დამტკიცებული თანხა - ₾" },
    { id: "contract_signed_date", label: "ხელშეკრულების გაფაორმების თარიღი" },
    { id: "actual_municipality", label: "საქმიანობის განხორციელების ფაქტობრივი მისამართი - მუნიციპალიტეტი" },
    { id: "program_component", label: "პროგრამის კომპონენტი" },
    ...(tab !== Type.agreement ? [{ id: "created_by", label: "მომხმარებელი" }] as Column[] : []),
  ];

  useEffect(() => {
    if (isChecked) {
      fetchApplications();
    }

  }, [isChecked]);

  useEffect(() => {
    setFilter((prev) => ({ ...prev, type: tab, page: 1 }));
  }, [tab]);

  function redirectToDetailsOrCreate(url: string, callback: (a: string) => void) {
    if (callback) callback(url);
  }

  const getAgreement = (
    company_identification_code: string,
    contract_number: string) => {
    setSelectedCompany(company_identification_code);
    setSelectedContract(contract_number);
    // setAgreement(null);
    // window.location.reload();
    return history.push(`/monitoring-form`);
  };
  return (
    <>
      <Card sx={{ width: "100%" }}>
        <TabContext value={tab}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              alignItems: "center",
              padding: 2
            }}>
            <TextField
              variant="outlined"
              label="ბენეფიციარის დასახელება"
              value={filter.company_name}
              onChange={(e) => setFilter({ ...filter, company_name: e.target.value })}
            />
            <TextField
              variant="outlined"
              label="ბენეფიციარის ს/კ"
              value={filter.company_identification_code}
              onChange={(e) => setFilter({ ...filter, company_identification_code: e.target.value })}
            />
            <FormControl variant="outlined">
              <InputLabel id="municipality">მუნიციპალიტეტი</InputLabel>
              <Select
                sx={{ width: "200px" }}
                labelId="municipality"
                value={filter.municipality}
                onChange={(e) => setFilter({ ...filter, municipality: e.target.value as string })}
                label="მუნიციპალიტეტი"
              >
                <MenuItem value="">
                  <em>ყველა</em>
                </MenuItem>
                {
                  GeneralInfo?.municipalities && Object.keys(GeneralInfo?.municipalities).map((key) => {
                    return <MenuItem
                      key={key}
                      value={key}>
                      {GeneralInfo?.municipalities[key].label}
                    </MenuItem>
                  })
                }
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel id="component">კომპონენტი</InputLabel>
              <Select
                sx={{ width: "200px" }}
                labelId="component"
                value={filter.component}
                onChange={(e) => setFilter({ ...filter, component: e.target.value as string })}
                label="კომპონენტი"
              >
                <MenuItem value="">
                  <em>ყველა</em>
                </MenuItem>
                {
                  Object.keys(ProgramComponent).map((key) => {
                    const enumKey = key as keyof typeof ProgramComponent;
                    return <MenuItem key={key} value={ProgramComponent[enumKey]}>{ProgramComponent[enumKey]}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
            {["admin", "ROLE_AGENCY"].includes(localStorage.getItem("role") || "") && <Switch
              isChecked={isChecked}
              callback={() => setIsChecked(prev => !prev)}
              label={isChecked ? 'სხვა პროგრამები' : 'მიკრო'}
            />}
            <CardActions>
              <Button variant="contained" color="primary" onClick={fetchApplications}>
                ძებნა
              </Button>
            </CardActions>
          </Box>
          <Divider />
          <CardContent>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {
                      columns.map((column) => (
                        <TableCell key={column.id}>{column.label}</TableCell>
                      ))
                    }
                    <TableCell key={1001}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    applications.map((application, index) => {
                      let returned = false;
                      if (tab === Type.monitoring) {
                        if (
                          application.monitoring_approval_history
                          &&
                          application.monitoring_approval_history.length > 0
                        ) {
                          let lastApproval = application.monitoring_approval_history[application.monitoring_approval_history.length - 1]?.approved;
                          returned = lastApproval === false ? true : false;
                        }
                      }

                      return (
                        <TableRow
                          sx={{
                            backgroundColor: returned ? "#F57C00" : "inherits",
                          }}
                          key={application._id}
                          onDoubleClick={() => redirectToDetailsOrCreate(`${tab === Type.monitoring || tab === Type.approved ? '/monitoring-details' : '/monitoring-agreement-details'}/${application._id}`, openInNewTab)}>
                          {
                            columns.map((column) => {
                              let value;
                              if (column.id === "actual_municipality") {
                                if (
                                  GeneralInforValues
                                  &&
                                  GeneralInforValues["municipalities"]
                                  && application[column.id]) {
                                  value = GeneralInforValues["municipalities"][application[column.id] as keyof typeof GeneralInforValues["municipalities"]].municipality;
                                }
                              } else if (application[column.id as keyof ApplicationPreview] && column.id === "created_by") {
                                value = application[column.id]?.name || "";
                              }
                              else {
                                value = application[column.id as keyof ApplicationPreview];
                              }
                              return (
                                <TableCell
                                  sx={{
                                    color: returned ? "#fff" : "inherits",
                                  }}
                                  key={column.id}>
                                  {returned ? <b>{value || ""}</b> : <>{value || ""}</>}
                                </TableCell>
                              )
                            })}
                          <TableCell
                            key={`${application._id} - ${index}`}
                            align="left"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                width: "100px",
                              }}
                            >
                              {
                                !application?.monitoring_approval
                                  ?
                                  <CustomTooltip title={tab === Type.agreement ? "მონიტორინგი" : "რედაქტირება"} placement="bottom-end" arrow>
                                    <IconButton
                                      aria-label="edit"
                                      size="small"
                                      onClick={() => tab === Type.monitoring
                                        ?
                                        redirectToDetailsOrCreate(`/monitoring-update-form/${application._id}`, history.push) : getAgreement(application.company_identification_code, application.contract_number)}>
                                      {
                                        tab === Type.monitoring ?
                                          <>
                                            <EditOutlinedIcon
                                              sx={{
                                                fontSize: "1rem",
                                                color: returned ? "#fff" : "#56B9E9",
                                              }}
                                            />

                                          </>
                                          :
                                          <AddCircleOutlinedIcon
                                            sx={{
                                              fontSize: "1rem",
                                              color: "#56B9E9",
                                            }}
                                          />
                                      }
                                    </IconButton>
                                  </CustomTooltip>
                                  :
                                  null
                              }
                              {
                                [Type.monitoring, Type.approved].includes(tab)
                                &&
                                application.monitoring_approval
                                &&
                                <CustomTooltip title="დადასტურებული" placement="bottom-end" arrow>
                                  <IconButton
                                    aria-label="detail"
                                    size="small"
                                    sx={{ fontSize: "1em" }}
                                  >
                                    <DownloadDoneOutlinedIcon
                                      sx={{
                                        color: "#00695f",
                                        fontSize: "1.1rem"
                                      }}
                                    />
                                  </IconButton>
                                </CustomTooltip>
                              }
                              <CustomTooltip
                                title={tab === Type.agreement ? "დასამონიტორინგებელი პროექტის დეტალები" : `${tab === Type.monitoring ? 'დამონიტორინგებული' : 'დადასტურებული'} პროექტის დეტალები`}
                                placement="bottom-end"
                                arrow
                              >
                                <IconButton
                                  aria-label="detail"
                                  size="small"
                                  sx={{ fontSize: "1em" }}
                                  onClick={() => redirectToDetailsOrCreate(`${([Type.monitoring, Type.approved].includes(tab)) ? '/monitoring-details/' : '/monitoring-agreement-details/'}${application._id}`, openInNewTab)}
                                >
                                  <InfoOutlinedIcon fontSize="inherit" sx={{ color: returned ? "#fff" : "#56B9E9" }} />
                                </IconButton>
                              </CustomTooltip>
                              {
                                [Type.monitoring, Type.approved].includes(tab)
                                  &&
                                  application.contract
                                  ?
                                  <CustomTooltip title="სააგენტოში დადასტურების ეტაპზე არსებული დეტალური ინფორმაცია 11" placement="bottom-end" arrow>
                                    <IconButton
                                      aria-label="detail"
                                      size="small"
                                      sx={{ fontSize: "1em" }}
                                      onClick={() => redirectToDetailsOrCreate(`/monitoring-agreement-details/${application.contract}`, openInNewTab)}
                                    >
                                      <BackupTableOutlinedIcon fontSize="inherit" sx={{ color: returned ? "#fff" : "#56B9E9" }} />
                                    </IconButton>
                                  </CustomTooltip>
                                  :
                                  null
                              }
                            </Box>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                  {
                    loading
                    &&
                    <TableSkeleton
                      amount={5}
                      columns={columns}
                    />}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              filterData={filter}
              setFilterData={setFilter}
              pages={totalPages} />
          </CardContent>
        </TabContext>
      </Card >
    </>
  );
};

export default MonitoringDashboard;
