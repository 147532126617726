/**
 * @React
 */
import React, { useEffect, useState } from "react";
/**
 * @Router
 */
import { useHistory } from "react-router-dom";
/**
 * @DateFormater
 */
import dateFormat from "dateformat";
/**
 * @Mui
 */
import {
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CardContent,
    IconButton,
    Divider,
} from "@mui/material";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DownloadDoneOutlinedIcon from '@mui/icons-material/DownloadDoneOutlined';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
/**
 * @Context
 */
import { useGeneralInfoContext } from "../../../context";
/**
 * @Components
 */
import Switch from "../../../components/CustomComponents/SwitchComponent";
import Pagination from "../../../components/filterApplication/Pagination";
import TableSkeleton from "../../../components/TableSkeleton";
import CustomTooltip from "../../../components/CustomComponents/CustomTooltip";
import CustomInputFields from "../../../components/CustomComponents/CustomInputFields";
/**
 * @Types
 */
import {
    ApplicationPreview,
    Type,
    Filter,
    ProgramComponentCgs
} from "../../../types";
/**
 * @Utilities
 */
import openInNewTab from "../../../utilities/newTab";
import { useFetchData } from "../../../hooks";
import { generateUrl } from "../../../utilities/generateRequestUrl";
import config from '../../../utilities/generateRequestUrl/config';
import { ApiCgsListResponse } from "../../../types/monitoringCgsFields";
import axiosInstance from "../../../utilities/axios";

interface MonitoringDashboardProps {
    tab: Type;
    isChecked: boolean;
    setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

const MonitoringDashboard: React.FC<MonitoringDashboardProps> = ({
    tab,
    isChecked,
    setIsChecked
}) => {
    const history = useHistory();
    const [filter, setFilter] = useState<Filter>({
        company_name: "",
        company_identification_code: "",
        page: 1,
        amountOnPage: 15,
        type: Type.monitoring,
        municipality: "",
        component: "",
        ...(Type.agreement === tab && { is_monitorable: true }),
    });

    type Column = {
        id: keyof ApplicationPreview;
        label: string;
    };

    const { data, load, error, fetchData } = useFetchData<ApiCgsListResponse | null>(
        generateUrl(config.client, 'getAgreementCgs', null, filter as any),
    );

    const toggleAgreementMonitoring = async (id: string, is_monitorable: boolean) => {
        try {
            await axiosInstance.put(`/update-agreement-cgs-is-monitorable/${id}/${is_monitorable}`);
            if (fetchData) {
                fetchData();
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        setFilter((prev) => ({ ...prev, type: tab, page: 1 }))
    }, [tab]);

    let {
        options: GeneralInfo,
        generatedValues: GeneralInforValues,
    } = useGeneralInfoContext();

    const columns: Column[] = [
        { id: "contract_number", label: "სესხის ID" },
        { id: "company_name", label: "ბენეფიციარის დასახელება" },
        { id: "company_identification_code", label: "ბენეფიციარის ს/კ" },
        { id: "approved_amount", label: "დამტკიცებული თანხა - ₾" },
        { id: "contract_signed_date", label: "დადასტურების თარიღი" },
        { id: "actual_municipality", label: "საქმიანობის განხორციელების ფაქტობრივი მისამართი - მუნიციპალიტეტი" },
        { id: "program_component", label: "პროგრამის კომპონენტი" },
        ...(tab !== Type.agreement ? [{ id: "created_by", label: " მონიტორინგის განმხორციელებელი პირი" }] as Column[] : []),
    ];

    function redirectToDetailsOrCreate(url: string, callback: (a: string) => void) {
        if (callback) callback(url);
    }
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    alignItems: "center",
                    padding: 2
                }}>
                <TextField
                    variant="outlined"
                    label="ბენეფიციარის დასახელება"
                    value={filter.company_name}
                    onChange={(e) => setFilter({ ...filter, company_name: e.target.value })}
                />
                <TextField
                    variant="outlined"
                    label="ბენეფიციარის ს/კ"
                    value={filter.company_identification_code}
                    onChange={(e) => setFilter({ ...filter, company_identification_code: e.target.value })}
                />
                <FormControl variant="outlined">
                    <InputLabel id="municipality">მუნიციპალიტეტი</InputLabel>
                    <Select
                        sx={{ width: "200px" }}
                        labelId="municipality"
                        value={filter.municipality}
                        onChange={(e) => setFilter({ ...filter, municipality: e.target.value as string })}
                        label="მუნიციპალიტეტი"
                    >
                        <MenuItem value="">
                            <em>ყველა</em>
                        </MenuItem>
                        {
                            GeneralInfo?.municipalities && Object.keys(GeneralInfo?.municipalities).map((key) => {
                                return <MenuItem
                                    key={key}
                                    value={key}>
                                    {GeneralInfo?.municipalities[key].label}
                                </MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
                {
                    tab === Type.agreement
                    &&
                    <CustomInputFields
                        key={1}
                        name={'monitorable'}
                        value={filter?.is_monitorable || false}
                        onChange={() => {
                            setFilter((prev) => ({
                                ...prev,
                                is_monitorable: prev?.is_monitorable ? false : true
                            }));
                        }}
                        type={"checkbox"}
                        label={'მონიშნულები'}
                    />
                }
                {["admin", "ROLE_AGENCY"].includes(localStorage.getItem("role") || "") && <Switch
                    isChecked={isChecked}
                    callback={() => setIsChecked(prev => !prev)}
                    label={isChecked ? 'სხვა პროგრამები' : 'მიკრო'}
                />}
            </Box>
            <Divider />
            <CardContent>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {
                                    columns.map((column) => (
                                        <TableCell key={column.id}>{column.label}</TableCell>
                                    ))
                                }
                                <TableCell key={1001}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data && data?.results.map((application: any, index: number) => {
                                    let returned = false;
                                    if (tab === Type.monitoring) {
                                        if (
                                            application.monitoring_approval_history
                                            &&
                                            application.monitoring_approval_history.length > 0
                                        ) {
                                            let lastApproval = application.monitoring_approval_history[application.monitoring_approval_history.length - 1]?.approved;
                                            returned = lastApproval === false ? true : false;
                                        }
                                    }

                                    return (
                                        <TableRow
                                            sx={{
                                                backgroundColor: ((tab === Type.agreement && application.is_monitorable)) ? "#357a38" : returned ? "#F57C00" : "inherits",
                                            }}
                                            key={application._id}
                                            onDoubleClick={() => redirectToDetailsOrCreate(`${([Type.monitoring, Type.approved].includes(tab)) ? '/monitoring-details-cgs/' : '/monitoring-agreement-details-cgs/'}${application._id}`, openInNewTab)}>
                                            {
                                                columns.map((column) => {
                                                    let value;
                                                    if (column.id === "actual_municipality") {
                                                        if (
                                                            GeneralInforValues
                                                            &&
                                                            GeneralInforValues["municipalities"]
                                                            && application[column.id]) {
                                                            value = GeneralInforValues["municipalities"][application[column.id] as keyof typeof GeneralInforValues["municipalities"]].municipality;
                                                        }
                                                    } else if (application[column.id as keyof ApplicationPreview] && column.id === "created_by") {
                                                        value = application[column.id]?.name || "";
                                                    } else if (application[column.id as keyof ApplicationPreview] && column.id === "contract_signed_date") {
                                                        value = application[column.id] ? dateFormat(application[column.id], "dd/mm/yyyy") : "";
                                                    } else {
                                                        value = application[column.id as keyof ApplicationPreview];
                                                    }
                                                    return (
                                                        <TableCell
                                                            sx={{
                                                                color: (returned || (tab === Type.agreement && application.is_monitorable)) ? "#fff" : "inherits",
                                                            }}
                                                            key={column.id}>
                                                            {(returned || (tab === Type.agreement && application.is_monitorable)) ? <b>{value || ""}</b> : <>{value || ""}</>}
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                            < TableCell
                                                key={`${application._id} - ${index}`}
                                                align="left"
                                            >

                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                        width: "100px",
                                                    }}
                                                >
                                                    {
                                                        !application?.monitoring_approval
                                                            ?
                                                            <CustomTooltip title={tab === Type.agreement ? "მონიტორინგი" : "რედაქტირება"} placement="bottom-end" arrow>
                                                                <IconButton
                                                                    aria-label="edit"
                                                                    size="small"
                                                                    onClick={() => tab === Type.monitoring
                                                                        ? redirectToDetailsOrCreate(`/monitoring-update-cgs-form/${application._id}`, openInNewTab)
                                                                        :
                                                                        redirectToDetailsOrCreate(`/monitoring-start-cgs-form/${application._id}`, openInNewTab)}
                                                                >
                                                                    {
                                                                        tab === Type.monitoring ?
                                                                            <>
                                                                                {["ROLE_MONITORING_CGS"].includes(localStorage.getItem("role") || "")
                                                                                    ? <EditOutlinedIcon
                                                                                        sx={{
                                                                                            fontSize: "1rem",
                                                                                            color: (returned || application.is_monitorable) ? "#fff" : "#56B9E9",
                                                                                        }}
                                                                                    />
                                                                                    :
                                                                                    null}

                                                                            </>
                                                                            :
                                                                            <>
                                                                                {["ROLE_MONITORING_CGS"].includes(localStorage.getItem("role") || "")
                                                                                    &&
                                                                                    application.is_monitorable
                                                                                    ? <AddCircleOutlinedIcon
                                                                                        sx={{
                                                                                            fontSize: "1rem",
                                                                                            // color: "#56B9E9",
                                                                                            color: (returned || application.is_monitorable) ? "#fff" : "#56B9E9",
                                                                                        }}
                                                                                    />
                                                                                    :
                                                                                    null}
                                                                            </>

                                                                    }
                                                                </IconButton>
                                                            </CustomTooltip>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        tab === Type.agreement
                                                            &&
                                                            ["ROLE_AGENCY"].includes(localStorage.getItem("role") || "")
                                                            ?
                                                            <CustomInputFields
                                                                key={application._id}
                                                                name={application._id}
                                                                value={application.is_monitorable}
                                                                onChange={() => {
                                                                    toggleAgreementMonitoring(application._id, !application.is_monitorable);
                                                                }}
                                                                type={"checkbox"}
                                                                label={''}
                                                            />
                                                            :
                                                            null
                                                    }
                                                    {
                                                        [Type.monitoring, Type.approved].includes(tab)
                                                        &&
                                                        application.monitoring_approval
                                                        &&
                                                        <CustomTooltip title="დადასტურებული" placement="bottom-end" arrow>
                                                            <IconButton
                                                                aria-label="detail"
                                                                size="small"
                                                                sx={{ fontSize: "1em" }}
                                                            >
                                                                <DownloadDoneOutlinedIcon
                                                                    sx={{
                                                                        color: "#00695f",
                                                                        fontSize: "1.1rem"
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </CustomTooltip>
                                                    }
                                                    <CustomTooltip
                                                        title={tab === Type.agreement ? "დასამონიტორინგებელი პროექტის დეტალები" : `${tab === Type.monitoring ? 'დამონიტორინგებული' : 'დადასტურებული'} პროექტის დეტალები`}
                                                        placement="bottom-end"
                                                        arrow
                                                    >
                                                        <IconButton
                                                            aria-label="detail"
                                                            size="small"
                                                            sx={{ fontSize: "1em" }}
                                                            onClick={() => redirectToDetailsOrCreate(`${([Type.monitoring, Type.approved].includes(tab)) ? '/monitoring-details-cgs/' : '/monitoring-agreement-details-cgs/'}${application._id}`, openInNewTab)}
                                                        >
                                                            <InfoOutlinedIcon fontSize="inherit" sx={{ color: (returned || (tab === Type.agreement && application.is_monitorable)) ? "#fff" : "#56B9E9" }} />
                                                        </IconButton>
                                                    </CustomTooltip>
                                                    {
                                                        [Type.monitoring, Type.approved].includes(tab)
                                                            &&
                                                            application.contract
                                                            ?
                                                            <CustomTooltip title="სააგენტოში დადასტურების ეტაპზე არსებული დეტალური ინფორმაცია 11" placement="bottom-end" arrow>
                                                                <IconButton
                                                                    aria-label="detail"
                                                                    size="small"
                                                                    sx={{ fontSize: "1em" }}
                                                                    onClick={() => redirectToDetailsOrCreate(`/monitoring-agreement-details-cgs/${application.contract}`, openInNewTab)}
                                                                >
                                                                    <BackupTableOutlinedIcon fontSize="inherit" sx={{ color: (returned || (tab === Type.agreement && application.is_monitorable)) ? "#fff" : "#56B9E9" }} />
                                                                </IconButton>
                                                            </CustomTooltip>
                                                            :
                                                            null
                                                    }
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                            {
                                load
                                &&
                                <TableSkeleton
                                    amount={5}
                                    columns={columns}
                                />}
                        </TableBody>
                    </Table>
                </TableContainer >
                <Pagination
                    filterData={filter}
                    setFilterData={setFilter}
                    pages={data?.pages || 1} />
            </CardContent >
        </>
    );
};

export default MonitoringDashboard;

// function redirectToDetailsOrCreate(application: ApplicationPreview) {
//   const { _id: id, company_identification_code, contract_number } = application;

//   if (tab === Type.monitoring) {
//     window.open(`/monitoring-details/${id}`);
//   } else {
//     window.open(`/monitoring-form`);
//   }
// }
