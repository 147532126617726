interface TableTDListListProps {
    items: string[];
}

const TableTDListList: React.FC<TableTDListListProps> = ({ items = [] }) => {
    return (
        <ul style={{
            listStyleType: "none",
            padding: '0 0 0 5px',
            margin: 0,
        }}>
            {items.map((item, index) => (
                <li key={index} style={{
                    padding: '0 0 4px 4px',
                }}>
                    {item}
                </li>
            ))}
        </ul>
    );
};

export default TableTDListList;