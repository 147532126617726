import React from 'react';
import {
    Box,
    TextField,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    OutlinedInput,
    FormControl,
    InputLabel,
    Autocomplete,
    FormHelperText,
} from '@mui/material';

interface IInputFieldOption {
    label: string;
    value: string;
}

interface CustomInputProps {
    name: string;
    label: string;
    value: any;
    type: "number" | "select" | "textarea" | "text" | "checkbox" | "date" | "autocomplete";
    options?: IInputFieldOption[];
    disabled?: boolean;
    onChange: (value: any) => void;
    errors?: string;
    style?: React.CSSProperties;
}

const CustomInputFields: React.FC<CustomInputProps> = ({
    name,
    label,
    value,
    onChange,
    options,
    disabled = false,
    errors,
    style,
    type,
}) => {
    const renderInput = () => {
        switch (type) {
            case 'autocomplete':
                return (
                    <Autocomplete
                        value={options?.find((opt) => opt.value === value) || null}
                        options={options ?? []}
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option) => (
                            <Box {...props} key={option.value} component="li" sx={{ fontSize: '11px' }}>
                                {option.label}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label}
                                error={!!errors}
                                helperText={errors}
                                disabled={disabled}
                                size="small"
                            />
                        )}
                        onChange={(_, data) => onChange(data?.value ?? '')}
                    />
                );

            case 'select':
                return (
                    <FormControl fullWidth variant="outlined" sx={{ minWidth: 120 }}>
                        <InputLabel>{label}</InputLabel>
                        <Select
                            value={value}
                            size='small'
                            error={!!errors}
                            disabled={disabled}
                            onChange={(e) => onChange(e.target.value)}
                            input={<OutlinedInput label={label} />}
                        >
                            {options?.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors && <FormHelperText>{errors}</FormHelperText>}
                    </FormControl>
                );

            case 'checkbox':
                return (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!!value}
                                onChange={(e) => onChange(e.target.checked)}
                                disabled={disabled}
                                size="small"
                            />
                        }
                        label={label}
                    />
                );

            case 'date':
                return (
                    <TextField
                        label={label}
                        value={value ? new Date(value).toISOString().split('T')[0] : ''}
                        size="small"
                        type='date'
                        fullWidth
                        onChange={(e) => onChange(e.target.value)}
                        error={!!errors}
                        helperText={errors}
                        disabled={disabled}
                    />
                );

            case 'number':
                return (
                    <TextField
                        label={label}
                        value={value ?? 0}
                        size="small"
                        type='number'
                        fullWidth
                        onChange={(e) => onChange(Number(e.target.value))}
                        disabled={disabled}
                        error={!!errors}
                        helperText={errors}
                    />
                );

            case 'text':
                return (
                    <TextField
                        label={label}
                        value={value}
                        size="small"
                        fullWidth
                        type='text'
                        onChange={(e) => onChange(e.target.value)}
                        error={!!errors}
                        helperText={errors}
                        disabled={disabled}
                    />
                );

            case 'textarea':
                return (
                    <TextField
                        label={label}
                        value={value}
                        size="small"
                        fullWidth
                        multiline
                        rows={4}
                        onChange={(e) => onChange(e.target.value)}
                        error={!!errors}
                        helperText={errors}
                        disabled={disabled}
                    />
                );

            default:
                return null;
        }
    };

    return renderInput();
};

export default CustomInputFields;
