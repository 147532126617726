import React from "react";
import Login from "./pages/login/Login";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import AddApplication from "./pages/addApplication/AddApplication";
import FilterApplications from "./pages/filterApplications/FilterApplications";
import MortgageDetailed from "./pages/detailedApplication/mortgage/MortgageDetailed";
import IndustrialDetailed from "./pages/detailedApplication/industrial/IndustrialDetailed";
import UniversalDetailed from "./pages/detailedApplication/universal/UniversalDetailed";
import Users from "./pages/users/Users";
import Report from "./pages/report/Report";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@mui/material";
import SideBar from "./components/SideBar";
import axios from "axios";
import EditApplication from "./pages/editApplication/editApplication";
import MonitoringDashboard from "./pages/Monitoring/MonitoringDashboardPage";
import MonitoringForm from "./pages/Monitoring/MonitoringFormPage";
import MonitoringDetailsPage from "./pages/Monitoring/MonitoringApplicationDetailPage";
import MonitoringApprovedDetailsPage from "./pages/Monitoring/MonitoringApprovedDetailPage";
import MonitoringRoute from "./providers/MonitoringRoute";
import CustomTheme from "./providers/theme";
import MonitoringUpdateForm from "./pages/Monitoring/MonitoringUpdate";
import MonitoringAgreementDetailPage from "./pages/Monitoring/MonitoringAgreementDetailPage";
import MonitoringTabs from "./pages/Monitoring/MonitorigTabs";
import MonitoringCgsAgreementDetailPage from "./pages/Monitoring/MonitoringCgsAgreementDetailPage";
import MonitoringCgsStartForm from "./pages/Monitoring/MonitoringCgsStart";
import MonitoringCgsUpdateForm from "./pages/Monitoring/MonitoringCgsUpdate";
import MonitoringCgsApplicantDetailPage from "./pages/Monitoring/MonitoringCgsApplicationDetailPage";
import MonitoringCgsRoute from "./providers/MonitoringCgsRoute";
// import TestComponent from "./providers/MonitoringProvider";

axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("x-access-token");
    if (token) {
      config.headers["x-access-token"] = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const theme = createTheme({
  typography: {
    fontFamily: "Title",
  },
  palette: {
    primary: {
      main: "#2c5b94",
    },
    secondary: {
      main: "#142e4c",
    },
  },
});

function App() {
  const { defaultTheme } = CustomTheme();

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={defaultTheme}>
        <Router>
          {localStorage.getItem("x-access-token") ? (
            <div style={{ display: "flex" }}>
              <SideBar />
              <div className="app">
                <Switch>
                  <Route exact path="/" component={FilterApplications} />
                  <Route path="/add-application" component={AddApplication} />
                  <Route path="/edit-application" component={EditApplication} />
                  <Route exact path="/monitoring" component={MonitoringTabs} />
                  <MonitoringRoute path="/monitoring-form" component={MonitoringForm} />
                  <MonitoringRoute path="/monitoring-update-form/:id" component={MonitoringUpdateForm} />
                  <MonitoringCgsRoute path="/monitoring-update-cgs-form/:id" component={MonitoringCgsUpdateForm} />
                  <MonitoringCgsRoute path="/monitoring-start-cgs-form/:id" component={MonitoringCgsStartForm} />

                  <MonitoringRoute path="/monitoring-details/:id" component={MonitoringDetailsPage} />
                  <MonitoringCgsRoute path="/monitoring-details-cgs/:id" component={MonitoringCgsApplicantDetailPage} />

                  <MonitoringRoute path="/monitoring-approved-details/:id" component={MonitoringApprovedDetailsPage} />
                  <MonitoringRoute path="/monitoring-agreement-details/:id" component={MonitoringAgreementDetailPage} />
                  <MonitoringCgsRoute path="/monitoring-agreement-details-cgs/:id" component={MonitoringCgsAgreementDetailPage} />

                  <Route exact path="/users" component={Users} />
                  <Route exact path="/report" component={Report} />
                  <Route exact path="/legacy-mortgage-application/:id" component={MortgageDetailed} />
                  <Route exact path="/mortgage-application/:id" component={MortgageDetailed} />
                  <Route exact path="/industrial-application/:id" component={IndustrialDetailed} />
                  <Route exact path="/universal-application/:id" component={UniversalDetailed} />
                </Switch>
              </div>
            </div>
          ) : (
            <Switch>
              <Route exact path="/reset/:email/:token" component={ResetPassword} />
              <Route path="/" component={Login} />
            </Switch>
          )}
        </Router>
      </ThemeProvider>
    </MuiThemeProvider>
  );
}

export default App;
