import dateFormat from "dateformat";

export const monitoringValueMappers = {
  is_female_entrepreneur: (isTruthy: boolean) => (isTruthy ? "კი" : "არა"),
  is_mountain_region: (isTruthy: boolean) => (isTruthy ? "კი" : "არა"),
  is_income_to_k: (isTruthy: boolean) => (isTruthy ? "კი" : "არა"),
  is_sun_gate_beneficiary: (isTruthy: boolean) => (isTruthy ? "კი" : "არა"),
  is_female_entrepreneur_checked: (isTruthy: boolean) => (isTruthy ? "კი" : "არა"),
  is_mountain_region_checked: (isTruthy: boolean) => (isTruthy ? "კი" : "არა"),
  is_income_to_k_checked: (isTruthy: boolean) => (isTruthy ? "კი" : "არა"),
  is_sun_gate_beneficiary_checked: (isTruthy: boolean) => (isTruthy ? "კი" : "არა"),
  is_purchased_activities_checked: (isTruthy: boolean) => (isTruthy ? "არა" : "კი"),
  is_project_activities_checked: (isTruthy: boolean) => (isTruthy ? "კი" : "არა"),
  is_purpose_detailed_provided: (isTruthy: boolean) => (isTruthy ? "კი" : "არა"),
  contract_signed_date: (date: string) => (date ? dateFormat(date, "dd/mm/yyyy") : ""),
  start_activity_date: (date: string) => (date ? dateFormat(date, "dd/mm/yyyy") : ""),
  cgs_created_at: (date: string) => (date ? dateFormat(date, "dd/mm/yyyy") : ""),
};
