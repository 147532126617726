/**
 * @React
 */
import {
    FC,
    useState
} from "react";
/**
 * @Router
 */
import { useParams } from 'react-router-dom';
/**
 * @Yup
 */
import * as yup from "yup";
/**
 * @MUI
 */
import {
    CardContent,
    Card,
    Alert,
    Box,
    Button
} from "@mui/material";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
/**
 * @Context
 */
import {
    useGeneralInfoContext,
} from "../../../context";

/**
 * @Components
 */
import Loader from "../../../components/Loader";
/**
 * @Types
 */
import {
    IMonitoringAgreementCgsModel
} from "../../../types";
/**
 * @Utilities
 */
import { generateUrl } from "../../../utilities/generateRequestUrl";
import config from "../../../utilities/generateRequestUrl/config";
import { useFetchData } from "../../../hooks";
import MonitoringCgsDetails from "../../../components/detailedApplication/MonitoringApplication/MonitoringCgsDetails";
import { IMonitoringCgsFields } from "../../../types/monitoringCgsFields";
import MessageBox from "../../../components/CustomComponents/MessageBox";
import { monitoringCgsDetailsFields } from "../../../utilities/Fields/CgsMonitoringFields/fields/detailFields";
import CardsLayout from "../../../components/CustomComponents/CardsLayout";
import { monitoringCgsInitialDataSchema } from "../../../utilities/Fields/CgsMonitoringFields/fields/forms/validation";
import axios from "axios";

const MonitoringCgsApplicantDetailPage: FC = () => {
    const { id } = useParams<{ id: string }>();
    const [approvingLoader, setApprovingLoader] = useState<boolean>(false);

    const { data, load: loading, error: detailsError } = useFetchData<IMonitoringCgsFields & Partial<IMonitoringAgreementCgsModel> | null>(generateUrl(config.client, 'getApplicantCgs', { id: id }, null));
    let {
        generatedValues,
    } = useGeneralInfoContext();

    const applicationApproval = async (approval_status: boolean) => {
        try {
            setApprovingLoader(true);
            if (!data?.monitoring_approval) {
                await monitoringCgsInitialDataSchema.validate(data, {
                    abortEarly: false,
                })
            }
            let comment = ""
            if (!approval_status) {
                comment = prompt("კომენტარი") as string;
                if (!comment) {
                    return;
                }
            }
            if (!window.confirm("არჩევანის დადასტურება")) {
                return;
            }
            await axios
                .post("/monitoring/application_approval_cgs", { application_id: id, approval: approval_status, comment })
                .then((res) => {
                    alert(res.data.message);
                    window.location.reload();
                });
        } catch (err: any) {
            if (err instanceof yup.ValidationError) {
                const firstError = err.inner[0];
                const errorMessage = firstError.message;
                // alert(`${errorMessage}`);
            }
            if (err?.response?.data?.message) {
                alert(err.response.data.message);
            } else {
                alert("დაფიქსირდა შეცდომა, გთხოვთ მიმართოთ ვებ-გვერდის ადმინისტრატორს")
            }
        } finally {
            setApprovingLoader(false);
        }
    }

    return (
        <>

            {
                loading
                    ?
                    <Loader />
                    :
                    detailsError ?
                        <MessageBox
                            alignHorizontal="center"
                        >
                            <Alert severity="error">
                                დაფიქსირდა შეცდომა, გთხოვთ, მიმართოთ საიტის ადმინისტრატორს
                            </Alert>
                        </MessageBox>

                        :
                        data
                            &&
                            generatedValues
                            ?
                            <CardsLayout>
                                <MonitoringCgsDetails
                                    data={data || null}
                                    generatedValues={generatedValues}
                                    loading={loading}
                                    title={'ჩატარებული მონიტორინგის დეტალური ინფორმაცია'}
                                    monitoringCgsDetailsFields={monitoringCgsDetailsFields}
                                />
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    justifyItems: "center",
                                    marginTop: "20px",
                                    gap: "50px"
                                }}>
                                    <>
                                        <Button
                                            disabled={
                                                approvingLoader || data?.monitoring_approval === true}
                                            onClick={() => {
                                                applicationApproval(true);
                                            }}
                                            variant="contained"
                                            color="success"
                                            startIcon={<ThumbUpAltIcon />}
                                        >
                                            დადასტურება
                                        </Button>
                                        <Button
                                            disabled={approvingLoader}
                                            onClick={() => {
                                                applicationApproval(false);
                                            }}
                                            variant="contained"
                                            color="error"
                                            startIcon={<ThumbDownAltIcon />}
                                        >
                                            დახარვეზება
                                        </Button>
                                    </>

                                </Box>
                            </CardsLayout>


                            :
                            <MessageBox
                                alignHorizontal="center"
                            >
                                <Alert severity="warning">
                                    განაცხადი არ მოიძებნა
                                </Alert>
                            </MessageBox>
            }
        </>
    )
}

export default MonitoringCgsApplicantDetailPage;
