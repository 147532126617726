import React, { useState } from 'react';
import {
    Box,
    TextField,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    ThemeProvider,
    createTheme,
    OutlinedInput,
    FormControl,
    InputLabel,
    Autocomplete,
    FormHelperText,
} from '@mui/material';
import { IInputField, IInputFieldOption } from '../../../types';
import { useFormContext, Control, useController } from 'react-hook-form';

import { FieldValues } from 'react-hook-form';

interface CustomInputProps<T extends FieldValues> {
    name: keyof T;
    label: string;
    control: Control<T>;
    value?: T[keyof T];
    type: "number" | "select" | "textarea" | "text" | "checkbox" | "date" | "autocomplete"
    options?: IInputFieldOption[];
    disabled?: boolean;
    customChange?: (data: T) => void;
    watchedValues?: Record<string, any>;
    errors?: { message?: string };
    style?: React.CSSProperties;
}

const CustomField: React.FC<CustomInputProps<IInputField<any>>> = ({
    name,
    label,
    value,
    customChange,
    options,
    control,
    disabled = false,
    errors,
    style,
    type,
}) => {
    const [focused, setFocused] = useState(false);
    const theme = createTheme({
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        backgroundColor: "inherit",
                        borderWidth: "1px",
                        minWidth: "150px",
                        '.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input': {
                            color: "rgba(0, 0, 0, 0.70)",
                            ...(style && { ...style })
                        }
                    },
                },
            },
        },
    });
    const { getValues } = useFormContext();
    const {
        field,
    } = useController({
        name: name as string,
        control
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        let updatedValue: any;
        if (e.target.type === 'checkbox') {
            if (e.target instanceof HTMLInputElement) {
                updatedValue = e.target.checked;
            }
        } else if (e.target.type === 'date') {
            updatedValue = e.target.value;
        } else if (e.target.type === 'number') {
            updatedValue = Number(e.target.value);
        } else {
            updatedValue = e.target.value;
        }
        field.onChange(updatedValue);
        if (customChange) {
            const allValues = getValues();
            customChange(allValues as IInputField<any>)
        };
    };

    const renderInput = () => {
        switch (type) {
            case 'autocomplete':
                return (
                    <Autocomplete
                        {...field}
                        options={options ?? []}
                        getOptionLabel={(option: IInputFieldOption) => option.label}
                        renderOption={(props, option: IInputFieldOption) => (
                            <Box
                                {...props}
                                key={option.value}
                                component="li"
                                sx={{ fontSize: '11px' }}
                            >
                                {option.label}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label || ''}
                                error={errors?.message ? true : false}
                                helperText={errors?.message}
                                disabled={disabled}
                                size="small"
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "&:focus": {
                                            outline: `2px solid 'red'`,
                                        },
                                        "&:hover fieldset": {
                                            color: "#1976d2",
                                            borderColor: "#1976d2",
                                            borderWidth: "2px",
                                        },
                                    },
                                }}
                                InputLabelProps={{
                                    sx: {
                                        fontSize: "1rem",
                                        top: '-2px',
                                    },
                                    shrink: true,
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    sx: {
                                        height: '20px',
                                        padding: '2px',
                                        fontSize: 12,
                                    }
                                }}
                            />
                        )}
                        value={options && options.find((opt) => opt.value === field.value)}
                        onChange={(_, data) => {
                            const value = data?.value ?? '';
                            field.onChange(value);
                        }}
                        componentsProps={{
                            clearIndicator: {
                                sx: {
                                    '& .MuiSvgIcon-root': {
                                        fontSize: '0.8rem', // Change the size of the clear button here
                                    },
                                },
                            },
                        }}
                    />
                );

            case 'select':
                return (
                    <FormControl
                        fullWidth
                        variant="outlined"
                        sx={{
                            minWidth: 120,
                            fontSize: '12px',
                            "& .MuiOutlinedInput-root": {
                                "&:focus": {
                                    outline: `2px solid 'red'`,
                                },
                                "&:hover fieldset": {
                                    color: "#1976d2",
                                    borderColor: "#1976d2",
                                    borderWidth: "2px",
                                },
                            },
                        }}
                    >
                        <InputLabel
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '2px',
                                fontSize: focused || field.value === false || field.value ? '1rem' : '0.8rem',
                                top: '-5px',
                            }}
                        >
                            {label ?? ''}
                        </InputLabel>
                        <Select
                            sx={{
                                '& .MuiSelect-select': {
                                    fontSize: '12px',
                                },
                                hover: {
                                    borderColor: 'red'
                                }

                            }}
                            size='small'
                            error={errors?.message ? true : false}
                            input={
                                <OutlinedInput
                                    label={label}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "&:focus": {
                                                outline: `2px solid 'red'`,
                                            },
                                            "&:hover fieldset": {
                                                color: "#1976d2",
                                                borderColor: "#1976d2",
                                                borderWidth: "2px",
                                            },
                                        },
                                    }}
                                />}
                            {...field}
                            disabled={disabled}
                            onChange={(e: any) => handleChange(e)}

                        >
                            {
                                options && options.map((option: IInputFieldOption) => {
                                    return (
                                        <MenuItem
                                            sx={{ fontSize: '12px' }}
                                            key={option.label}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    )
                                })}
                        </Select>
                        {errors?.message && (
                            <FormHelperText
                                sx={{
                                    fontSize: '12px',
                                    color: '#d32f2f',
                                }}
                            >{errors?.message}</FormHelperText>
                        )}
                    </FormControl>
                );

            case 'checkbox':
                return (
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...field}
                                checked={!!field.value}
                                onChange={handleChange}
                                disabled={disabled}
                                size="small"
                            />
                        }
                        label={label}
                        sx={{
                            '& .MuiFormControlLabel-label': {
                                fontSize: '12px',
                            },
                        }}
                    />
                );

            case 'date':
                return (
                    <ThemeProvider theme={theme}>
                        <TextField
                            {...field}
                            disabled={disabled}
                            label={label}
                            value={field.value ? new Date(field.value).toISOString().split('T')[0] : null}
                            size="small"
                            type='date'
                            fullWidth
                            onChange={handleChange}
                            error={errors?.message ? true : false}
                            helperText={errors?.message}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& .MuiOutlinedInput-root": {
                                        "&:hover fieldset": {
                                            borderWidth: "2px",
                                        },
                                    },
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    fontSize: "1rem",
                                    top: '-2px',
                                },
                                shrink: true,
                            }}
                            InputProps={{
                                sx: {
                                    height: '40px',
                                    padding: '2px',
                                },
                                inputProps: {
                                    fontSize: 12,
                                    min: 0,
                                    style: {
                                        fontSize: 12,
                                    },
                                    onBlur: () => setFocused(false),
                                    onFocus: () => setFocused(true),
                                    color: "rgba(0, 0, 0, 0.87)",
                                    step: "1",
                                    padding: "10px",
                                }
                            }}
                        />
                    </ThemeProvider>
                )
            case 'number':
                return (
                    <ThemeProvider theme={theme}>
                        <TextField
                            {...field}
                            value={field.value ? field.value : 0}
                            label={label}
                            size="small"
                            type='number'
                            disabled={disabled}
                            error={errors?.message ? true : false}
                            helperText={errors?.message}
                            fullWidth
                            onChange={handleChange}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "&:focus": {
                                        outline: `2px solid 'red'`,
                                    },
                                    "&:hover fieldset": {
                                        color: "#1976d2",
                                        borderColor: "#1976d2",
                                        borderWidth: "2px",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    fontSize: "1rem",
                                    top: '-2px',
                                },
                                shrink: true,
                            }}
                            InputProps={{
                                sx: {
                                    height: '40px',
                                    padding: '2px',
                                },
                                inputProps: {
                                    fontSize: 12,
                                    transition: "font-size 0.2s, color 0.2s",
                                    min: 0, // Set the minimum value to 0
                                    style: {
                                        fontSize: 12,
                                    },
                                    onBlur: () => setFocused(false),
                                    onFocus: () => setFocused(true),
                                    color: "rgba(0, 0, 0, 0.87)",
                                    step: "1",
                                    padding: "10px",
                                }
                            }}
                        />
                    </ThemeProvider>
                )
            case 'text':
                return (
                    <ThemeProvider theme={theme}>
                        <TextField
                            {...field}
                            disabled={disabled}
                            size="small"
                            fullWidth
                            type='text'
                            label={label}
                            error={errors?.message ? true : false}
                            helperText={errors?.message}
                            onChange={handleChange}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "&:hover fieldset": {
                                        color: "#1976d2",
                                        borderColor: "#1976d2",
                                        borderWidth: "2px",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    fontSize: "1rem",
                                    top: '-2px',
                                },
                                shrink: true,
                            }}
                            InputProps={{
                                sx: {
                                    // color: "rgba(0, 0, 0, 0.8)",
                                    height: '40px',
                                    padding: '2px',
                                },
                                inputProps: {
                                    fontSize: 12,
                                    transition: "font-size 0.2s, color 0.2s",
                                    min: 0, // Set the minimum value to 0
                                    style: {
                                        fontSize: 12,
                                    },
                                    onBlur: () => setFocused(false),
                                    onFocus: () => setFocused(true),
                                    color: "rgba(0, 0, 0, 0.87)",
                                    step: "1",
                                    padding: "10px",
                                }
                            }}
                        />
                    </ThemeProvider>
                )
            case 'textarea':
                return (
                    <TextField
                        {...field}
                        disabled={disabled}
                        type={"text"}
                        label={label}
                        error={errors?.message ? true : false}
                        helperText={errors?.message}
                        multiline
                        rows={4}
                        onChange={handleChange}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "&:hover fieldset": {
                                    color: "#1976d2",
                                    borderColor: "#1976d2",
                                    borderWidth: "2px",
                                },
                            },
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: "1rem",
                                top: '-2px',
                            },
                            shrink: true,
                        }}
                        InputProps={{
                            inputProps: {
                                fontSize: 12,
                                padding: '15px',
                                transition: "font-size 0.2s, color 0.2s",
                                style: {
                                    fontSize: 12,
                                },
                                onBlur: () => setFocused(false),
                                onFocus: () => setFocused(true),
                            }
                        }}
                    />
                );

            default:
                return null;
        }
    }
    return renderInput();
};

export default CustomField;

