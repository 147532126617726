import dateFormat from "dateformat";
export const fields: {
  [key: string]: { title: string; type: string };
} = {
  contract_number: { title: "ხელშეკრულების ნომერი / სესხის/ლიზინგის ID", type: "text" },
  company_identification_code: { title: "ს/კ", type: "text" },
  company_name: { title: "ბენეფიციარის დასახელება", type: "text" },
  program: { title: "პროგრამა", type: "text" },
  enterprise_category: { title: "ბენეფიციარის სამეწარმეო კატეგორია", type: "text" },
  contract_signed_date: { title: "ხელშეკრულების გაფორმების /სესხის/ლიზინგის დადასტურების თარიღი", type: "date" },
  first_tranche_date: { title: "პირველი ტრანშის თარიღი", type: "date" },
  start_activity_date: { title: "საქმიანობის დაწყების ვადა", type: "date" },
  program_component: { title: "პროგრამის კომპონენტი", type: "text" },
  component: { title: "კომპონენტი", type: "text" },
  approved_amount: { title: "დამტკიცებული თანხა", type: "text" },
  currency: { title: "სესხის/ლიზინგის ვალუტა", type: "text" },
  bank: { title: "მიმდინარე/ბოლო ფინანსო ინსტიტუტი", type: "countries" },
  purpose: { title: "სესხის/ლიზინგის მიზნობრიობა", type: "text" },
  jobs_created: { title: "პროექტის ფარგლებში შექმნილი სამუშაო ადგილების რაოდენობა", type: "number" },
  emploee_amount: { title: "ჯამური დასაქმება", type: "number" },
  new_hotel_rooms: { title: "სასტუმროს ახალი ნომრების რაოდენობა", type: "number" },
  hotel_rooms: { title: "სასტუმროს ნომრების ჯამური რაოდენობა", type: "number" },
  project_status: { title: "პროექტის სტატუსი", type: "text" },
  project_activity: { title: "პროექტის ეკონომიკური აქტივობა", type: "text" }, // ??
  //   actual_region: "ფაქტობრივი მისამართი: რეგიონი",
  //   actual_municipality: "ფაქტობრივი მისამართი: თვითმმართველი ქალაქი/ მუნიციპალიტეტი",
  actual_address: { title: "ფაქტობრივი მისამართი:  სოფელი/ქუჩა, ქუჩის ნომერი", type: "text" }, //?
  representative_email: { title: "წარმომადგენლის ელ. ფოსტა", type: "text" },
  representative_name_lastname: { title: "წარმომადგენლის სახელი/გვარი", type: "text" },
  representative_phone: { title: "წარმომადგენლის ტელეფონი", type: "text" },
  representative_phone_secondary: { title: "დამატებით საკონტაქტო", type: "text" },
  representative_person_email: { title: "წარმომადგენლის ელ. ფოსტა", type: "text" },
  company_email: { title: "კომპანიის საჯარო ელ. ფოსტა", type: "text" },
  company_founder_phone: { title: "დამფუძნებლის ნომერი", type: "text" },
  approved: { title: "დადასტურებული / დახარვეზებული", type: "text" },
  comment: { title: "კომენტარი", type: "text" },
  approved_by: { title: "დაადასტურა / დაახარვეზა", type: "text" },
  approved_at: { title: "დადასტურების / დახარვეზები თარიღი", type: "date" },
};
export const valeueFormat = {
  contract_signed_date: (date: string) => (date ? dateFormat(date, "dd/mm/yyyy HH:MM:ss") : ""),
  jobs_created: (date: string) => (date ? dateFormat(date, "dd/mm/yyyy HH:MM:ss") : ""),
  first_tranche_date: (date: string) => (date ? dateFormat(date, "dd/mm/yyyy HH:MM:ss") : ""),
  start_activity_date: (date: string) => (date ? dateFormat(date, "dd/mm/yyyy HH:MM:ss") : ""),
  approved_at: (date: string) => (date ? dateFormat(date, "dd/mm/yyyy HH:MM:ss") : ""),
  approved: (value: boolean | undefined) => (value ? "დადასტურდა" : value === false ? "დახარვეზდა" : "განსახილველი"),
};
