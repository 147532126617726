import { useState, useEffect, useCallback } from "react";
import axiosInstance from "../../utilities/axios";
interface ApiResponse<T> {
  data: T | null;
  load: boolean;
  error: any;
  fetchData?: () => void;
}
const useFetchData = <T>(
  url: string,
  setState?: (dt: T) => void,
  callback?: (...args: any[]) => void,
  ...rest: (string | number | boolean | null | undefined)[]
): ApiResponse<T> => {
  const [data, setData] = useState<T | null>(null);
  const [load, setLoad] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  const fetchData = useCallback(async (): Promise<void> => {
    try {
      const response = await axiosInstance.get<T>(url);
      setState && setState(response.data);
      setData(response.data);
      callback && callback(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoad(false);
    }
  }, [url, setState, callback]);

  useEffect(() => {
    if (url) {
      fetchData();
    }

    return () => {
      //  cancelTokenSource.cancel('Request canceled by cleanup'); // maybe it would be needed
    };
  }, [url, fetchData, ...(rest || [])]);

  return { data, load, error, fetchData };
};

export default useFetchData;
