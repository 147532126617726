/**
 * @React
 */
import React, { FC } from "react";
/**
 * @MUI
 */
import { Box, Skeleton } from "@mui/material";
type LoadingSkeletonsProps = {
    loading?: boolean;
    children?: React.ReactNode;
    variant?: "text" | "rectangular" | "circular" | "rounded";
    width?: number;
    animation?: "pulse" | "wave";
};
const LoadingSkeletons: FC<LoadingSkeletonsProps> = ({
    loading = false,
    variant = 'text',
    animation = 'pulse',
    width = 210,
    children
}) => {
    return (
        <Box sx={{
            width: "100%",
        }}>
            {
                loading
                    ?
                    <Skeleton
                        variant={variant}
                        animation={animation}
                        width={width}
                        sx={{ width: '100%' }}
                    />
                    :
                    <>
                        {children}
                    </>
            }
        </Box>
    );
};

export default LoadingSkeletons;