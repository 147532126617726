import { FC, ReactNode, useEffect } from "react";
interface DetailTableTRProps {
    className: any;
    children: ReactNode | string;
}

const DetailTableTD: FC<DetailTableTRProps> = ({ className, children }) => {

    return (
        <>
            <td className={className}>
                {children}
            </td>
        </>
    )
}

export default DetailTableTD;