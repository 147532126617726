import { IMonitoringAgreementCgsModel, IMonitoringCgsFields } from "../../../../types";

export const monitoring_agreemet_cgs_initial_data: Partial<IMonitoringCgsFields> & Partial<IMonitoringAgreementCgsModel> = {
  used_amount: 0,
  fixed_assets_capital_expenditures: 0,
  machine_equipment_equipment: 0,
  vehicles_special_technic: 0,
  purchase_building_land: 0,
  building_repairs: 0,
  funds_turnover: 0,
  is_female_entrepreneur_checked: false,
  female_entrepreneur_comment: "",
  is_mountain_region_checked: false,
  mountain_region_comment: "",
  is_income_to_k_checked: false,
  income_to_k_comment: "",
  is_sun_gate_beneficiary_checked: false,
  sun_gate_beneficiary_comment: "",
  is_purpose_detailed_provided: false,
  purpose_detailed_comment: "",
  is_project_activities_checked: false,
  project_activities_comment: "",
  representative_id_number: "",
  representative_position: "",
  attached_documents: {
    monitoring_attachments: [],
  },
};

export const monitoring_application_cgs_initial_data: Partial<IMonitoringCgsFields> & Partial<IMonitoringAgreementCgsModel> = {
  used_amount: 0,
  fixed_assets_capital_expenditures: 0,
  machine_equipment_equipment: 0,
  vehicles_special_technic: 0,
  purchase_building_land: 0,
  building_repairs: 0,
  funds_turnover: 0,
  female_entrepreneur_comment: "",
  mountain_region_comment: "",
  income_to_k_comment: "",
  sun_gate_beneficiary_comment: "",
  purpose_detailed_comment: "",
  project_activities_comment: "",
  is_purchased_activities_checked:false,
  purchased_activities_comment:'',
  attached_documents: {
    monitoring_attachments: [],
  },
};
