import "./IndustrialDetailed.css";
import { PostData } from "../../../utilities/PostData";
import { useState, useEffect } from "react";
import DetailedStyles from "../../../utilities/DetailedStyles";
import { Card, CardContent, Button, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import dateFormat from "dateformat";
import CheckIcon from "@material-ui/icons/Check";
import CachedIcon from "@material-ui/icons/Cached";
import ErrorIcon from "@material-ui/icons/Error";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CloseLoan from "../../../components/detailedApplication/CloseLoan";
import ClosureData from "../../../components/detailedApplication/ClosureData";
import ReactDOM from "react-dom";
import SetDetailedApplicationData from "../../../utilities/SetDetailedApplicationData";
import GetAgencyPosition from "../../../utilities/GetAgencyPosition";
import ApprovalHistoryTable from "../../../components/detailedApplication/ApprovalHistoryTable";
import LoanID from "../../../components/detailedApplication/LoanID";
import InternalAttachments from "../../../components/detailedApplication/internalAttachments/InternalAttachments";

function IndustrialDetailed(props) {
  const [applicationInformation, setApplicationInformation] = useState();
  const [isBank, setIsBank] = useState();
  const [isBankHead, setIsBankHead] = useState();
  const [agencyLevel, setAgencyLevel] = useState();
  const [canModifyScheduling, setCanModifyScheduling] = useState(false);
  const [loading, setLoading] = useState(true);
  const classes = DetailedStyles();

  useEffect(() => {
    SetDetailedApplicationData({
      setIsBank,
      setIsBankHead,
      setCanModifyScheduling,
      setAgencyLevel,
      setApplicationInformation,
      id: props.match.params.id,
      setLoading,
      applicationType: "industrial",
    });
  }, []);
  useEffect(() => {
    let details = document.getElementsByClassName(classes.detail);
    if (loading) {
      //Create new material-ui <Skeleton /> for each detail
      for (let i = 0; i < details.length; i++) {
        let skeleton = document.createElement("div");
        skeleton.className = "skeleton";
        ReactDOM.render(<Skeleton variant="text" />, skeleton);
        details[i].appendChild(skeleton);
      }
    } else {
      let skeletons = document.getElementsByClassName("skeleton");
      for (let i = 0; i < skeletons.length; i++) {
        skeletons[i].style.display = "none";
      }
    }
  }, [loading]);

  const approvalStatus = () => {
    if (applicationInformation?.suspended) {
      return { name: "გაუქმებული", icon: <RemoveCircleIcon color="primary" /> };
    }
    if (applicationInformation?.approved === true) {
      return { name: "დადასტურებული", icon: <CheckIcon color="primary" /> };
    } else if (applicationInformation?.approved === false) {
      return { name: "უარყოფილი", icon: <ErrorIcon color="primary" /> };
    } else {
      return { name: "განსახილველი", icon: <CachedIcon color="primary" /> };
    }
  };

  const submitApprovalHandler = (approvalStatus) => {
    let rejectionComment;
    if (!approvalStatus) {
      rejectionComment = prompt("უარყოფის მიზეზი: ");
    }
    PostData("agency/approve_industrial_application", {
      application_id: props.match.params.id,
      approval: approvalStatus,
      rejection_comment: rejectionComment,
    }).then((response) => {
      alert(response?.message);
      window.location.reload();
    });
  };

  const suspendApplicationHandler = () => {
    let suspensionReason = prompt("გაუქმების მიზეზი: ");
    if (!suspensionReason) return;
    if (!window.confirm("გაუქმების დადასტურება")) return;

    let request = { application_type: "industrial", application_id: props.match.params.id, suspension_reason: suspensionReason };
    PostData("agency/suspend_application", request).then((response) => {
      alert(response?.message);
      window.location.reload();
    });
  };

  return (
    <>
      <Card className={classes.root}>
        <CardContent className={classes.innerCardContent}>
          <div className="left" style={{ width: window.innerWidth < 600 ? "100%" : "50%" }}>
            <Card className={classes.component} variant="outlined">
              <Typography className={classes.cardSummary}>სესხის შესახებ ინფორმაცია</Typography>
              <CardContent className={classes.innerCardContent}>
                <table className={classes.table}>
                  <tbody>
                    <tr className={classes.row}>
                      <td className={classes.title}>სესხის/ლიზინგის ID:</td>
                      <td className={classes.detail}>
                        <LoanID application={applicationInformation} isBankHead={isBankHead} />
                      </td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>სტატუსი:</td>
                      <td className={classes.detail} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ margin: "0 2px 0 0" }}>{approvalStatus()?.icon}</div>
                        <p>{approvalStatus()?.name}</p>
                      </td>
                    </tr>

                    {applicationInformation?.suspended ? (
                      <tr className={classes.row}>
                        <td className={classes.title}>გაუქმების მიზეზი:</td>
                        <td className={classes.detail}>{applicationInformation?.suspension_reason}</td>
                      </tr>
                    ) : null}
                    {applicationInformation?.rejection_comment ? (
                      <tr className={classes.row}>
                        <td className={classes.title}>უარყოფის მიზეზი:</td>
                        <td className={classes.detail}>{applicationInformation?.rejection_comment}</td>
                      </tr>
                    ) : null}
                    <tr className={classes.row}>
                      <td className={classes.title}>ტიპი:</td>
                      <td className={classes.detail}>{applicationInformation?.application_type?.name}</td>
                    </tr>
                    <ClosureData classes={classes} applicationInformation={applicationInformation} />
                    <tr className={classes.row}>
                      <td className={classes.title}>კომპონენტი:</td>
                      <td className={classes.detail}>{applicationInformation?.program_type?.name}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>საკრედიტო საგარანტიო სქემით სარგებლობა:</td>
                      <td className={classes.detail}>{applicationInformation?.credit_guarantee_scheme ? "მონიშნულია" : "არ არის მონიშნული"}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>ფინანსური ინსტიტუცია:</td>
                      <td className={classes.detail}>{applicationInformation?.bank?.name}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>თანამშრომელი:</td>
                      <td className={classes.detail}>{applicationInformation?.user?.name}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>თანამშრომლის ელ-ფოსტა:</td>
                      <td className={classes.detail}>{applicationInformation?.user?.email}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>ფინ. ინსტიტუტში დამტკიცების თარიღი:</td>
                      <td className={classes.detail}>{dateFormat(applicationInformation?.loan_information?.conditions?.approval_date, "yyyy-mm-dd", "T")}</td>
                    </tr>
                    {applicationInformation &&
                      applicationInformation.approved &&
                      applicationInformation.approval_history &&
                      applicationInformation.approval_history.length > 0 && (
                        <tr className={classes.row}>
                          <td className={classes.title}>სააგენტოში დამტკიცების თარიღი:</td>
                          <td className={classes.detail}>
                            {dateFormat(applicationInformation.approval_history[applicationInformation.approval_history.length - 1].date, "yyyy-mm-dd", "T")}
                          </td>
                        </tr>
                      )}
                    <tr className={classes.row}>
                      <td className={classes.title}>დამტკიცებული ვადა (თვე):</td>
                      <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.approval_months}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>თანხა:</td>
                      <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.approved_amount?.toLocaleString()}</td>
                    </tr>
                    {applicationInformation?.loan_information?.conditions?.upfront_amount ? (
                      <tr className={classes.row}>
                        <td className={classes.title}>თანამონაწილეობა/წინასწარი შენატანი (GEL):</td>
                        <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.upfront_amount}</td>
                      </tr>
                    ) : null}
                    <tr className={classes.row}>
                      <td className={classes.title}>მიზნობრიობის ტიპი:</td>
                      <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.type?.name}</td>
                    </tr>
                    {applicationInformation?.loan_information?.conditions?.purpose?.general?.amount ? (
                      <>
                        <tr className={classes.row}>
                          <td className={classes.title}>კაპიტალური ხარჯების თანხა:</td>
                          <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.general?.amount?.toLocaleString()}</td>
                        </tr>
                        {applicationInformation?.loan_information?.conditions?.purpose?.general?.percentage ? (
                          <tr className={classes.row}>
                            <td className={classes.title}>კაპიტალური ხარჯების %:</td>
                            <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.general?.percentage}%</td>
                          </tr>
                        ) : null}
                      </>
                    ) : null}
                    {applicationInformation?.loan_information?.conditions?.purpose?.mixed?.amount ? (
                      <>
                        <tr className={classes.row}>
                          <td className={classes.title}>საბრუნავი საშუალების თანხა:</td>
                          <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.mixed?.amount?.toLocaleString()}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>საბრუნავი საშუალების %:</td>
                          <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.mixed?.percentage}%</td>
                        </tr>
                      </>
                    ) : null}
                    <tr className={classes.row}>
                      <td className={classes.title}>მიზნობრიობა დეტალურად:</td>
                      <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.purpose_detailed}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>პროცენტის ტიპი:</td>
                      <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.interest_rate_type?.name}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>საპროცენტო განაკვეთი:</td>
                      <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.interest_rate}%</td>
                    </tr>
                    {applicationInformation?.loan_information?.conditions?.purpose?.variable_rate ? (
                      <tr className={classes.row}>
                        <td className={classes.title}>ბანკის მარჟა %:</td>
                        <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.variable_rate}%</td>
                      </tr>
                    ) : null}

                    <tr className={classes.row}>
                      <td className={classes.title}>ე.ბ.რ.გ.:</td>
                      <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.refinancing_rate}%</td>
                    </tr>
                    {applicationInformation?.loan_information?.conditions?.purpose?.mixed_interest_rate ? (
                      <tr className={classes.row}>
                        <td className={classes.title}>შერეული საპროცენტო განაკვეთი:</td>
                        <td className={classes.detail}>{applicationInformation?.loan_information?.conditions?.purpose?.mixed_interest_rate}</td>
                      </tr>
                    ) : null}
                    {applicationInformation?.loan_information?.conditions?.purpose?.co_financing_amount ? (
                      <tr className={classes.row}>
                        <td className={classes.title}>თანადაფინანსების თანხა:</td>
                        <td className={classes.detail}>
                          {parseFloat(applicationInformation?.loan_information?.conditions?.purpose?.co_financing_amount?.toFixed(2))?.toLocaleString()}
                        </td>
                      </tr>
                    ) : null}
                    <tr className={classes.row}>
                      <td className={classes.title}>შვილობილი/მშობელი კომპანიის შეზღუდვა:</td>
                      <td className={classes.detail}>
                        {applicationInformation?.loan_information?.conditions?.purpose?.child_company_restriction ? "მონიშნულია" : "არ არის მონიშნული"}
                      </td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>აპლიკაციის შემოსვლის თარიღი:</td>
                      <td className={classes.detail}>{dateFormat(applicationInformation?.submit_date, "yyyy-mm-dd", "T")}</td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
            <Card className={classes.component} variant="outlined">
              <Typography className={classes.cardSummary}>დამფუძნებლები</Typography>
              <CardContent>
                <table className={classes.table}>
                  <tbody>
                    {applicationInformation?.founders.map((founder) => (
                      <>
                        <tr className={classes.row}>
                          <td className={classes.title}>დამფუძნებლის სამმართვლებრივი ფორმა:</td>
                          <td className={classes.detail}>{founder?.legal_form?.name}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>დამფუძნებლის დასახელება:</td>
                          <td className={classes.detail}>{founder?.name}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>საიდენტიფიკაციო კოდი:</td>
                          <td className={classes.detail}>{founder?.identification_code}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>წილი:</td>
                          <td className={classes.detail}>{founder?.ownership_percentage}%</td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </CardContent>
            </Card>
            <Card className={classes.component} variant="outlined">
              <Typography className={classes.cardSummary}>გენდერული სტატისტიკა</Typography>
              <CardContent>
                <table className={classes.table}>
                  <tbody>
                    <tr className={classes.row}>
                      <td className={classes.title}>დირექტორი ქალები:</td>
                      <td className={classes.detail}>{applicationInformation?.gender_statistics?.director_women}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>დამფუძნებელი ქალები:</td>
                      <td className={classes.detail}>{applicationInformation?.gender_statistics?.founder_women}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>ქალების ჯამური მფლობელობის წილი:</td>
                      <td className={classes.detail}>{applicationInformation?.gender_statistics?.stakes_women}%</td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
            <Card className={classes.component} variant="outlined">
              <Typography className={classes.cardSummary}>პროექტის ინფორმაცია</Typography>
              <CardContent>
                <table className={classes.table}>
                  <tbody>
                    <tr className={classes.row}>
                      <td className={classes.title}>პროექტის ტიპი:</td>
                      <td className={classes.detail}>{applicationInformation?.project_information?.project_type}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>ეკონომიკური აქტივობა:</td>
                      <td>
                        {applicationInformation?.project_information?.economic_activity.map((activity) => (
                          <td className={classes.detail} style={{ fontSize: 10, maxWidth: 130 }}>
                            {activity.activity_code} {activity.activity_description},
                          </td>
                        ))}
                      </td>
                    </tr>
                    {applicationInformation?.project_information?.actual_address?.municipality?.region ? (
                      <>
                        <tr className={classes.row}>
                          <td className={classes.title}>რეგიონი:</td>
                          <td className={classes.detail}>{applicationInformation?.project_information?.actual_address?.municipality?.region}</td>
                        </tr>
                      </>
                    ) : null}

                    <tr className={classes.row}>
                      <td className={classes.title}>მუნიციპალიტეტი:</td>
                      <td className={classes.detail}>
                        {applicationInformation?.project_information?.actual_address?.municipality?.municipality
                          ? applicationInformation?.project_information?.actual_address?.municipality?.municipality
                          : applicationInformation?.project_information?.actual_address?.balneological_village?.municipality}
                      </td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>პროექტის განხორციელების ფაქტობრივი მისამართი:</td>
                      <td className={classes.detail}>{applicationInformation?.project_information?.actual_address?.street}</td>
                    </tr>
                    {applicationInformation?.project_information?.actual_address?.balneological_village?.municipality ? (
                      <>
                        <tr className={classes.row}>
                          <td className={classes.title}>სოფელი/დასახლება:</td>
                          <td className={classes.detail}>{applicationInformation?.project_information?.actual_address?.balneological_village?.village}</td>
                        </tr>
                      </>
                    ) : null}

                    <tr className={classes.row}>
                      <td className={classes.title}>საკადასტრო კოდი:</td>
                      <td className={classes.detail}>{applicationInformation?.project_information?.actual_address?.cadastral_code}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>ტერიტორიის სარგებლობის ტიპი:</td>
                      <td className={classes.detail}>{applicationInformation?.project_information?.territory_usage_type}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>ჯამური ინვესტიცია:</td>
                      <td className={classes.detail}>{applicationInformation?.project_information?.total_investment}</td>
                    </tr>

                    <tr className={classes.row}>
                      <td className={classes.title}>დასრულების ვადა:</td>
                      <td className={classes.detail}>{applicationInformation?.project_information?.completion_time}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>ადაპტირებულია შშმ პირთათვის:</td>
                      <td className={classes.detail}>{applicationInformation?.disabilities?.is_adapted ? "მონიშნულია" : "არ არის მონიშნული"}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>იგეგმება ადაპტაცია:</td>
                      <td className={classes.detail}>{applicationInformation?.disabilities?.is_planned_to_adapt ? "მონიშნულია" : "არ არის მონიშნული"}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>შშმ თანამშრომელთა რაოდენობა:</td>
                      <td className={classes.detail}>{applicationInformation?.disabilities?.disabled_employees}</td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
            {applicationInformation?.refinancing_information?.loan_id ? (
              <>
                <Card className={classes.component} variant="outlined">
                  <Typography className={classes.cardSummary}>დარეფინანსირებული სესხის ინფორმაცია</Typography>
                  <CardContent>
                    <table className={classes.table}>
                      <tbody>
                        <tr className={classes.row}>
                          <td className={classes.title}>ბანკი (საწყისი):</td>
                          <td className={classes.detail}>{applicationInformation?.refinancing_information?.initial_bank?.name}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>დარეფინანსირებელი სესხის ID:</td>
                          <td className={classes.detail}>{applicationInformation?.refinancing_information?.loan_id}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>სესხის პირველი ტრანშის გაცემის თარიღი:</td>
                          <td className={classes.detail}>
                            {dateFormat(applicationInformation?.refinancing_information?.first_disbursment_date, "yyyy-mm-dd", "T")}
                          </td>
                        </tr>
                        {/* <tr className={classes.row}>
                          <td className={classes.title}>მიზნობრივი თანადაფინანსება წლიური საპროცენტო განაკვეთიდან %:</td>
                          <td className={classes.detail}>{applicationInformation?.refinancing_information?.annual_co_financing}</td>
                        </tr> */}
                        <tr className={classes.row}>
                          <td className={classes.title}>დამტკიცებული თანხა (GEL):</td>
                          <td className={classes.detail}>{applicationInformation?.refinancing_information?.approved_amount}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>ნაშთი (დარჩენილი ძირითადი თანხა) GEL:</td>
                          <td className={classes.detail}>{applicationInformation?.refinancing_information?.remaining_amount}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>დარჩენილი ასათვისებელი მოცულობა (GEL) (არსებობის შემთხვევაში):</td>
                          <td className={classes.detail}>{applicationInformation?.refinancing_information?.outstanding_amount}</td>
                        </tr>
                      </tbody>
                    </table>
                  </CardContent>
                </Card>
              </>
            ) : null}
            {!isBank && (
              <Card className={classes.component} variant="outlined">
                <CardContent>
                  <InternalAttachments
                    externalClasses={classes}
                    applicationId={props.match.params.id}
                    component="industrial"
                    initialFiles={applicationInformation?.internal_attachments?.map((attachment) => ({
                      fileName: attachment.path.split("/").pop(),
                      url: process.env.REACT_APP_BASE_URL + attachment.path,
                    }))}
                  />
                </CardContent>
              </Card>
            )}
          </div>

          <div className="right" style={{ width: window.innerWidth < 600 ? "100%" : "50%" }}>
            {applicationInformation?.approval_history?.length > 0 && !isBank ? (
              <ApprovalHistoryTable classes={classes} applicationInformation={applicationInformation} />
            ) : null}
            {/* <WorldBankTable classes={classes} applicationInformation={applicationInformation} /> */}
            <Card className={classes.component} variant="outlined">
              <Typography className={classes.cardSummary}>აპლიკანტი</Typography>
              <CardContent>
                <table className={classes.table}>
                  <tbody>
                    <tr className={classes.row}>
                      <td className={classes.title}>დამფუძნებლის სამართლებრივი ფორმა:</td>
                      <td className={classes.detail}>{applicationInformation?.applicant_information?.legal_form?.name}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>დამფუძნებლის დასახელება:</td>
                      <td className={classes.detail}>{applicationInformation?.applicant_information?.name}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>საიდენტიფიკაციო კოდი:</td>
                      <td className={classes.detail}>{applicationInformation?.applicant_information?.identification_code}</td>
                    </tr>
                    {/* <tr className={classes.row}>
                      <td className={classes.title}>რეგიონი:</td>
                      <td className={classes.detail}>{applicationInformation?.applicant_information?.legal_address?.municipality?.region}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>მუნიციპალიტეტი:</td>
                      <td className={classes.detail}>{applicationInformation?.applicant_information?.legal_address?.municipality?.municipality}</td>
                    </tr> */}
                    <tr className={classes.row}>
                      <td className={classes.title}>იურიდიული მისამართი:</td>
                      <td className={classes.detail}>{applicationInformation?.applicant_information?.legal_address}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>დაარსების თარიღი (წელი):</td>
                      <td className={classes.detail}>{applicationInformation?.applicant_information?.foundation_year}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>ელ-ფოსტა:</td>
                      <td className={classes.detail}>{applicationInformation?.applicant_information?.email}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>სამეწარმეო კატეგორია:</td>
                      <td className={classes.detail}>{applicationInformation?.applicant_information?.enterprise_category}</td>
                    </tr>
                    {applicationInformation?.applicant_information?.enterprise_category_comment ? (
                      <tr className={classes.row}>
                        <td className={classes.title}>კატეგორიის კომენტარი:</td>
                        <td className={classes.detail}>{applicationInformation?.applicant_information?.enterprise_category_comment}</td>
                      </tr>
                    ) : null}
                    <tr className={classes.row}>
                      <td className={classes.title}>საგადასახადო ვალდებულებები:</td>
                      <td className={classes.detail}>{applicationInformation?.applicant_information?.tax_liabilities ? "მონიშნულია" : "არ არის მონიშნული"}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>რეგისტრირებული მევალე:</td>
                      <td className={classes.detail}>
                        {applicationInformation?.applicant_information?.registered_debtor ? "მონიშნულია" : "არ არის მონიშნული"}
                      </td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>თანამშრომელთა რაოდენობა:</td>
                      <td className={classes.detail}>{applicationInformation?.applicant_information?.employee_amount}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>შექმნილი სამუშაო ადგილები:</td>
                      <td className={classes.detail}>{applicationInformation?.project_information?.jobs_created}</td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
            <Card className={classes.component} variant="outlined">
              <Typography className={classes.cardSummary}>ხელმომწერი პირები</Typography>
              <CardContent>
                <table className={classes.table}>
                  <tbody>
                    {applicationInformation?.signers.map((signer) => (
                      <>
                        <tr className={classes.row}>
                          <td className={classes.title}>სახელი:</td>
                          <td className={classes.detail}>
                            {signer.first_name} {signer.last_name}
                          </td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>პოზიცია:</td>
                          <td className={classes.detail}>{signer.position}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>პირადი ნომერი:</td>
                          <td className={classes.detail}>{signer.private_number}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>ტელ. ნომერი:</td>
                          <td className={classes.detail}>{signer.phone_number}</td>
                        </tr>
                        <tr className={classes.row}>
                          <td className={classes.title}>ელ-ფოსტა:</td>
                          <td className={classes.detail}>{signer.email}</td>
                        </tr>
                        {signer.poa_name ? (
                          <>
                            <tr className={classes.row}>
                              <td className={classes.title}>მინდობილი პირის სახელი:</td>
                              <td className={classes.detail}>{signer.poa_name}</td>
                            </tr>
                            <tr className={classes.row}>
                              <td className={classes.title}>მინდობილი პირის საიდენტიფიკაციო კოდი:</td>
                              <td className={classes.detail}>{signer.poa_identification_code}</td>
                            </tr>
                            <tr className={classes.row}>
                              <td className={classes.title}>მინდობილი პირის რეკვიზიტები:</td>
                              <td className={classes.detail}>{signer.poa_requisites}</td>
                            </tr>
                          </>
                        ) : null}
                      </>
                    ))}
                  </tbody>
                </table>
              </CardContent>
            </Card>
            <Card className={classes.component} variant="outlined">
              <Typography className={classes.cardSummary}>შშმ ადაპტაცია</Typography>
              <CardContent>
                <table className={classes.table}>
                  <tbody>
                    <tr className={classes.row}>
                      <td className={classes.title}>ადაპტირებულია შშმ პირთათვის:</td>
                      <td className={classes.detail}>{applicationInformation?.disabilities?.is_adapted ? "მონიშნულია" : "არ არის მონიშნული"}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>იგეგმება ადაპტაცია:</td>
                      <td className={classes.detail}>{applicationInformation?.disabilities?.is_planned_to_adapt ? "მონიშნულია" : "არ არის მონიშნული"}</td>
                    </tr>
                    <tr className={classes.row}>
                      <td className={classes.title}>შშმ თანამშრომელთა რაოდენობა:</td>
                      <td className={classes.detail}>{applicationInformation?.disabilities?.disabled_employees}</td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
            <Card className={classes.component} variant="outlined">
              <Typography className={classes.cardSummary}>მიმაგრებული ფაილები</Typography>
              <CardContent>
                <table className={classes.table}>
                  <tbody>
                    {applicationInformation?.attachments?.poa_proof.map((attachment) => (
                      <tr className={classes.row}>
                        <td className={classes.title}>დანართი:</td>
                        <td className={classes.detail}>
                          <a href={process.env.REACT_APP_BASE_URL + attachment}>ფაილი</a>
                        </td>
                      </tr>
                    ))}
                    {applicationInformation?.attachments?.elevator_absence_reason.map((attachment) => (
                      <tr className={classes.row}>
                        <td className={classes.title}>ლიფტის არარსებობის მიზეზი:</td>
                        <td className={classes.detail}>
                          <a href={process.env.REACT_APP_BASE_URL + attachment}>ფაილი</a>
                        </td>
                      </tr>
                    ))}
                    {applicationInformation?.attachments?.building_permit.map((attachment) => (
                      <tr className={classes.row}>
                        <td className={classes.title}>სასტუმროს/ბალნეოლოგიური კურორტის მშენებლობის ნებართვა:</td>
                        <td className={classes.detail}>
                          <a href={process.env.REACT_APP_BASE_URL + attachment}>ფაილი</a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardContent>
            </Card>
            {applicationInformation?.tourist_services_information ? (
              <Card className={classes.component} variant="outlined">
                <Typography className={classes.cardSummary}>ტურისტული სერვისები</Typography>
                <CardContent>
                  <table className={classes.table}>
                    <tbody>
                      <tr className={classes.row}>
                        <td className={classes.title}>სტუმრის სიცოცხლისა და ჯანმრთელობის დაზღვევა:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.tourist_services_information?.adventure_tourism_measures?.guest_life_insurance
                            ? "მონიშნულია"
                            : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>
                          ჯგუფური ტურისტული აქტივობების შემთხვევაში ერთდროულად (დროის ერთ მონაკვეთში) არანაკლებ 20 ადამიანის მომსახურებას:
                        </td>
                        <td className={classes.detail}>
                          {applicationInformation?.tourist_services_information?.adventure_tourism_measures?.group_service ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>
                          ინდივიდუალური ტურისტული აქტივობების შემთხვევაში ერთდროულად (დროის ერთ მონაკვეთში) არანაკლებ 5 ადამიანის მომსახურებას:
                        </td>
                        <td className={classes.detail}>
                          {applicationInformation?.tourist_services_information?.adventure_tourism_measures?.individual_service
                            ? "მონიშნულია"
                            : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>ძველი დროის პერიოდის სიმულაცია:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.tourist_services_information?.historic_simulation ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>ლიფტი:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.tourist_services_information?.elevator ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      {applicationInformation?.tourist_services_information?.elevator_absence_reason ? (
                        <>
                          <tr className={classes.row}>
                            <td className={classes.title}>ლიფტის არარსებობის მიზეზი:</td>
                            <td className={classes.detail}>{applicationInformation?.tourist_services_information?.elevator_absence_reason}</td>
                          </tr>
                        </>
                      ) : null}
                      <tr className={classes.row}>
                        <td className={classes.title}>გარე განათება:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.tourist_services_information?.utilities?.outside_light ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>შიდა განათება:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.tourist_services_information?.utilities?.inside_light ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>სანიტარული კეთილმოყობა:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.tourist_services_information?.utilities?.water_utilities ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>ოთახ(ებ)ის, პავილიონ(ებ)ის განათება და ელექტრომომარაგება:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.tourist_services_information?.utilities?.room_utilities ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CardContent>
              </Card>
            ) : null}
            {applicationInformation?.project_information?.hotel_information ? (
              <Card className={classes.component} variant="outlined">
                <Typography className={classes.cardSummary}>სასტუმროს ინფორმაცია</Typography>
                <CardContent>
                  <table className={classes.table}>
                    <tbody>
                      {applicationInformation?.international_brand?.agreement_number ? (
                        <>
                          <tr className={classes.row}>
                            <td className={classes.title}>საერთაშორისო ბრენდი - ხელშეკრულების ნომერი:</td>
                            <td className={classes.detail}>{applicationInformation?.international_brand?.agreement_number}</td>
                          </tr>
                          <tr className={classes.row}>
                            <td className={classes.title}>საერთაშორისო ბრენდი - ხელშეკრულების თარიღი:</td>
                            <td className={classes.detail}> {dateFormat(applicationInformation?.international_brand?.agreement_date, "yyyy-mm-dd", "T")}</td>
                          </tr>
                        </>
                      ) : null}
                      <tr className={classes.row}>
                        <td className={classes.title}>არსებული ნომრების რაოდენობა:</td>
                        <td className={classes.detail}>{applicationInformation?.project_information?.hotel_information?.existing_rooms_amount}</td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>პროექტის ფარგლებში დამატებული ნომრების რაოდენობა:</td>
                        <td className={classes.detail}>{applicationInformation?.project_information?.hotel_information?.additional_rooms_amount}</td>
                      </tr>
                      {applicationInformation?.project_information?.hotel_information?.criteria?.permit_absence_reason ? (
                        <tr className={classes.row}>
                          <td className={classes.title}>ნებართვის არარსებობის მიზეზი:</td>
                          <td className={classes.detail}>{applicationInformation?.project_information?.hotel_information?.criteria?.permit_absence_reason}</td>
                        </tr>
                      ) : null}
                      <tr className={classes.row}>
                        <td className={classes.title}>ნებართვა გაცემულია:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.project_information?.hotel_information?.criteria?.permit_given ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      {applicationInformation?.project_information?.hotel_information?.criteria?.permit_given ? (
                        <>
                          <tr className={classes.row}>
                            <td className={classes.title}>ნებართვის ნომერი:</td>
                            <td className={classes.detail}>{applicationInformation?.project_information?.hotel_information?.criteria?.permit_number}</td>
                          </tr>
                          <tr className={classes.row}>
                            <td className={classes.title}>ნებართვის გამცემი ორგანო:</td>
                            <td className={classes.detail}>{applicationInformation?.project_information?.hotel_information?.criteria?.permit_organization}</td>
                          </tr>
                        </>
                      ) : null}
                      <tr className={classes.row}>
                        <td className={classes.title}>სასტუმროს ნომრები აკმაყოფილებს პროგრამის კრიტერიუმებს:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.project_information?.hotel_information?.satisfies_criteria ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>ლიფტი:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.project_information?.hotel_information?.elevator ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      {applicationInformation?.project_information?.hotel_information?.elevator_absence_reason ? (
                        <>
                          <tr className={classes.row}>
                            <td className={classes.title}>ლიფტის არარსებობის მიზეზი:</td>
                            <td className={classes.detail}>{applicationInformation?.project_information?.hotel_information?.elevator_absence_reason}</td>
                          </tr>
                        </>
                      ) : null}
                      <tr className={classes.row}>
                        <td className={classes.title}>სანიტარული კეთილმოწყობა და ელექტრომომარაგება:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.project_information?.utilities?.utilities_criteria ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>ხმაურის კონტროლი/ჰაერის კონდიცირება:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.project_information?.air_conditioning?.noise_conditioning ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>უსაფრთხოების მინიმალური მოთხოვნები:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.project_information?.security_measures?.fire_safety ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>პირობები შეზღუდული შესაძლებლობის მქონე პირთათვის:</td>
                        <td className={classes.detail}>
                          {applicationInformation?.project_information?.disability_measures?.disabled_criteria ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>
                      <tr className={classes.row}>
                        <td className={classes.title}>სტუმართა რეგისტრაცია (რეცეფცია):</td>
                        <td className={classes.detail}>
                          {applicationInformation?.project_information?.reception?.reception_criteria ? "მონიშნულია" : "არ არის მონიშნული"}
                        </td>
                      </tr>

                      <tr className={classes.row}>
                        <td className={classes.title}>სასადილო ან რესტორანი:</td>
                        <td className={classes.detail}>{applicationInformation?.project_information?.catering ? "მონიშნულია" : "არ არის მონიშნული"}</td>
                      </tr>
                    </tbody>
                  </table>
                </CardContent>
              </Card>
            ) : null}
          </div>
          <div className={classes.buttonContainer}>
            {!isBank && agencyLevel > 0 && typeof applicationInformation?.approved === "undefined" ? (
              <>
                <Button onClick={() => submitApprovalHandler(true)} variant="contained" color="primary" className={classes.button} startIcon={<CheckIcon />}>
                  დადასტურება
                </Button>
                <Button onClick={() => submitApprovalHandler(false)} variant="contained" color="primary" className={classes.button} startIcon={<ErrorIcon />}>
                  უარყოფა
                </Button>
              </>
            ) : null}
            <CloseLoan
              agencyLevel={agencyLevel}
              approved={applicationInformation?.approved}
              bankPayments={applicationInformation?.bank_payment_history}
              component="industrial"
              loan_id={props.match.params.id}
              reloading={loading}
            />
          </div>

          {!isBank && agencyLevel >= 2 && !applicationInformation?.suspended ? (
            <>
              <Button
                onClick={() => suspendApplicationHandler()}
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<RemoveCircleIcon />}
              >
                სესხის გაუქმება
              </Button>
            </>
          ) : null}
        </CardContent>
      </Card>
    </>
  );
}

export default IndustrialDetailed;
