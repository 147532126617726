import { useEffect, useMemo } from "react";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";

interface WatchedValues {
  fixed_assets_capital_expenditures?: number;
  funds_turnover?: number;
  machine_equipment_equipment?: number;
  vehicles_special_technic?: number;
  purchase_building_land?: number;
  building_repairs?: number;
  is_female_entrepreneur_checked?: boolean;
  is_mountain_region_checked?: boolean;
  is_income_to_k_checked?: boolean;
  is_sun_gate_beneficiary_checked?: boolean;
  is_purpose_detailed_provided?: boolean;
  is_project_activities_checked?: boolean;
  is_purchased_activities_checked?: boolean;
}

export const useFormCalculations = (watchedValues: WatchedValues, setValue: UseFormSetValue<any>) => {
  // Compute usedAmount & fixedAssets
  const usedAmount = useMemo(
    () => [watchedValues.fixed_assets_capital_expenditures || 0, watchedValues.funds_turnover || 0].reduce((a, b) => a + b, 0),
    [watchedValues.fixed_assets_capital_expenditures, watchedValues.funds_turnover]
  );

  const fixedAssets = useMemo(
    () =>
      [
        watchedValues.machine_equipment_equipment || 0,
        watchedValues.vehicles_special_technic || 0,
        watchedValues.purchase_building_land || 0,
        watchedValues.building_repairs || 0,
      ].reduce((a, b) => a + b, 0),
    [watchedValues.machine_equipment_equipment, watchedValues.vehicles_special_technic, watchedValues.purchase_building_land, watchedValues.building_repairs]
  );

  useEffect(() => {
    if (watchedValues.is_female_entrepreneur_checked) {
      setValue("female_entrepreneur_comment", "");
    }
    if (watchedValues.is_mountain_region_checked) {
      setValue("mountain_region_comment", "");
    }
    // if (watchedValues.is_income_to_k_checked) {
    //   setValue("income_to_k_comment", "");
    // }
    if (watchedValues.is_sun_gate_beneficiary_checked) {
      setValue("sun_gate_beneficiary_comment", "");
    }
    if (watchedValues.is_purpose_detailed_provided) {
      setValue("purpose_detailed_comment", "");
    }
    if (watchedValues.is_project_activities_checked) {
      setValue("project_activities_comment", "");
    }
    if (watchedValues.is_purchased_activities_checked) {
      setValue("purchased_activities_comment", "");
    }
    setValue("fixed_assets_capital_expenditures", fixedAssets);
    setValue("used_amount", usedAmount);
  }, [
    usedAmount,
    fixedAssets,
    watchedValues.is_female_entrepreneur_checked,
    watchedValues.is_mountain_region_checked,
    watchedValues.is_income_to_k_checked,
    watchedValues.is_sun_gate_beneficiary_checked,
    watchedValues.is_purpose_detailed_provided,
    watchedValues.is_project_activities_checked,
    watchedValues.is_purchased_activities_checked,
    setValue,
  ]);

  // Visibility conditions for conditional fields
  const visibilityConditions = useMemo(
    () => ({
      female_entrepreneur_comment: watchedValues.is_female_entrepreneur_checked,
      mountain_region_comment: watchedValues.is_mountain_region_checked,
      income_to_k_comment: watchedValues.is_income_to_k_checked,
      sun_gate_beneficiary_comment: watchedValues.is_sun_gate_beneficiary_checked,
      purpose_detailed_comment: watchedValues.is_purpose_detailed_provided,
      project_activities_comment: watchedValues.is_project_activities_checked,
      purchased_activities_comment: watchedValues.is_purchased_activities_checked,
    }),
    [
      watchedValues.is_female_entrepreneur_checked,
      watchedValues.is_mountain_region_checked,
      watchedValues.is_income_to_k_checked,
      watchedValues.is_sun_gate_beneficiary_checked,
      watchedValues.is_purpose_detailed_provided,
      watchedValues.is_project_activities_checked,
      watchedValues.is_purchased_activities_checked,
    ]
  );

  return { usedAmount, fixedAssets, visibilityConditions };
};
