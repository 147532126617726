import React from 'react';
import { Switch, FormControlLabel } from '@mui/material';
interface SwitchProps {
    label: string;
    isChecked: boolean;
    callback?: (a: boolean) => void;
    size?: 'small' | 'medium';
}

const YesNoSwitch: React.FC<SwitchProps> = ({ isChecked, label, callback, size = 'medium' }) => {

    return (
        <FormControlLabel
            label={label}
            control={
                <Switch
                    checked={isChecked}
                    onChange={callback ? (e) => callback(e.target.checked) : undefined}
                    size={size}
                />
            }
            sx={{ fontSize: '12px' }}

        />
    );
};

export default YesNoSwitch;