/**
 * @React
 */
import {
    FC,
} from "react";
/**
 * @Router
 */
import { useParams } from 'react-router-dom';
/**
 * @Hooks
 */
import { useFetchData } from "../../../hooks";
/**
 * @lodash
 */
import _ from 'lodash';
/**
 * @Components
 */
import Loader from "../../../components/Loader";
import MonitoringCgsDetails from "../../../components/detailedApplication/MonitoringApplication/MonitoringCgsDetails";
import MonitoringCgsForm from "../../../components/FormComponent/MonitoringCgsFormComponent";
import CardsLayout from "../../../components/CustomComponents/CardsLayout";
/**
 * @Types
 */
import {
    IMonitoringAgreementCgsModel,
    IMonitoringCgsFields
} from "../../../types";
/**
 * @Utilities
 */
import { generateUrl } from "../../../utilities/generateRequestUrl";
import config from "../../../utilities/generateRequestUrl/config";
import { monitoringAgreementCgsDetailsFields } from "../../../utilities/Fields/CgsMonitoringFields/fields/detailFields";
import { monitoring_agreemet_cgs_initial_data } from "../../../utilities/Fields/CgsMonitoringFields/init";
/**
 * 
 * @Context
 */
import { useGeneralInfoContext } from "../../../context";

const MonitoringCgsStartForm: FC = () => {
    const { id } = useParams<{ id: string }>();
    let {
        options,
        load: OptionsLoad,
        error: OptionsError,
        generatedValues,
    } = useGeneralInfoContext();
    const { data, load, error } = useFetchData<IMonitoringCgsFields & Partial<IMonitoringAgreementCgsModel> | null>(generateUrl(config.client, 'getAgreementCgsById', { id: id }, null));

    if (load) {
        return <Loader />;
    }
    if (error || OptionsError) {
        return <h1>დაფიქსირდა შეცდომა</h1>
    }
    return (
        <>
            {
                load && OptionsLoad
                    ?
                    <Loader />
                    :
                    <>
                        {
                            data
                            &&
                            options
                            &&
                            <CardsLayout>

                                <>
                                    {
                                        generatedValues
                                        &&
                                        <MonitoringCgsDetails
                                            data={data || null}
                                            generatedValues={generatedValues}
                                            loading={load}
                                            title={''}
                                            monitoringCgsDetailsFields={monitoringAgreementCgsDetailsFields}
                                        />
                                    }

                                    <MonitoringCgsForm
                                        data={_.merge({}, monitoring_agreemet_cgs_initial_data, data)}
                                        load={load}
                                        url='monitoringCgsSubmit'
                                        opts={options}
                                    />
                                </>
                            </CardsLayout>
                        }
                    </>

            }

        </>
    )
}

export default MonitoringCgsStartForm;
