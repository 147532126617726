import { FC, ReactNode } from "react";
import LoadingSkeletons from "../../../SkeletonLoading";
// import DetailedStyles from "../../../../../utilities/DetailedStyles";
import DetailTableTD from "../DetailTableTD";
import DetailePageStyles from "../../../../../utilities/DetailePageStayles";

interface DetailTableTRProps {
    label: string;
    loading: boolean;
    children: ReactNode | string;
}

const DetailTableTR: FC<DetailTableTRProps> = ({ label, loading, children }) => {
    const classes = DetailePageStyles();

    return (
        <tr className={classes.row}>
            <DetailTableTD
                className={classes.title}>
                {label}
            </DetailTableTD>
            <DetailTableTD className={classes.detail}>
                <LoadingSkeletons loading={loading}>
                    {children}
                </LoadingSkeletons>
            </DetailTableTD>
        </tr>
    )
}

export default DetailTableTR;