/**
 * @React
 */
import React, {
  FC,
  ReactNode,
  useEffect,
  useState,
  useCallback
} from "react";

import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
/**
 * @MUI
 */
import { Box, Skeleton, Divider, Button, TextField, FormControl, IconButton, Alert } from "@mui/material";
import DownloadDoneOutlinedIcon from '@mui/icons-material/DownloadDoneOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
/**
 * @Components
 */
import Loader from "../../Loader";
import CustomInput from "../../CustomComponents/InputField";
import CardFieldSet from "../../CustomComponents/CardFieldSet";
/**
 * @ReactHookForm
 */
import {
  FormProvider,
  SubmitErrorHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
/**
 * @Types
 */
import {
  IMonitoringFields,
  IInputField,
  IMonitoringAgreementModel,
  IFieldGroup,
  IGroup,
  ProgramComponent,
  Program,
  UploadedAttachments,
  // Attachment,
  TourismType,
  IDifferenceFromAgreement
} from "../../../types";
/**
 * @Validation
 */
import { monitoringInitialDataSchema } from "../../../utilities/Fields/monitoringFields/fields/forms/validation";
/**
 * @Utilities
 */
import {
  getNestedError,
  getFirstErrorMessage,
} from "../../../utilities/Fields/monitoringFields/utils";
import { Warning } from "@material-ui/icons";
import { PostData } from "../../../utilities/PostData";
import { Add, Send } from "@mui/icons-material";
import { MonitoringLogsDetails } from "../../detailedApplication/MonitoringApplication";

interface MonitoringFormProps {
  inpuFields: IFieldGroup<IMonitoringFields & Partial<IMonitoringAgreementModel>>[];
  agreement: IMonitoringFields & Partial<IMonitoringAgreementModel>;
  setAgreement: React.Dispatch<React.SetStateAction<(IMonitoringFields & Partial<IMonitoringAgreementModel>) | null>>;
  agreementLoad: boolean;
  setReset: React.Dispatch<React.SetStateAction<boolean>>;
  dependencies?: any;
  submitFn: (data: IMonitoringFields & Partial<IMonitoringAgreementModel>) => Promise<void>;
  validateFN?: any;
}
type Attachment = {
  title: string;
  field: string;
  canAdd?: boolean;
  files?: File[];
};
const MonitoringForm: FC<MonitoringFormProps> = ({
  inpuFields,
  setReset,
  setAgreement,
  agreement,
  agreementLoad,
  dependencies,
  submitFn,
  validateFN
}) => {
  const history = useHistory();
  const [agreementData, setAgreementData] = useState<IMonitoringFields & Partial<IMonitoringAgreementModel>>(() => agreement);
  const [note, setNote] = useState<{
    funds_turnover: boolean;
    "tourist_services_information.adventure_tourism_measures.group_service": boolean;
    "tourist_services_information.adventure_tourism_measures.guest_life_insurance": boolean;
    "tourist_services_information.adventure_tourism_measures.individual_service": boolean;
    "tourist_services_information.historic_simulation": boolean;
  }>({
    funds_turnover: false,
    "tourist_services_information.adventure_tourism_measures.guest_life_insurance": false,
    "tourist_services_information.adventure_tourism_measures.group_service": false,
    "tourist_services_information.adventure_tourism_measures.individual_service": false,
    "tourist_services_information.historic_simulation": false
  });
  const [disabled, setDisabled] = useState<{ [key: string]: { disabled: boolean } }>({});
  const [uploadedAttachments, setUploadedAttachments] = useState<UploadedAttachments>({ monitoring_attachments: [] });
  const [attachmentFields, setAttachmentFields] = useState<Array<Attachment>>([
    { title: "დოკუმენტები", field: "monitoring_attachments", canAdd: true },
  ]);
  const [isUploading, setIsUploading] = useState(false);
  const methods = useForm<IMonitoringFields & Partial<IMonitoringAgreementModel>>({
    defaultValues: agreementData as IMonitoringFields & Partial<IMonitoringAgreementModel>,
    resolver: yupResolver(monitoringInitialDataSchema) as any,
  });

  function fileChangeHandler(event: any, index: number) {
    let newAttachmentFields = [...attachmentFields];
    let newAttachmentField = { ...newAttachmentFields[index] };
    newAttachmentField.files = [];
    newAttachmentField.files = event.target.files;
    newAttachmentFields[index] = newAttachmentField;
    setAttachmentFields(newAttachmentFields);
  }

  function fileSubmitHandler(fieldName: string, index: number) {
    const files = attachmentFields[index].files;

    if (!files || Array.from(files).length === 0) {
      alert("No files uploaded.");
      return;
    }
    const oversizedFiles = Array.from(files).find(file => file.size / 1024 / 1024 > 20);

    if (oversizedFiles) {
      alert("ფაილის ზომა აღემატება 20MB-ს.");
      return;
    }
    setIsUploading(true);
    uploadFile(files, fieldName, index);
  }

  function uploadFile(files: File[], fieldName: string, index: number) {
    const fileData = new FormData();
    files.forEach((file: File) => {
      fileData.append(fieldName, file, file.name);
    });

    PostData("bank/application/upload_attachments", fileData, true)
      .then((res) => {
        const newAttachments: any = { ...uploadedAttachments };
        if (newAttachments[fieldName]) {
          newAttachments[fieldName] = [...newAttachments[fieldName], ...res[fieldName]];
        } else {
          newAttachments[fieldName] = res[fieldName];
        }
        setUploadedAttachments(newAttachments);
        setIsUploading(false);
      })
      .catch((err) => {
        alert("ფაილის ატვირთვისას დაფიქსირდა შეცდომა");
        setIsUploading(false);
      });
  }

  const {
    setValue,
    getFieldState,
    getValues,
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    unregister
  } = methods as any;

  useEffect(() => {
    setAgreementData(agreement);
    reset(agreement);
  }, [agreement]);
  useEffect(() => {
    setAgreementData(agreement);
  }, []);
  type FormFields = {
    used_amount?: undefined | number;
  } & IMonitoringFields & Partial<IMonitoringAgreementModel>;
  const {
    fields: exportCountryFields,
    append: appendExportCountry,
    remove: removeExportCountry
  } = useFieldArray<FormFields, any, "id">({
    control,
    name: 'country_export',
    keyName: "id"
  });


  let {
    fields: projectActivityFields,
    append: appendProjectActivity,
    remove: removeProjectActivity
  } = useFieldArray<FormFields, any, "id">({
    control,
    name: 'project_activities',
    keyName: "id"
  });
  const addRemoveItemOfArray = {
    append: {
      country_export: () => appendExportCountry(""),
      project_activities: () => appendProjectActivity("")
    },
    remove: {
      country_export: (index: number) => {
        removeExportCountry(index)
      },
      project_activities: removeProjectActivity

    }
  }
  const watchedValues = watch();
  useEffect(() => {
    const used_amount = Number(watchedValues.used_amount) || 0;
    const fixed_assets_capital_expenditures = Number(watchedValues.fixed_assets_capital_expenditures) || 0;
    const funds_turnover = Number(watchedValues.funds_turnover) || 0;
    const program = watchedValues.program;
    const programComponent = watchedValues.program_component;
    const group_service = watchedValues?.tourist_services_information?.adventure_tourism_measures.group_service;
    const guest_life_insurance = watchedValues?.tourist_services_information?.adventure_tourism_measures?.guest_life_insurance;
    const individual_service = watchedValues?.tourist_services_information?.adventure_tourism_measures?.individual_service;
    const tourist_services_information_type = watchedValues?.tourist_services_information?.type;
    const historic_simulation = watchedValues?.tourist_services_information?.historic_simulation;
    if (program === Program.INDUSTRIAL || program === Program.UNIVERSAL) {
      setNote((prev) => ({ ...prev, funds_turnover: funds_turnover > (0.5 * used_amount) }));
    }
    if (program === Program.CREDIT_GUARANTEE_PROGRAM) {
      setNote((prev) => ({ ...prev, funds_turnover: funds_turnover > (0.2 * used_amount) }));
      setNote((prev) => ({ ...prev, fixed_assets_capital_expenditures: fixed_assets_capital_expenditures == 0 }));
    }
    if (programComponent === ProgramComponent.TOURISM_SERVICES_INDUSTRY) {
      if (tourist_services_information_type === TourismType.AdventureTourismMeasures) {
        setNote((prev) => ({
          ...prev,
          "tourist_services_information.adventure_tourism_measures.group_service": !group_service,
          "tourist_services_information.adventure_tourism_measures.guest_life_insurance": !guest_life_insurance,
          "tourist_services_information.adventure_tourism_measures.individual_service": !individual_service
        }))
      }
      if (tourist_services_information_type === TourismType.HistoricSimulation) {
        setNote((prev) => ({
          ...prev,
          "tourist_services_information.historic_simulation": !historic_simulation
        }))
      }
    }
    setDisabled({
      program: {
        disabled: true
      }, program_component: {
        disabled: true
      }, contract_number: {
        disabled: true
      }, contract_signed_date: {
        disabled: true
      }, project_status: {
        disabled: watchedValues.program !== Program.CREDIT_GUARANTEE_PROGRAM
      }, component: {
        disabled: true
      }, bank: {
        disabled: true
      }, approved_amount: {
        disabled: true
      }, currency: {
        disabled: true
      }, used_amount: {
        disabled: true
      }, fixed_assets_capital_expenditures: {
        disabled: true
      }
    })
  }, [
    watchedValues.used_amount,
    watchedValues.program,
    watchedValues.fixed_assets_capital_expenditures,
    watchedValues.funds_turnover,
    watchedValues.program_component,
    watchedValues.contract_number,
    watchedValues.contract_number,
    watchedValues.contract_signed_date,
    watchedValues.project_status,
    watchedValues.component,
    watchedValues?.tourist_services_information?.adventure_tourism_measures?.guest_life_insurance,
    watchedValues?.tourist_services_information?.adventure_tourism_measures?.group_service,
    watchedValues?.tourist_services_information?.adventure_tourism_measures?.individual_service,
    watchedValues?.tourist_services_information?.historic_simulation,
    watchedValues?.tourist_services_information?.type
  ]);

  const onError: SubmitErrorHandler<IMonitoringFields & Partial<IMonitoringAgreementModel>> = (errors) => {
    const message = getFirstErrorMessage(errors)
    alert(message);
  };

  const onSubmit = (data: IMonitoringFields & Partial<IMonitoringAgreementModel>) => {
    let attached_documents = data?.attached_documents?.monitoring_attachments ?? [];
    if (attached_documents.length > 0 && data.attached_documents) {
      data.attached_documents = {
        monitoring_attachments: [...attached_documents, ...uploadedAttachments.monitoring_attachments]
      };
    } else {
      data.attached_documents = {
        monitoring_attachments: uploadedAttachments.monitoring_attachments
      };
    }
    submitFn(data);
  };
  return (
    <>
      {
        agreementLoad ? (
          <Loader />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              width: `100%`,
            }}
          >
            <FormProvider {...(methods as any)}>
              {
                <form onSubmit={
                  handleSubmit(onSubmit, onError)} noValidate>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      width: `100%`,
                    }}
                  >
                    {
                      inpuFields.map((group, groupIndex) => {
                        let isGroupVisible = true;
                        if (group.groupName in dependencies) {
                          if (!dependencies[group.groupName]) {
                            isGroupVisible = false;
                          }
                        }
                        const fieldOptions: {
                          [key: string]: any;
                        } = dependencies.options;

                        return (
                          <>
                            {
                              isGroupVisible ? (
                                <CardFieldSet
                                  key={`${group.groupName}-${groupIndex}`}
                                  title={group.groupLabel}>
                                  <>
                                    {
                                      group.fields.map((field: IInputField<IMonitoringFields & Partial<IMonitoringAgreementModel>>) => {
                                        let isVisible = true;
                                        if (field.name in dependencies) {
                                          if (!dependencies[field.name]) {
                                            isVisible = false;
                                          }
                                        }
                                        if (field.options && field.name in fieldOptions) {
                                          field.options = fieldOptions[field.name];
                                        }
                                        if (field.sub_fields) {
                                          return field.sub_fields.map(
                                            (subField: IInputField<IMonitoringFields & Partial<IMonitoringAgreementModel>>, index: number) => {
                                              if (subField.name in dependencies) {
                                                if (!dependencies[subField.name]) {
                                                  isVisible = false;
                                                }
                                              }
                                              if (subField.options && subField.name in fieldOptions) {
                                                subField.options = fieldOptions[subField.name];
                                              }
                                              return (
                                                <>
                                                  {
                                                    isVisible ? (
                                                      <Box sx={{ display: "flex" }}>
                                                        <CustomInput
                                                          control={control as any}
                                                          disabled={disabled[subField.name as keyof typeof disabled]?.disabled}
                                                          newField={subField as IInputField<IMonitoringFields & Partial<IMonitoringAgreementModel>>}
                                                          watchedValues={watchedValues}
                                                          customChange={(data) => {
                                                            if (data) {
                                                              setAgreement(data);
                                                            }
                                                          }}
                                                          errors={errors[field.name as keyof typeof errors] && errors[field.name as keyof typeof errors][index]}
                                                        />
                                                        {
                                                          index === 0 && (
                                                            <Button
                                                              onClick={() => {
                                                                let field_item = subField as unknown as IMonitoringFields & Partial<IMonitoringAgreementModel>[];
                                                                type AgreementType = IMonitoringFields &
                                                                  Partial<IMonitoringAgreementModel> & {
                                                                    [key: string]: any;
                                                                  };
                                                                addRemoveItemOfArray.append[field.name as keyof typeof addRemoveItemOfArray.append]();
                                                                let result = getValues();
                                                                setAgreement(result);
                                                                // setAgreement((prev) => {
                                                                //   const newPrev = {
                                                                //     ...(prev as AgreementType),
                                                                //   };
                                                                //   const newArray = [...newPrev[field.name], ""];
                                                                //   return {
                                                                //     ...newPrev,
                                                                //     [field.name]: newArray,
                                                                //   };
                                                                // });
                                                              }}
                                                            >
                                                              +
                                                            </Button>
                                                          )}
                                                        {
                                                          index > 0 && (
                                                            <Button
                                                              onClick={() => {
                                                                type AgreementType = IMonitoringFields &
                                                                  Partial<IMonitoringAgreementModel> & {
                                                                    [key: string]: any;
                                                                  };
                                                                // addRemoveItemOfArray.remove[field.name as keyof typeof addRemoveItemOfArray.remove](index);
                                                                // let result = getValues();
                                                                // setAgreement(result);
                                                                setAgreement((prev) => {
                                                                  const newPrev = {
                                                                    ...(prev as AgreementType),
                                                                  };
                                                                  const newArray = newPrev[field.name].filter((_: any, i: number) => i !== index);
                                                                  return {
                                                                    ...newPrev,
                                                                    [field.name]: newArray,
                                                                  };
                                                                });
                                                              }}
                                                            >
                                                              -
                                                            </Button>
                                                          )}
                                                      </Box >
                                                    )
                                                      :
                                                      null
                                                  }
                                                </>
                                              );
                                            }
                                          );
                                        } else if (field.subGroupFields) {
                                          return (
                                            <>
                                              {
                                                isVisible ? (
                                                  <>
                                                    <Box
                                                      sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        border: "1px solid #ccc",
                                                        padding: "10px",
                                                        borderRadius: "5px",
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      {
                                                        field.label && (
                                                          <label
                                                            style={{
                                                              fontWeight: "bold",
                                                              paddingBottom: "25px",
                                                            }}
                                                          >
                                                            <b>{field.label}</b>
                                                          </label>
                                                        )
                                                      }
                                                      {
                                                        field.subGroupFields.map(
                                                          (subFieldGroupField: IInputField<IMonitoringFields & Partial<IMonitoringAgreementModel>>) => {
                                                            if (subFieldGroupField.name in dependencies) {
                                                              if (!dependencies[subFieldGroupField.name]) {
                                                                isVisible = false;
                                                              }
                                                            }
                                                            if (subFieldGroupField.options && subFieldGroupField.name in fieldOptions) {
                                                              subFieldGroupField.options = fieldOptions[subFieldGroupField.name];
                                                            }
                                                            return (
                                                              <>
                                                                {
                                                                  isVisible ? (
                                                                    <>
                                                                      <CustomInput
                                                                        key={subFieldGroupField.name}
                                                                        disabled={disabled[subFieldGroupField.name as keyof typeof disabled]?.disabled}
                                                                        control={control as any}
                                                                        newField={subFieldGroupField as IInputField<IMonitoringFields & Partial<IMonitoringAgreementModel>>}
                                                                        watchedValues={watchedValues}
                                                                        errors={errors[subFieldGroupField.name as keyof typeof errors]}
                                                                        customChange={(data) => {
                                                                          if (data) {
                                                                            setAgreement(data);
                                                                          }
                                                                        }}
                                                                      />
                                                                      {
                                                                        note[subFieldGroupField.name as keyof typeof note] ? (
                                                                          <>
                                                                            <Alert
                                                                              severity="warning"
                                                                              color="warning"
                                                                            >
                                                                              <i
                                                                                style={{
                                                                                  color: "#ed6c02",
                                                                                  fontSize: "1em"
                                                                                }}
                                                                              >
                                                                                აღნიშნული მონაცემების გადამოწმება უნდა მოხდეს ხელშეკრულების / სააგენტოს დადასტურებული განაცხადის თარიღის და ამ თარიღით მოქმედი დადგენილების პირობებთან.
                                                                              </i>
                                                                            </Alert>
                                                                          </>
                                                                        ) : null
                                                                      }
                                                                    </>
                                                                  ) : null}
                                                              </>
                                                            );
                                                          }
                                                        )
                                                      }
                                                    </Box>
                                                  </>
                                                ) : null}
                                            </>
                                          );
                                        } else {
                                          return (
                                            <>
                                              {
                                                isVisible ? (
                                                  <>
                                                    {
                                                      field.note && !field.warning ? (
                                                        <>
                                                          <Box
                                                            sx={{
                                                              display: "flex",
                                                              flexDirection: "column",
                                                              gap: "5px",
                                                              border: "1px solid #ccc",
                                                              padding: "10px",
                                                              borderRadius: "5px",
                                                              fontSize: "12px",
                                                            }}
                                                          >
                                                            <label>
                                                              <b>{field.note.label}</b>
                                                            </label>
                                                            <CustomInput
                                                              control={control as any}
                                                              newField={field as IInputField<IMonitoringFields & Partial<IMonitoringAgreementModel>>}
                                                              disabled={disabled[field.name as keyof typeof disabled]?.disabled}
                                                              watchedValues={watchedValues}
                                                              customChange={(data) => {
                                                                if (data) {
                                                                  setAgreement(data);
                                                                }
                                                              }}
                                                              errors={errors[field.name as keyof typeof errors]}
                                                            />
                                                            <i style={{ paddingTop: "20px" }}>{field.note.note}</i>
                                                          </Box>
                                                          {
                                                            note[field.name as keyof typeof note] ? (
                                                              <>
                                                                <Alert
                                                                  severity="warning"
                                                                  color="warning"
                                                                >
                                                                  <i
                                                                    style={{
                                                                      color: "#ed6c02",
                                                                      fontSize: "1em"
                                                                    }}
                                                                  >
                                                                    აღნიშნული მონაცემების გადამოწმება უნდა მოხდეს ხელშეკრულების / სააგენტოს დადასტურებული განაცხადის თარიღის და ამ თარიღით მოქმედი დადგენილების პირობებთან.
                                                                  </i>
                                                                </Alert>
                                                              </>
                                                            ) : null
                                                          }
                                                        </>
                                                      ) : field.type === "checkbox" ? (
                                                        <Box sx={{ paddingLeft: "10px" }}>
                                                          <CustomInput
                                                            control={control as any}
                                                            newField={field as IInputField<IMonitoringFields & Partial<IMonitoringAgreementModel>>}
                                                            watchedValues={watchedValues}
                                                            customChange={(data) => {
                                                              if (data) {
                                                                setAgreement(data);
                                                              }
                                                            }}
                                                            errors={errors[field.name as keyof typeof errors]}
                                                          />
                                                        </Box>
                                                      ) : field.subItem ? (
                                                        <li
                                                          style={{
                                                            listStyle: "none",
                                                            paddingLeft: "20px"
                                                          }}>
                                                          <CustomInput
                                                            control={control as any}
                                                            newField={field as IInputField<IMonitoringFields & Partial<IMonitoringAgreementModel>>}
                                                            disabled={disabled[field.name as keyof typeof disabled]?.disabled}
                                                            watchedValues={watchedValues}
                                                            customChange={(data) => {
                                                              if (data) {
                                                                setAgreement(data);
                                                              }
                                                            }}
                                                            errors={getNestedError(errors, field.name)}
                                                          />
                                                          {
                                                            note[field.name as keyof typeof note] ? (
                                                              <>
                                                                <Alert
                                                                  severity="warning"
                                                                  color="warning"
                                                                >
                                                                  <i
                                                                    style={{
                                                                      color: "#ed6c02",
                                                                      fontSize: "1em"
                                                                    }}
                                                                  >
                                                                    აღნიშნული მონაცემების გადამოწმება უნდა მოხდეს ხელშეკრულების / სააგენტოს დადასტურებული განაცხადის თარიღის და ამ თარიღით მოქმედი დადგენილების პირობებთან.
                                                                  </i>
                                                                </Alert>
                                                              </>
                                                            ) : null
                                                          }
                                                        </li>
                                                      ) : (
                                                        <>
                                                          <CustomInput
                                                            control={control as any}
                                                            newField={field as IInputField<IMonitoringFields & Partial<IMonitoringAgreementModel>>}
                                                            disabled={disabled[field.name as keyof typeof disabled]?.disabled}
                                                            watchedValues={watchedValues}
                                                            customChange={(data) => {
                                                              if (data) {
                                                                setAgreement(data);
                                                              }
                                                            }}
                                                            errors={getNestedError(errors, field.name)}
                                                          />
                                                          {
                                                            note[field.name as keyof typeof note] ? (
                                                              <>
                                                                <Alert
                                                                  severity="warning"
                                                                  color="warning"
                                                                >
                                                                  <i
                                                                    style={{
                                                                      color: "#ed6c02",
                                                                      fontSize: "1em"
                                                                    }}
                                                                  >
                                                                    აღნიშნული მონაცემების გადამოწმება უნდა მოხდეს ხელშეკრულების / სააგენტოს დადასტურებული განაცხადის თარიღის და ამ თარიღით მოქმედი დადგენილების პირობებთან.
                                                                  </i>
                                                                </Alert>
                                                              </>
                                                            ) : null
                                                          }
                                                        </>

                                                      )}
                                                  </>
                                                ) : null}
                                            </>
                                          );
                                        }
                                      })}
                                  </>
                                </CardFieldSet >
                              ) : null}
                          </>
                        );
                      })}
                    <CardFieldSet
                      key={`10000001`}
                      title={"ასატვირთი დოკუმენტები"}
                    >
                      <>
                        <Box sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                          width: `100%`,
                        }}>
                          {attachmentFields.map((attachment, index) => (
                            <Box key={index} style={{ marginBottom: "10px" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                  alignItems: "center",
                                  padding: "15px 0",
                                }}
                              >
                                <input
                                  type="file"
                                  multiple
                                  onChange={(event) => fileChangeHandler(event, index)}
                                />
                                <Box>
                                  {uploadedAttachments[attachment.field as keyof typeof uploadedAttachments]?.map((file, fileIndex) => (
                                    <div key={fileIndex}>წარმატებით აიტვირთა</div>
                                  ))}
                                </Box>
                                <Button
                                  style={{ marginLeft: "14.625rem" }}
                                  color="primary"
                                  size="small"
                                  variant="outlined"
                                  onClick={() => {
                                    fileSubmitHandler(attachment.field, index);
                                  }}
                                >
                                  ატვირთვა
                                </Button>
                                {attachment.canAdd && index === 0 && (
                                  <IconButton
                                    onClick={() => {
                                      setAttachmentFields([...attachmentFields, { ...attachment, files: [] }]);
                                    }}
                                  >
                                    <Add />
                                  </IconButton>
                                )}
                                {attachment.canAdd && index > 0 && (
                                  <IconButton
                                    onClick={() => {
                                      setAttachmentFields((prev) => ([...prev.filter((_, i) => i !== index)]));
                                    }}
                                  >
                                    <RemoveOutlinedIcon />
                                  </IconButton>
                                )}
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </>

                    </CardFieldSet>
                    {
                      agreementData
                      &&
                      agreementData?.difference_from_agreement
                      &&
                      agreementData?.difference_from_agreement.length > 0
                      &&
                      <CardFieldSet
                        key={`10000002`}
                        title={"განაცხადის ცვლილების ისტორია"}
                      >
                        <>
                          <MonitoringLogsDetails
                            changedDataHistory={agreementData.difference_from_agreement as IDifferenceFromAgreement[]}
                          />
                        </>
                      </CardFieldSet>}
                    <Button type="submit" variant="contained" >Submit</Button>
                  </Box>
                </form>
              }
            </FormProvider>
          </Box >
        )}
    </>

  );
};

export default MonitoringForm;