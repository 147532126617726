/**
 * @React
 */
import React, {
  FC,
  ReactNode,
  useEffect,
  useState,
  useCallback,
  useRef
} from "react";

import { useHistory } from "react-router-dom";
/**
 * @MUI
 */
import { Box, Button, IconButton, Grid } from "@mui/material";
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
/**
 * @Components
 */
import Loader from "../../Loader";
import CardFieldSet from "../../CustomComponents/CardFieldSet";
import { MonitoringLogsDetailsCgs } from "../../detailedApplication/MonitoringApplication";
import { IMonitoringCgsFields } from "../../../types/monitoringCgsFields";
import CustomField from "../../CustomComponents/CustomFields/input";
import AutoComplete from "../../CustomComponents/CustomFields/AutoComplete";
import FilesContainer from "../../CustomComponents/FilesContainer";
/**
 * @ReactHookForm
 */
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
/**
 * @Types
 */
import {
  IMonitoringFields,
  IInputField,
  IMonitoringAgreementModel,
  UploadedAttachments,
  IDifferenceFromAgreement,
  IMonitoringAgreementCgsModel
} from "../../../types";
/**
 * @Validation
 */
import { monitoringInitialDataSchema } from "../../../utilities/Fields/monitoringFields/fields/forms/validation";
/**
 * @Hooks
 */
import { useFormCalculations } from "../../../hooks/useMonitoringCgsHook";
/**
 * @Utilities
 */
import {
  getFirstErrorMessage,
} from "../../../utilities/Fields/monitoringFields/utils";
import { monitoringCgsGroupFieldsInitValues } from "../../../utilities/Fields/CgsMonitoringFields/fields/forms";
import { PostData } from "../../../utilities/PostData";
import { generateUrl } from "../../../utilities/generateRequestUrl";
import axiosInstance from "../../../utilities/axios";
import config from "../../../utilities/generateRequestUrl/config";
import { monitoringCgsInitialDataSchema } from "../../../utilities/Fields/CgsMonitoringFields/fields/forms/validation";
import { fieldsCgs, valueFormatCgs } from "../../../utilities/Fields/CgsMonitoringFields/fields/changesFields";
import DetailTableTR from "../../CustomComponents/TablesCompoent/Detail/DetailTableTR";
import DetailePageStyles from "../../../utilities/DetailePageStayles";

interface MonitoringCgsFormProps {
  data: IMonitoringCgsFields & Partial<IMonitoringAgreementCgsModel>;
  load: boolean;
  url: string;
  opts?: any;
}
const MonitoringCgsForm: FC<MonitoringCgsFormProps> = ({
  data,
  load,
  url,
  opts,
}) => {
  const history = useHistory();
  const classes = DetailePageStyles();

  const [submitReload, setSubmitReload] = useState<boolean>(false);
  const [uploadedAttachments, setUploadedAttachments] = useState<UploadedAttachments>({ monitoring_attachments: [] });
  const [isUploading, setIsUploading] = useState(false);
  const [projectActivity, setProjectActivity] = useState<{ label: string; value: string } | null>(null);

  const methods = useForm<IMonitoringCgsFields & Partial<IMonitoringAgreementCgsModel>>({
    defaultValues: data as IMonitoringCgsFields & Partial<IMonitoringAgreementCgsModel> || {},
    resolver: yupResolver(monitoringCgsInitialDataSchema) as any,
    mode: "onBlur",
  });
  const {
    setValue,
    control,
    handleSubmit,
    watch,
    formState,
  } = methods as any;

  const { errors } = formState;

  const {
    fields: FilesFields,
    append: AppendFile,
    remove: RemoveFile
  } = useFieldArray({
    control,
    name: "attached_documents.monitoring_attachments"
  });
  let {
    fields: projectActivityFields,
    append: appendProjectActivity,
    remove: removeProjectActivity
  } = useFieldArray({
    control,
    name: 'project_activities'
  });

  function fileChangeHandler(files: File[] | null) {
    fileSubmitHandler("monitoring_attachments", files);
  }

  function fileSubmitHandler(fieldName: string, files: File[] | null) {

    if (!files || Array.from(files).length === 0) {
      alert("No files uploaded.");
      return;
    }
    const oversizedFiles = Array.from(files).find(file => file.size / 1024 / 1024 > 20);

    if (oversizedFiles) {
      alert("ფაილის ზომა აღემატება 20MB-ს.");
      return;
    }
    setIsUploading(true);
    uploadFile(files, fieldName);
  }
  function uploadFile(files: File[], fieldName: string) {
    const fileData = new FormData();
    files.forEach((file: File) => {
      fileData.append(fieldName, file, file.name);
    });

    PostData("bank/application/upload_attachments", fileData, true)
      .then((res) => {
        setUploadedAttachments((prevState) => ({ ...prevState, "monitoring_attachments": [...res.monitoring_attachments] }));
      })
      .catch((err) => {
        alert("ფაილის ატვირთვისას დაფიქსირდა შეცდომა");
      })
      .finally(() => (setIsUploading(false)));
  }

  const watchedValues = watch();
  const { visibilityConditions } =
    useFormCalculations(watchedValues, setValue);

  const shouldHideField = (fieldName: keyof typeof visibilityConditions) => {
    return visibilityConditions[fieldName] === true; // Return true if the field should be hidden
  };

  const submitFn = async (data: IMonitoringCgsFields & Partial<IMonitoringAgreementCgsModel>) => {

    try {
      setSubmitReload(true);
      const resp = await axiosInstance.post(generateUrl(config.client, url, null, null), {
        application: data,
      });
      if (resp && resp.data && resp.statusText === "OK") {
        history.push("/monitoring");
      }
    } catch (e) {
      if (e) {
        alert("დაფიქსირდა შეცდომა");
      }
      console.error(e);
    } finally {
      setSubmitReload(false)
    }
  };

  const onError: SubmitErrorHandler<IMonitoringFields & Partial<IMonitoringAgreementModel>> = (errors) => {
    const message = getFirstErrorMessage(errors)
    alert(message);
  };

  const onSubmit = (data: IMonitoringCgsFields & Partial<IMonitoringAgreementCgsModel>) => {
    submitFn(data);
  };
  return (
    <>
      {
        load ? (
          <Loader />
        ) : <>
          {
            opts
            &&
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                width: `100%`,
              }}
            >
              <FormProvider {...(methods as any)}>
                {
                  <form
                    onSubmit={
                      handleSubmit(onSubmit, onError)
                    }
                    noValidate
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        width: `100%`,
                      }}
                    >
                      {
                        monitoringCgsGroupFieldsInitValues.map((group, groupIndex) => {
                          return (
                            <CardFieldSet
                              key={groupIndex}
                              title={group.groupLabel}>
                              <>
                                {
                                  group.fields.map((item: IInputField<IMonitoringCgsFields & Partial<IMonitoringAgreementCgsModel>>) => {
                                    if (shouldHideField(item.name as keyof typeof visibilityConditions)) {
                                      return null;
                                    }
                                    if (item.name === 'project_activities') {
                                      const project_activities = opts[item.optionName ?? 'nace_activities'] as { value: string; label: string }[];
                                      const pr = projectActivityFields.map((projectActivity, index) => {
                                        return (
                                          <>
                                            <Controller
                                              key={projectActivity.id}
                                              name={`project_activities.${index}`}
                                              control={control}
                                              render={({ field }) => {
                                                const projectActivityData = Object.values(project_activities).find(
                                                  (activity: { label: string; value: string; }) => activity?.value === field.value // Find the activity by id
                                                );
                                                return (
                                                  <Box
                                                    sx={{
                                                      padding: '0 15px 0 50px',
                                                    }}
                                                  >
                                                    <table className={classes.table}>
                                                      <tbody>
                                                        <DetailTableTR
                                                          label={projectActivityData ? projectActivityData?.label : `აქტივობა არ მოიძებნა (${field.value})`}
                                                          loading={false}
                                                        >
                                                          <IconButton
                                                            sx={{
                                                              color: 'rgb(211, 47, 47)'
                                                            }}
                                                            onClick={() => {
                                                              removeProjectActivity(index);
                                                              setProjectActivity(projectActivityData || null);
                                                            }}
                                                          >
                                                            <RemoveOutlinedIcon
                                                              sx={{
                                                                fontSize: '12px',
                                                                color: 'rgb(211, 47, 47)'
                                                              }}
                                                            />
                                                          </IconButton>
                                                        </DetailTableTR>
                                                      </tbody>
                                                    </table >

                                                  </Box>

                                                )
                                              }}

                                            />
                                          </>)
                                      });
                                      return (
                                        <>
                                          <Grid container
                                            sx={{
                                              width: '100%',
                                              justifyContent: "center",
                                              alignItems: "center"
                                            }}
                                          >
                                            <Grid item xs={11}>
                                              {
                                                project_activities
                                                && <AutoComplete
                                                  name={'activity'}
                                                  value={projectActivity}
                                                  label={item.label}
                                                  options={Object.values(project_activities) as { value: string; label: string }[]}
                                                  callback={(data) => {
                                                    if (data) setProjectActivity(data);
                                                  }}
                                                />}
                                            </Grid>
                                            <Grid item xs={1} display="flex" justifyContent="center">
                                              <IconButton
                                                disabled={projectActivity ? false : true}
                                                onClick={() => {
                                                  appendProjectActivity(projectActivity?.value);
                                                  setProjectActivity(null);
                                                }}
                                                sx={{
                                                  cursor: 'pointer',
                                                }}
                                              >
                                                <AddOutlinedIcon
                                                  sx={{
                                                    fontSize: '12px',
                                                    color: !!projectActivity ? 'rgb(44, 91, 148, 0.8)' : 'rgb(0,0,0,0.23)'
                                                  }}
                                                />

                                              </IconButton>
                                            </Grid>
                                          </Grid>
                                          {pr}
                                        </>
                                      );
                                    } else {
                                      return (
                                        <>
                                          <CustomField
                                            name={item.name}
                                            label={item.label}
                                            type={item.type as 'text' | 'select' | 'textarea' | 'checkbox' | 'autocomplete' | 'number' | 'date' || 'text'}
                                            control={control}
                                            {...(item.optionName && opts ? { options: Object.values(opts[item.optionName]) } : item.options ? { options: item.options } : {})}
                                            disabled={item.disabled}
                                            errors={errors[item.name]}
                                          />
                                        </>

                                      );
                                    }

                                  })}
                              </>
                            </CardFieldSet >
                          )
                        })}
                      {
                        <CardFieldSet
                          key={`10000001`}
                          title={"ასატვირთი დოკუმენტები"}
                        >
                          <>
                            <Box sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "20px",
                              width: `100%`,
                            }}>
                              <Box key={'index'} style={{ marginBottom: "10px" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                    padding: "15px 0",
                                  }}
                                >
                                  <input
                                    type="file"
                                    multiple
                                    onChange={(event) => fileChangeHandler(event.target.files ? Array.from(event.target.files) : null)}
                                  />
                                  <Button
                                    disabled={uploadedAttachments.monitoring_attachments.length === 0}
                                    style={{ marginLeft: "14.625rem" }}
                                    color="primary"
                                    size="small"
                                    variant="outlined"
                                    onClick={() => {
                                      uploadedAttachments.monitoring_attachments.map((fileName: string) => (AppendFile(fileName)))
                                      setUploadedAttachments({ monitoring_attachments: [] });
                                    }}
                                  >
                                    ატვირთვა
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </>
                          <Controller
                            name="attached_documents.monitoring_attachments"
                            control={control}
                            render={() => (
                              <FilesContainer
                                FilesList={methods.getValues('attached_documents.monitoring_attachments')}
                                mode="form"
                                removeCallback={(i) => {
                                  RemoveFile(i)
                                }}
                                cols={6}
                              />
                            )}
                          />

                        </CardFieldSet>}
                      {
                        data
                        &&
                        data?.difference_from_agreement
                        &&
                        data?.difference_from_agreement.length > 0
                        &&
                        <CardFieldSet
                          key={`10000002`}
                          title={"განაცხადის ცვლილების ისტორია"}
                        >
                          <>
                            <MonitoringLogsDetailsCgs
                              changedDataHistory={data?.difference_from_agreement ?? [] as IDifferenceFromAgreement[]}
                              valueFormat={valueFormatCgs}
                              fields={fieldsCgs}
                            />
                          </>
                        </CardFieldSet>}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: `100%`,
                        }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{
                            width: "90%",
                          }}
                        >
                          გაგზავნა
                        </Button>
                      </Box>

                    </Box>
                  </form>
                }
              </FormProvider>
            </Box >}
        </>}
    </>

  );
};

export default MonitoringCgsForm;
