import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import MonitoringCgsProvider from '../MonitoringCgsProvider';
import MonitoringLayout from '../../components/Layouts/MonitoringLayout';

interface MonitoringRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const MonitoringCgsRoute: React.FC<MonitoringRouteProps> = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      <MonitoringLayout>
        <MonitoringCgsProvider>
          <Component {...props} />
        </MonitoringCgsProvider>
      </MonitoringLayout>
    )} />
  );
};

export default MonitoringCgsRoute;