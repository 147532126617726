import { FC } from "react";
import { Card, CardContent } from "@mui/material";
import DetailePageStyles from "../../../utilities/DetailePageStayles";


interface ICardLayoutProps {
    children: React.ReactNode;
}
const CardsLayout: FC<ICardLayoutProps> = ({ children }) => {
    const classes = DetailePageStyles();
    return (
        <>
            <Card className={classes.root}>
                <CardContent className={classes.innerCardContent}>
                    {children}
                </CardContent>
            </Card>
        </>
    );
}
export default CardsLayout;