import React, { ReactNode } from "react";
import { Box } from "@mui/material";

interface MessageBoxProps {
    children: ReactNode | string;
    alignVertical?: "top" | "center" | "bottom";
    alignHorizontal?: "left" | "center" | "right";
}

const MessageBox: React.FC<MessageBoxProps> = ({
    children,
    alignVertical = "center",
    alignHorizontal = "center",
}) => {
    const verticalAlignMap = {
        top: "flex-start",
        center: "center",
        bottom: "flex-end",
    };

    const horizontalAlignMap = {
        left: "flex-start",
        center: "center",
        right: "flex-end",
    };

    return (
        <Box
            display="flex"
            alignItems={verticalAlignMap[alignVertical]}
            justifyContent={horizontalAlignMap[alignHorizontal]}
            height="100vh"
            width="100%"
            textAlign="center"
        >
            {children}
        </Box>
    );
};

export default MessageBox;
