import { monitoring_initial_data } from "../../init";

const moitoringDetailsFields = [
  {
    groupName: "company_name_company_identification_code",
    groupLabel: "ბენეფიციარი კომპანიის დასახელება და საიდენტიფიკაციო კოდი",
    fields: [
      {
        name: "company_name",
        label: "ბენეფიციარი კომპანიის დასახელება",
        type: "string",
        value: "",
      },
      {
        name: "company_identification_code",
        label: "ბენეფიციარი კომპანიის საიდენტიფიკაციო ნომერი",
        type: "text",
        value: "",
      },
    ],
  },
  {
    groupName: "contract_number_contract_signed_date",
    groupLabel: "ბენეფიციართან გაფორმებული ხელშეკრულების ნომერი/სესხის/ლიზინგის ID და გაფორმების/დადასტურების თარიღი",
    fields: [
      {
        name: "contract_number",
        label: "ხელშეკრულების ნომერი/სესხის/ლიზინგის ID",
        disabled: true,
        type: "text",
        value: "",
      },
      {
        name: "contract_signed_date",
        label: "ხელშეკრულების გაფორმების / სესხის/ლიზინგის დადასტურების თარიღი",
        disabled: true,
        value: null,
      },
    ],
  },
  {
    groupName: "project_status_program",
    groupLabel: "პროექტის ფარგლებში ბენეფიციარის სტატუსი, პროგრამის დასახელება და პროგრამის კომპონენტი",
    fields: [
      {
        name: "project_status",
        label: "პროექტის სტატუსი",
        type: "select",
        value: "",
      },
      {
        name: "program",
        label: "სახელმწიფო პროგრამის დასახელება",
        type: "select",
      },
      {
        name: "program_component",
        label: "სახელმწიფო პროგრამის კომპონენტი",
        type: "select",
        value: "",
      },
      {
        name: "component",
        label: "სესხი / ლიზინგი",
        type: "select",
        value: "",
      },
      {
        name: "is_social_industry",
        label: "არის თუ არა კომპანია სოციალური საწარმო",
        type: "select",
        value: false,
      },
      {
        name: "social_industry_description",
        label: "სოციალური ტიპის საწარმოს აღწერა",
        type: "textarea",
        value: "",
      },
    ],
  },
  {
    groupName: "bank_credentials",
    groupLabel: "პროექტის ფარგლებში მხარდამჭერი ფინანსური ინსტიტუტი",
    fields: [
      {
        name: "bank",
        label: "ფინ. ინსტიტუტის დასახელება",
        type: "autocomplete",
        value: "",
      },
      {
        name: "approved_amount",
        label: "ფინ.ინსტიტუტის მიერ დამტკიცებული თანხა",
        type: "number",
        value: 0,
      },
      {
        name: "currency",
        label: "ვალუტა",
        type: "select",
        value: "",
      },
    ],
  },
  {
    groupName: "fixed_assets_capital_expenditures_funds_turnover",
    groupLabel: "პროექტის ფარგლებში ბენეფიციარის მხრიდან შესრულებული აქტივობები",
    fields: [
      {
        name: "used_amount",
        label: "ათვისებული თანხის მოცულობა ₾",
        type: "number",
        value: 0,
      },
      {
        name: "fixed_assets_capital_expenditures",
        label: "ძირითადი საშუალებები / კაპიტალური დანახარჯები",
        type: "number",
        value: 0,
      },
      {
        name: "machine_equipment_equipment",
        label: "მანქანა - დანადგარები / აღჭურვილობა",
        type: "number",
        value: 0,
      },
      {
        name: "vehicles_special_technic",
        label: "სატრანსპორტო საშუალებები / სპეც. ტექნიკა",
        type: "number",
        value: 0,
      },
      {
        name: "purchase_building_land",
        label: "შენობა ნაგებობის / მიწის შეძენა",
        type: "number",
        value: 0,
      },
      {
        name: "building_repairs",
        label: "მშენებლობა / რემონტი",
        type: "number",
        value: 0,
      },
      {
        name: "funds_turnover",
        label: "საბრუნავი საშუალებები",
        type: "number",
        value: 0,
        note: {
          label: "",
          note: `აღნიშნული მონაცემების გადამოწმება უნდა მოხდეს ხელშეკრულების / სააგენტოს დადასტურებული განაცხადის თარიღის და ამ თარიღით მოქმედი დადგენილების პირობებთან.`,
        },
      },
      {
        name: "worked_jobs_detail_description",
        label: "პროექტის ფარგლებში ბენეფიციარი კომპანიის მხრიდან შესრულებული სამუშაოების დეტალური აღწერა",
        type: "textarea",
        value: "",
      },
      {
        name: "project_activities",
        value: "",
        type: "text",
        label: "პროექტის ფარგლებში განხორციელებული საქმიანობის აღწერა",
        sub_fields: [],
        generateDetailsFields: (a: string, b: string) => `${a} - ${b}`,
      },
    ],
  },
  {
    groupName: `company_assets_income_prev_year`,
    groupLabel: `ბენეფიციარის ფინანსური მდგომარეობა წინა საანგარიშგებო წლის მონაცემებით - ₾`,
    fields: [
      {
        name: "enterprise_category",
        label: "საწარმოს კატეგორია შემოსავლების კომპონენტში",
        type: "select",
        value: "",
      },
      {
        name: "last_year_actives",
        label: "აქტივები",
        type: "number",
        value: 0,
      },
      {
        name: "last_year_income",
        label: "შემოსავლები",
        type: "number",
        value: 0,
      },
      {
        name: "last_year_paid_taxes",
        label: "გადასახადები",
        type: "number",
        value: 0,
      },
    ],
  },
  {
    groupName: `company_full_investment_volume`,
    groupLabel: `პროექტის ფარგლებში ბენეფიციარის მხრიდან განხორციელებული სრული ინვესტიციის (სესხი/ლიზინგი + სხვა ინვესტიციები) მოცულობა - ₾`,
    fields: [
      {
        name: "company_full_investment_volume",
        label: "ინვესტიციის მოცულობა",
        type: "number",
        value: 0,
      },
    ],
  },
  {
    groupName: `is_external_capital_external_capital_source`,
    groupLabel: `გარე კაპიტალი`,
    fields: [
      {
        name: "is_external_capital",
        label: "აქვს თუ არა კომპანიას მოზიდული გარე კაპიტალი",
        type: "text",
        value: false,
      },
      {
        name: "external_capital_source",
        label: "გარე კაპიტალის დასახელება",
        type: "text",
        value: "",
      },
    ],
  },
  {
    groupName: `social_industry_group`,
    groupLabel: `არის თუ არა კომპანია სოციალური საწარმო`,
    fields: [
      {
        name: "is_social_industry",
        label: "არის თუ არა კომპანია სოციალური საწარმო",
        type: "select",
        value: false,
      },
      {
        name: "social_industry_description",
        label: "სოციალური ტიპის საწარმოს აღწერა",
        type: "textarea",
        value: "",
      },
    ],
  },
  {
    groupName: `exporter_company`,
    groupLabel: `ექსპორტი`,
    fields: [
      {
        name: "is_exporter",
        label: "ექსპორტი",
        type: "select",
        value: false,
      },
      {
        name: "country_export",
        value: "",
        type: "text",
        label: "ქვეყანა",
        generateDetailsFields: (val: string) => val,
        sub_fields: [],
      },
      {
        name: "product_export",
        label: "საექსპორტო პროდუქციის ჩამონათვალი",
        type: "textarea",
        value: "test",
      },
    ],
  },
  {
    groupName: `is_using_brand_used_brand`,
    groupLabel: `საქმიანობს თუ არა კომპანია ბრენდული სახელით, მათ შორის საერთაშორისო ბრენდის სახელით`,
    fields: [
      {
        name: "is_using_brand",
        label: "საქმიანობს თუ არა კომპანია ბრენდული სახელით",
        type: "select",
        value: false,
      },
      {
        name: "used_brand",
        label: "ბრენდის სახელწოდება",
        type: "text",
        value: "",
      },
    ],
  },
  {
    groupName: `is_functional_none_functional_reason`,
    groupLabel: `ბენეფიციარი კომპანიის ფუნქციონირების სტატუსი`,
    fields: [
      {
        name: "start_activity_date",
        label: "პროექტის ფარგლებში ფუნქციონირების დაწყების ბოლო ვადა",
        type: "date",
        value: null,
      },
      {
        name: "is_functional",
        label: "ფუნქციონირების სტატუსი",
        type: "select",
        value: false,
      },
      {
        name: "stop_functional_activity_date",
        label: "პროექტის ფარგლებში ფუნქციონირების შეწყვეტის თარიღი",
        type: "date",
        value: null,
      },
      {
        name: "none_functional_reason",
        label: "მიზეზი",
        type: "textarea",
        value: "",
      },
    ],
  },
  {
    groupName: `is_started_operating_within_project_group`,
    groupLabel: `ბენეფიციარი კომპანიის საქმიანობის პროფილის შენარჩუნების სტატუსი `,
    fields: [
      {
        name: "is_started_operating_within_project",
        label: "პროექტის ფარგლებში ბენეფიციარ კომპანიას დაწყებული აქვს თუ არა  ფუნქციონირება",
        type: "select",
        value: true,
      },
      {
        name: "start_beneficiar_activity_date",
        label: "პროექტის ფარგლებში საქმიანობის დაწყების თარიღი",
        type: "date",
        value: null,
      },
      {
        name: "is_same_company_profile_activity",
        label: "მონიტორინგის განხორციელების დღისთვის ბენეფიციარ კომპანიას შენარჩუნებული აქვს თუ არა საქმიანობის პროფილი",
        type: "select",
        value: true,
      },
      {
        name: "other_profile",
        label: "კომენტარის ველი",
        type: "textarea",
        value: "",
      },
    ],
  },
  {
    groupName: `employee_amount_enterprise_category`,
    groupLabel: `დასაქმებულთა რაოდენობა`,
    fields: [
      {
        name: "woman_employee_amount",
        label: "ქალი",
        type: "number",
        value: 0,
      },
      {
        name: "man_employee_amount",
        label: "კაცი",
        type: "number",
        value: 0,
      },
      {
        name: "employee_amount",
        label: "დასაქმებულთა არსებული რაოდენობა",
        type: "number",
        value: 0,
      },
      {
        name: "jobs_created",
        label: "პროექტის ფარგლებში შექმნილი სამუშაო ადგილების რაოდენობა",
        type: "number",
        value: 0,
      },
      {
        name: "is_lack_of_personnel",
        label: "განიცდის თუ არა კადრების დეფიციტს კომპანია",
        type: "select",
        value: false,
      },
      {
        name: "is_lack_of_administration",
        label: "ადმინისტრაციული",
        type: "checkbox",
        value: false,
      },
      {
        name: "is_production_service_direction",
        label: "წარმოების/მომსახურების გაწევის მიმართულებით",
        type: "checkbox",
        value: false,
      },
      {
        name: "lack_of_production_service_direction",
        label: "წარმოების/მომსახურების გაწევის მიმართულება",
        type: "text",
        value: "",
      },
      {
        name: "marketing",
        label: "მარკეტინგი",
        type: "checkbox",
        value: false,
      },
      {
        name: "accounting",
        label: "ბუღალტერია",
        type: "checkbox",
        value: false,
      },
      {
        name: "finances",
        label: "ფინანსები",
        type: "checkbox",
        value: false,
      },
      {
        name: "hr",
        label: "HR",
        type: "checkbox",
        value: false,
      },
      {
        name: "sales",
        label: "გაყიდვები",
        type: "checkbox",
        value: false,
      },
      {
        name: "is_other_administration",
        label: "სხვა",
        type: "checkbox",
        value: false,
      },
      {
        name: "other_administration",
        label: "სხვა",
        type: "text",
        value: "",
      },
      {
        name: "is_person_disability",
        label: "დასაქმებული შშმ პირი",
        type: "select",
        value: false,
      },
      {
        name: "employeed_person_disability_amount",
        label: "დასაქმებული შშმ პირ(ებ)ის რაოდენობა",
        type: "number",
        value: 0,
      },
      {
        name: "is_building_adapted_for_person_disability",
        label: "ბენეფიციარი კომპანიის სამეურნეო ადმინისტრაციული შენობა ადაპტირებულია შშმ პირებზე",
        type: "select",
        value: false,
      },
    ],
  },
  {
    groupName: `employee_amount_gender_statistic`,
    groupLabel: `ბენეფიციარის გენდერული სტატისტიკა`,
    fields: [
      {
        name: "director_women_amount",
        label: "დირექტორი ქალ(ებ)ის რაოდენობა",
        type: "number",
        value: 0,
      },
      {
        name: "founder_women_amount",
        label: "დამფუძნებელი ქალ(ებ)ის",
        type: "number",
        value: 0,
      },
      {
        name: "founder_women_amount_percentage",
        label: "დამფუძნებელი ქალ(ებ)ის ჯამური წილობრივი მონაწილეობა",
        type: "number",
        value: 0,
      },
    ],
  },
  {
    groupName: `actual_municipality_address_cadastral_code_is_building_rented`,
    groupLabel: `ბენეფიციარის საქმიანობის განხორციელების ფაქტობრივი მისამართი`,
    fields: [
      {
        name: "actual_municipality.region",
        label: "რეგიონი",
        type: "autocomplete",
        value: "",
      },
      // is_sign_inscription_banner
      {
        name: "is_sign_inscription_banner",
        label: `საქმიანობის განხორციელების ადგილას განთავსებულია აბრა წარწერით - პროექტი განხორციელდა პროგრამის "აწარმოე საქართველოში" მხარდაჭერით`,
        type: "checkbox",
        value: false,
      },
      {
        name: "actual_municipality.municipality",
        label: "მუნიციპალიტეტი",
        type: "autocomplete",
        value: "",
      },
      {
        name: "actual_address",
        label: "მისამართი(სოფელი/დაბა/ქუჩა/ნომერი)*",
        type: "text",
        value: "",
      },
      {
        name: "cadastral_code",
        label: "საკადასტრო კოდი",
        type: "text",
        value: "",
      },
      {
        name: "is_building_rented",
        label: "კომპანიის საქმიანობის განხორციელების ფაქტობრივი მისამართის სტატუსი",
        type: "select",
        value: false,
      },
    ],
  },
  {
    groupName: `representative_name_phone_position_id_number_email`,
    groupLabel: `ბენეფიციარის წარმომადგენელი პირი`,
    fields: [
      {
        name: "representative_name",
        label: "სახელი, გვარი",
        type: "text",
        value: "",
      },
      {
        name: "representative_phone",
        label: "ტელ. ნომერი",
        type: "text",
        value: "",
      },
      {
        name: "representative_position",
        label: "პოზიცია",
        type: "text",
        value: "",
      },
      {
        name: "representative_id_number",
        label: "პ/ნ",
        type: "text",
        value: "",
      },
      {
        name: "representative_email",
        label: "წარმომადგენლის ელ. ფოსტა",
        type: "text",
        value: "",
      },
    ],
  },
  {
    groupName: `hotel_information`,
    groupLabel: `სასტუმრო/ბალნეოლოგიური კურორტები`,
    fields: [
      {
        name: "new_hotel_rooms",
        label: "პროექტის ფარგლებში შექმნილი ახალი ნომრების რაოდენობა",
        type: "number",
        value: 0,
      },
      {
        name: "hotel_rooms",
        label: "სასტუმრო ნომრების სრული რაოდენობა",
        type: "number",
        value: 0,
      },
      {
        name: "hotel_information.satisfies_criteria",
        label: "სასტუმროს ნომრები აკმაყოფილებს პროგრამის კრიტერიუმებს",
        type: "checkbox",
        value: false,
      },
      {
        name: "hotel_information.elevator",
        label: "ლიფტი",
        type: "checkbox",
        value: false,
      },
      {
        name: "hotel_information.elevator_absence_reason",
        label: "ლიფტის არ-არსებობის მიზეზი (ასეთ შემთხვევაში)",
        type: "text",
        value: "",
      },
      {
        name: "hotel_information.utilities_criteria",
        label: "სანიტარული კეთილმოწყობა და ელექტრომომარაგება",
        type: "checkbox",
        value: false,
      },
      {
        name: "hotel_information.noise_conditioning",
        label: "ხმაურის კონტროლი/ჰაერის კონდიცირება",
        type: "checkbox",
        value: false,
      },
      {
        name: "hotel_information.fire_safety",
        label: "უსაფრთხოების მინიმალური მოთხოვნები",
        type: "checkbox",
        value: false,
      },
      {
        name: "hotel_information.disabled_criteria",
        label: "პირობები შეზღუდული შესაძლებლობის მქონე პირთათვის",
        type: "checkbox",
        value: false,
      },
      {
        name: "hotel_information.reception_criteria",
        label: "სტუმართა რეგისტრაცია (რეცეფცია)",
        type: "checkbox",
        value: false,
      },
      {
        name: "hotel_information.catering",
        label: "კვების ობიექტი (სასადილო/რესტორანი)",
        type: "checkbox",
        value: false,
      },
      {
        name: "hotel_information.reason_comment",
        label: "კრიტერიუმ(ებ)ის არდაკმაყოფილების მიზეზი",
        type: "textarea",
        value: "",
      },
    ],
  },
  {
    groupName: `tourist_services_information`,
    groupLabel: `ტურისტული სერვისები (თემატური პარკები და სათავგადასავლო ტურიზმი)`,
    fields: [
      {
        name: "tourist_services_information.type",
        label: "ტიპი",
        type: "select",
        value: "",
      },
      {
        name: "adventure_tourism_measures",
        label: "სათავგადასავლო ტურიზმის მიმართულებით უნდა უზრუნველყოფდეს",
        type: "text",
        value: "",
        subGroupFields: [
          {
            name: "tourist_services_information.adventure_tourism_measures.guest_life_insurance",
            label: "ობიექტზე მე-3 პირის (სტუმრის) სიცოცხლისა და ჯანმრთელობის დაზღვევა",
            type: "checkbox",
            value: false,
          },
          {
            name: "tourist_services_information.adventure_tourism_measures.group_service",
            label: "ჯგუფური ტურისტული აქტივობების შემთხვევაში ერთდროულად (დროის ერთ მონაკვეთში) არანაკლებ 20 ადამიანის მომსახურებას",
            type: "checkbox",
            value: false,
          },
          {
            name: "tourist_services_information.adventure_tourism_measures.individual_service",
            label: "ინდივიდუალური ტურისტული აქტივობების შემთხვევაში ერთდროულად (დროის ერთ მონაკვეთში) არანაკლებ 5 ადამიანის მომსახურება",
            type: "checkbox",
            value: false,
          },
        ],
      },
      {
        name: "historic_simulation",
        label: "თემატური პარკების ეთნო/ეთნოგრაფიული პარკების მიმართულებით ობიექტი უზრუნველყოფს",
        type: "text",
        value: "",
        subGroupFields: [
          {
            name: "tourist_services_information.historic_simulation",
            label: "ძველი დროის პერიოდის სიმულაციას, რომელიც გამოხატავს ძველი ტრადიციების და კულტურის, ბუნებრივი და ისტორიული გარემოს იმიტაციას",
            type: "checkbox",
            value: false,
          },
        ],
      },
      {
        name: "tourist_services_information.elevator",
        label: "ლიფტი",
        type: "checkbox",
        value: false,
      },
      {
        name: "tourist_services_information.elevator_absence_reason",
        label: "კომენტარი ლიფტის არარსებობის შემთხვევაში",
        type: "text",
        value: "",
      },
      {
        name: "tourist_services_information.utilities_criteria",
        label: "სანიტარული კეთილმოწყობა და ელექტრომომარაგება",
        type: "checkbox",
        value: false,
        note: {
          label: "სანიტარული კეთილმოწყობა და ელექტრომომარაგება",
          note: `პროექტით გათვალისწინებულია: გარე განათება;შენობის შიდა ფართობის განათება;საშხაპე/აბაზანა, ხელსაბანი, უნიტაზი;სასტუმრო ოთახ(ებ)ის
                              და სანიტარიული კვანძის სათანადო განათება და ელექტრომომარაგება.`,
        },
      },
      {
        name: "tourist_services_information.noise_conditioning",
        label: "ხმაურის კონტროლი/ჰაერის კონდიცირება",
        type: "checkbox",
        value: false,
        note: {
          label: "სანიტარული კეთილმოწყობა და ელექტრომომარაგება",
          note: `ნომერში არანაკლებ ერთი ფანჯარა ბუნებრივი განათებისათვის (ხმაურდამცავი მინაპაკეტით); ოთახების ცენტრალური ან/და ინდივიდუალური
                              კონდიცირება (გათბობა/გაგრილების სისტემა), აღჭურვილი ინდივიდუალური მართვის საშუალებებით; დამხმარე ოთახებისა (საჭიროების მიხედვით)
                              და საზოგადოებრივი ადგილების ცენტრალური ან/და ინდივიდუალური კონდიცირება (გათბობა/გაგრილების სისტემა).`,
        },
      },
      {
        name: "tourist_services_information.fire_safety",
        label: "უსაფრთხოების მინიმალური მოთხოვნები",
        type: "checkbox",
        value: false,
        note: {
          label: "უსაფრთხოების მინიმალური მოთხოვნები",
          note: `ხანძარსაწინააღმდეგო ინსტრუქცია ქართულ და უცხო ენებზე; საევაკუაციო სქემა სტუმრისთვის თვალსაჩინო ადგილას;`,
        },
      },
      {
        name: "tourist_services_information.disabled_criteria",
        label: "პირობები შეზღუდული შესაძლებლობის მქონე პირთათვის",
        type: "checkbox",
        value: false,
        note: {
          label: "პირობები შეზღუდული შესაძლებლობის მქონე პირთათვის",
          note: `სასტუმროსთან ეტლით მისასვლელი პანდუსი; შენობის შიგნით შეუფერხებელი გადაადგილება; ლიფტით, ეტლით გადაადგილება; შეზღუდული
                              შესაძლებლობის მქონე პირების სასტუმროში განთავსების საშუალება.`,
        },
      },
      {
        name: "tourist_services_information.catering",
        label: "კვების ობიექტი (სასადილო/რესტორანი)",
        type: "checkbox",
        value: false,
        note: {
          label: "კვების ობიექტი (სასადილო/რესტორანი)",
          note: `სასადილო ან რესტორანი.`,
        },
      },
      {
        name: "tourist_services_information.reason_comment",
        label: "კრიტერიუმ(ებ)ის არდაკმაყოფილების მიზეზი",
        type: "textarea",
        value: "",
      },
    ],
  },
  {
    groupName: `agro_tourism_information`,
    groupLabel: `აგრო ტურიზმი`,
    fields: [
      {
        name: "agro_tourism_information.placement",
        label: "განთავსება",
        type: "checkbox",
        value: false,
      },
      {
        name: "agro_tourism_information.catering",
        label: "კვებითი მომსახურება",
        type: "checkbox",
        value: false,
      },
      {
        name: "agro_tourism_information.recreational",
        label: "გასართობი/რეკრეაციული",
        type: "checkbox",
        value: false,
      },
      {
        name: "agro_tourism_information.educational",
        label: "საგანმანათლებლო/შემეცნებითი",
        type: "checkbox",
        value: false,
      },
      {
        name: "agro_tourism_information.farming_property",
        label: "საკუთრების ან მართლზომიერი სარგებლობის უფლებით ფლობს ფერმერულ მეურნეობას*",
        type: "checkbox",
        value: false,
      },
      {
        name: "agro_tourism_information.food_safety",
        label: `სურსათის/ცხოველის საკვების უვნებლობის, ვეტერინარიისა და მცენარეთა დაცვის კოდექსით
                განსაზღვრული მოთხოვნების შესაბამისად, რეგისტრირებულია ბიზნესოპერატორად*`,
        type: "checkbox",
        value: false,
      },
      {
        name: "agro_tourism_information.registered_farm",
        label: `რეგისტრირებულია   საქართველოს გარემოს დაცვისა და სოფლის მეურნეობის
            სამინისტროს ფერმათა/ფერმერთა რეესტრში*`,
        type: "checkbox",
        value: false,
      },
      {
        name: "agro_tourism_information.is_other_activity",
        label: "სხვა აქტივობა",
        type: "checkbox",
        value: false,
      },
      {
        name: "agro_tourism_information.other_activity",
        label: "სხვა აქტივობის მითითება",
        type: "text",
        value: "",
      },
    ],
  },
  {
    groupName: `eco_tourism_information`,
    groupLabel: `ეკო ტურიზმი`,
    fields: [
      {
        name: "eco_tourism_information.type",
        label: "ტიპი",
        type: "select",
        value: "",
      },
      {
        name: "eco_tourism_information.placement.existing_building",
        label:
          "არსებული შენობა-ნაგებობა, რომელიც არის რეგიონისთვის დამახასიათებელი ავთენტური არქიტექტურის და განკუთვნილია განთავსების ობიექტად განვითარებისა და ფუნქციონირებისთვის",
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.placement.mutliple_guests",
        label: "ერთდროულად არანაკლებ 8 სტუმრის გამასპინძლება",
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.placement.multiple_rooms",
        label: "სანომრე ფონდი არაუმეტეს 10 ნომრისა",
        type: "checkbox",
        value: false,
      },

      {
        name: "eco_tourism_information.artisan.existing_workshop",
        label: "არსებული, მოქმედი სახელოსნო, საქმიანობისთვის გამორჩეული სახასიათო ელემენტებით",
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.artisan.demonstrational_area",
        label: `გააჩნია არანაკლებ 10 ადამიანზე გათვლილი საჩვენებელი სივრცე`,
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.artisan.masterclass_area",
        label: `გააჩნია არანაკლებ 5 ადამიანზე გათვლილი მასტერკლასის ჩატარების შესაძლებლობა`,
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.nature.non_motorized_transport.bicycle",
        label: "ველოსიპედი",
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.nature.non_motorized_transport.horse",
        label: "ცხენი",
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.nature.non_motorized_transport.water",
        label: "საწყალოსნო",
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.nature.non_motorized_transport.seasonal_sled",
        label: "სეზონური ჩასაბმელები",
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.nature.non_motorized_transport.is_other",
        label: "სხვა",
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.nature.non_motorized_transport.other",
        label: "სხვა",
        type: "text",
        value: "",
      },
      {
        name: "eco_tourism_information.nature.accessories.safety",
        label: "უსაფრთხოების აქსესუარები",
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.nature.accessories.inventory",
        label: "აღჭურვილობა/ინვენტარი",
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.nature.accessories.is_other",
        label: "სხვა აქსესუარები",
        type: "checkbox",
        value: false,
      },
      {
        name: "eco_tourism_information.nature.accessories.other",
        label: "სხვა აქსესუარები",
        type: "textarea",
        value: "",
      },
    ],
  },
];

export { moitoringDetailsFields, monitoring_initial_data };
