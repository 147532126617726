import { FC } from "react";
import { Card, CardContent, Button, Typography } from "@mui/material";
// import DetailedStyles from "../../../utilities/DetailedStyles";
import DetailePageStyles from "../../../utilities/DetailePageStayles";


interface ICardLayoutProps {
    title?: string;
    children: React.ReactNode;
    cardVariant: "outlined" | "elevation";
}
const CardComponent: FC<ICardLayoutProps> = ({ children, title = '', cardVariant = "outlined" }) => {
    const classes = DetailePageStyles();
    return (
        <>
            <Card className={classes.component} variant={cardVariant}>
                <CardContent>
                    <h2 className={classes.cardSummary}>
                        {title}
                    </h2>
                    {children}
                </CardContent>
            </Card >
        </>
    );
}
export default CardComponent;