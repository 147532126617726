import * as React from 'react';
import {
    TextField,
    Autocomplete
} from '@mui/material';
import { Box } from '@material-ui/core';
import { MyType } from '../../../types';
interface SelectFieldProps {
    name: string;
    value: { value: string; label: string } | null;
    label: string;
    options: MyType<{ value: string; label: string }>;
    callback?: (value: { value: string; label: string }, name?: string) => void;
}
const AutoComplete: React.FC<SelectFieldProps> = ({
    name,
    value,
    label,
    options,
    callback
}: SelectFieldProps) => {
    const [internalValue, setInternalValue] = React.useState<{
        value: string; label: string
    } | null>(value);
    const handleSelectChange = (data: {
        value: string; label: string
    }) => {
        if (callback) {
            callback && callback(data);
        }
    };
    React.useEffect(() => {
        setInternalValue(value);
    }, [value])
    return (
        <Autocomplete
            // defaultValue={value ?? null}
            value={internalValue}
            sx={{
                width: "100%",
                height: '45px',
                padding: '2px'
            }}
            options={
                options
                    ?
                    Object.values(options) as unknown as { value: string; label: string; }[]
                    : []
            }
            autoHighlight
            getOptionLabel={(option) => {
                return option.label
            }}
            renderOption={(props, option) => {
                return (
                    <Box
                        key={option.value}
                        component="li"
                        sx={{
                            fontSize: '12px'
                        }}
                        {...props}
                    >
                        {option.label}
                    </Box>
                )
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        label={label}
                        size='small'
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "&:focus": {
                                    outline: `2px solid 'red'`,
                                },
                                "&:hover fieldset": {
                                    color: "#1976d2",
                                    borderColor: "#1976d2",
                                    borderWidth: "2px",
                                },
                            },
                        }}
                        InputLabelProps={{
                            sx: {
                                fontSize: "1rem",
                                top: '-2px',
                            },
                            shrink: true,
                        }}
                        inputProps={{
                            ...params.inputProps,
                            sx: {
                                height: '20px',
                                padding: '2px',
                                fontSize: 12,
                            }
                        }}
                    />
                )
            }}
            filterOptions={(options, state) => {
                if (state.inputValue.length > 2) {
                    return options.filter((item) =>
                        String(item.label).toLowerCase().includes(state.inputValue.toLowerCase())
                    );
                }
                return options;
            }}
            onChange={(_, data) => handleSelectChange(data as { value: string, label: string })}
            componentsProps={{
                clearIndicator: {
                    sx: {
                        '& .MuiSvgIcon-root': {
                            fontSize: '0.8rem', // Change the size of the clear button here
                        },
                    },
                },
            }}
        />
    )
}

export default AutoComplete;