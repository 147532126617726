export interface IServer {
  protocol: string;
  host: string;
}

export interface IEndpoint {
  method: string;
  uri: IPath;
}

export interface IPath {
  pathname: string;
  params?: Record<string, string>;
}

export interface IClient {
  endpoint: {
    [key: string]: IEndpoint;
  };
}

// /monitoring/update_application

export interface IConfig {
  client: IClient;
}

export const config: IConfig = {
  client: {
    endpoint: {
      getCompanies: {
        method: "GET",
        uri: {
          pathname: "/monitoring/company_info",
        },
      },
      getAgreementCgs: {
        method: "GET",
        uri: {
          pathname: "/monitoring/applications_cgs",
        },
      },
      getAgreement: {
        method: "GET",
        uri: {
          pathname: "/monitoring/agreement",
        },
      },
      getAgreementById: {
        method: "GET",
        uri: {
          pathname: "/monitoring/agreement/:id",
        },
      },
      getAgreementCgsById: {
        method: "GET",
        uri: {
          pathname: "/monitoring/agreement_cgs/:id",
        },
      },
      getApplicant: {
        method: "GET",
        uri: {
          pathname: "/monitoring/application/:id",
        },
      },
      getApplicantCgs: {
        method: "GET",
        uri: {
          pathname: "/monitoring/application_cgs/:id",
        },
      },
      getGeneralInfo: {
        method: "GET",
        uri: {
          pathname: "/general_monitoring_options",
        },
      },
      monitoringSubmit: {
        method: "POST",
        uri: {
          pathname: "/monitoring/submit_application",
        },
      },
      updateApplication: {
        method: "POST",
        uri: {
          pathname: "/monitoring/update_application",
        },
      },
      monitoringCgsSubmit: {
        method: "POST",
        uri: {
          pathname: "/monitoring/submit_application_cgs",
        },
      },
      updateApplicationCgs: {
        method: "POST",
        uri: {
          pathname: "/monitoring/update_application_cgs",
        },
      },
    },
  },
};

// /monitoring/application/:id

export default config;
