import { makeStyles } from "@material-ui/core/styles";

const DetailePageStyles = makeStyles({
  root: {
    minWidth: 275,
    width: "98%",
    fontSize: "0.9rem",
  },
  innerCardContent: {
    display: "flex",
    flexDirection: "column",
  },
  component: {
    minWidth: "48%",
    margin: "0 15px 15px 0",
  },
  cardSummary: {
    fontSize: "12px",
    margin: "0 0 8px 0",
    borderBottom: "1px #979797 solid",
    padding: "5px 0",
  },
  table: {
    borderCollapse: "collapse",
    minWidth: "100%",
    width: "100%",
    margin: "0 0 15px 0",
    fontSize: "12px",
  },
  row: {
    width: "100%",
    borderBottom: "1px #e3e3e3 solid",
    padding: '20px 0',
  },
  title: {
    textAlign: "left",
    padding: "5px 5px 5px 0",
    color: "#545454",
  },
  detail: {
    fontWeight: "bold",
    textAlign: "right",
    verticalAlign: "middle",
    maxWidth: "calc(100%)",
    padding: "5px 0 5px 5px",
    color: "#000"
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    gap: "10px",
    margin: "15px 0 0 0",
  },
  button: {
    margin: "30px 30px 30px 0",
  },
  label: {
    fontWeight: "bold",
    color: "#000",
    padding: '5px 0',
  }
});

export default DetailePageStyles;
