import * as yup from "yup";

export const monitoringCgsInitialDataSchema: yup.ObjectSchema<any> = yup.object().shape({
  // fixed_assets_capital_expenditures: yup.number().when("program_component", {
  //   is: (program: string) => ![ProgramComponent.CREDIT_GUARANTEE_COMPONENT].includes(program as ProgramComponent),
  //   then: yup
  //     .number()
  //     .required("გთხოვთ, მიუთითოთ ძირითადი საშუალებები/კაპიტალური დანახარჯები.")
  //     .min(1, "გთხოვთ, მიუთითოთ ძირითადი საშუალებები/კაპიტალური დანახარჯები."),
  //   otherwise: yup
  //     .number()
  //     .required("გთხოვთ, მიუთითოთ ძირითადი საშუალებები/კაპიტალური დანახარჯები.")
  //     .min(0, "გთხოვთ, მიუთითოთ ძირითადი საშუალებები/კაპიტალური დანახარჯები."),
  // }),
  // funds_turnover: yup.number().required("გთხოვთ, მიუთითოთ საბრუნავი საშუალებები.").min(0, "გთხოვთ, მიუთითოთ საბრუნავი საშუალებები."),
  is_female_entrepreneur_checked: yup.boolean(),
  is_mountain_region_checked: yup.boolean(),
  is_income_to_k_checked: yup.boolean(),
  is_sun_gate_beneficiary_checked: yup.boolean(),
  is_purpose_detailed_provided: yup.boolean(),
  is_project_activities_checked: yup.boolean(),
  is_purchased_activities_checked: yup.boolean(),
  actual_municipality: yup
    .string()
    .required("გთხოვთ, მიუთითოთ კომპანიის საქმიანობის განხორციელების რეგიონი/მუნიციპალიტეტი.")
    .typeError("გთხოვთ, მიუთითოთ კომპანიის საქმიანობის განხორციელების რეგიონი/მუნიციპალიტეტი."),
  actual_address: yup.string().required("გთხოვთ, მიუთითოთ მისამართი(სოფელი/დაბა/ქუჩა/ნომერი)"),
  cadastral_code: yup.string().required("გთხოვთ, მიუთითოთ საკადასტრო კოდი"),
  representative_name: yup.string().required("გთხოვთ, მიუთითოთ კომპანიის წარმომადგენელი (ხელმომწერი) პირის სახელი და გვარი"),
  representative_phone: yup.string().required("გთხოვთ, მიუთითოთ კომპანიის წარმომადგენელი (ხელმომწერი) პირის ტელ. ნომერი"),
  representative_position: yup.string().required("გთხოვთ, მიუთითოთ კომპანიის წარმომადგენელი (ხელმომწერი) პირის პოზიცია"),
  representative_id_number: yup.string().required("გთხოვთ, მიუთითოთ კომპანიის წარმომადგენელი (ხელმომწერი) პირის პირადი ნომერი"),
  representative_email: yup.string().email("ელ. ფოსტა არ არის ვალიდური").required("გთხოვთ, მიუთითოთ კომპანიის წარმომადგენელი (ხელმომწერი) პირის ელ. ფოსტა"),
  used_amount: yup.number().required("გთხოვთ, მიუთითოთ ათვისებული თანხის მოცულობა.").min(1, "გთხოვთ, მიუთითოთ ათვისებული თანხის მოცულობა."),
  project_activities_comment: yup.string().when("is_project_activities_checked", {
    is: false,
    then: yup.string().required("გთხოვთ, მიუთითოთ კომენტარი პროექტის აქტივობა"),
    otherwise: yup.string(),
  }),
  purchased_activities_comment: yup.string().when("is_purchased_activities_checked", {
    is: false,
    then: yup.string().required("გთხოვთ, მიუთითოთ სესხის თანხით შეძენილი აქტივობები"),
    otherwise: yup.string(),
  }),
  female_entrepreneur_comment: yup.string().when("is_female_entrepreneur_checked", {
    is: false,
    then: yup.string().required("გთხოვთ, მიუთითოთ კომენტარი ქალ მეწარმეზე"), // make comment required
    otherwise: yup.string(),
  }),
  mountain_region_comment: yup.string().when("is_mountain_region_checked", {
    is: false,
    then: yup.string().required("გთხოვთ, მიუთითოთ კომენტარი მთის რეგიონზე"),
    otherwise: yup.string(),
  }),
  // income_to_k_comment: yup.string().when("is_income_to_k_checked", {
  //   is: false,
  //   then: yup.string().required("გთხოვთ, მიუთითოთ კომენტარი შემოსავლისკენ"),
  //   otherwise: yup.string(),
  // }),
  sun_gate_beneficiary_comment: yup.string().when("is_sun_gate_beneficiary_checked", {
    is: false,
    then: yup.string().required("გთხოვთ, მიუთითოთ კომენტარი სუნის კარის ბენეფიციარზე"),
    otherwise: yup.string(),
  }),
  purpose_detailed_comment: yup.string().when("is_purpose_detailed_provided", {
    is: false,
    then: yup.string().required("გთხოვთ, მიუთითოთ სესხის მიზნობრიობა დეტალურად"),
    otherwise: yup.string(),
  }),
  additional_comments: yup.string().required("გთხოვთ, მიუთითოთ დამატებითი კომენტარი"),
});
