/**
 * @React
 */
import {
    useState,
    useEffect,
    FC
} from "react";
/**
 * @ThirdPartProgramms
 */
import dateFormat from "dateformat";
/**
 * @Mui
 */
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
/**
 * @Utilities
 */
import DetailedStyles from "../../../../utilities/DetailedStyles";
// import { fields, generateValueFormat } from "../../../../utilities/Fields/monitoringFields/fields/changesFields";
/**
 * @Types
 */
import { IMonitoringApprovalHistory } from "../../../../types";


interface IMonitoringApprovalHistoryProps {
    approvalHistory: IMonitoringApprovalHistory[];
    generateValueFormat: any;
}

const MonitoringApprovalHistory: FC<IMonitoringApprovalHistoryProps> = ({
    approvalHistory,
    generateValueFormat
}: IMonitoringApprovalHistoryProps) => {
    return (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 640 }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{
                                color: "rgb(44, 91, 148)",
                                fontSize: "12px"
                            }} align="left"><b>განაცხადის დადასტურების სტატუსი</b></TableCell>
                            <TableCell sx={{
                                color: "rgb(44, 91, 148)",
                                fontSize: "12px"
                            }} align="left"><b>განაცხადის დაუარების მიზეზი</b></TableCell>
                            <TableCell sx={{
                                color: "rgb(44, 91, 148)",
                                fontSize: "12px"
                            }} align="left"><b>მონიტორინგის სპეციალისტი</b></TableCell>
                            <TableCell sx={{
                                color: "rgb(44, 91, 148)",
                                fontSize: "12px"
                            }} align="left"><b>ცვლილების განხორციელების თარიღი</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            approvalHistory.map((row: IMonitoringApprovalHistory, index) => (
                                <TableRow
                                    key={`${index}`}
                                >
                                    <TableCell align="left">{generateValueFormat["approved" as keyof typeof generateValueFormat] ? generateValueFormat["approved" as keyof typeof generateValueFormat](row?.approved) : ""}</TableCell>
                                    <TableCell align="left">{row.comment || ""}</TableCell>
                                    <TableCell align="left">
                                        {row?.approved_by?.name || ""}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.approved_at ? `${dateFormat(row.approved_at, "dd/mm/yyyy HH:MM:ss")}` : ''}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default MonitoringApprovalHistory;
/**
 *   const columns = [
    {
      field: "bank",
      headerName: "ბანკი",
      flex: 2,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        return params.row.bank.name;
      },
    },
    { field: "amount", headerName: "თანხა", flex: 2, headerAlign: "center", align: "center" },
    { field: "interest_amount", headerName: "პროცენტი", flex: 2, headerAlign: "center", align: "center" },
    { field: "principal_amount", headerName: "ძირი", flex: 2, headerAlign: "center", align: "center" },
    {
      field: "date",
      headerName: "თარიღი",
      flex: 2,
      headerAlign: "center",
      align: "center",
      type: "date",
      valueGetter: (params) => {
        return dateFormat(params.row.date, "yyyy-mm-dd", true);
      },
    },
  ];

  return (
    <div style={{ marginTop: 30, height: 400, width: "100%" }}>
      <DataGrid getRowId={(row) => row._id} rows={rows} columns={columns} pageSize={100} rowsPerPageOptions={[5]} disableColumnMenu hideFooter />;
    </div>
  );
}
 */
