/**
 * @React
 */
import React, { useEffect, useState } from "react";

/**
 * @Mui
 */
import {
    Tab,
    Tabs,
    Card,
    CardHeader,
} from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import TabContext from "@mui/lab/TabContext";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
/**
 * @Context
 */
import {
    Type
} from "../../../context/MonitoringApplicationContext";
/**
 * @Components
 */
import MonitoringCgsDashboard from "../MonitoringCgsDashboard";
import MonitoringDashboard from "../MonitoringDashboardPage";
import MonitoringLayout from "../../../components/Layouts/MonitoringLayout";
import MonitoringCgsProvider from "../../../providers/MonitoringCgsProvider";
import MonitoringFormProvider from "../../../providers/MonitoringProvider";

const MonitoringTabs: React.FC = () => {
    const [tab, setTab] = useState<Type>(() => Type.monitoring as Type);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    useEffect(() => {
        if (localStorage.getItem("role") === "ROLE_MONITORING") {
            setIsChecked(true);
        }
        if (localStorage.getItem("role") === "ROLE_MONITORING_CGS") {
            setIsChecked(false);
        }
    }, [])
    return (
        <MonitoringLayout>
            <Card sx={{ width: "100%" }}>
                <TabContext value={tab}>
                    <CardHeader
                        subheader={
                            <Tabs
                                value={tab}
                                onChange={(e, newValue) => {
                                    setTab(newValue);
                                }}
                                variant="fullWidth"
                                textColor="primary"
                                indicatorColor="primary"
                            >
                                <Tab label="დასამონიტორინგებელი" value="agreement" icon={<AssignmentIcon />} />
                                <Tab label="დამონიტორინგებული" value="monitoring" icon={<ArticleIcon />} />
                                <Tab label="დადასტურებული" value="approved" icon={<AssignmentTurnedInIcon />} />
                            </Tabs>
                        }
                    />
                    {
                        isChecked
                            ?
                            <MonitoringFormProvider>
                                <MonitoringDashboard
                                    tab={tab}
                                    isChecked={isChecked}
                                    setIsChecked={setIsChecked}
                                />
                            </MonitoringFormProvider>
                            :
                            <MonitoringCgsProvider>
                                <MonitoringCgsDashboard
                                    tab={tab}
                                    isChecked={isChecked}
                                    setIsChecked={setIsChecked}
                                />
                            </MonitoringCgsProvider>
                    }

                </TabContext>
            </Card >
        </MonitoringLayout>
    );
};

export default MonitoringTabs;
