import { Button, Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import CheckIcon from "@material-ui/icons/Check";
import CachedIcon from "@material-ui/icons/Cached";
import ErrorIcon from "@material-ui/icons/Error";
import axios from "axios";
import dateFormat from "dateformat";

function approveDiscrepancy(applicationID, approval) {
  const component = window.location.pathname.split("/")[1];
  axios
    .post("/agency/approve_discrepancy", {
      application: applicationID,
      component: component,
      approval: approval,
    })
    .then((res) => {
      alert(res.data.message);
      // Reload the page to reflect the updated status
      window.location.reload();
    })
    .catch((err) => {
      const errorMessage = err.response?.data?.message || "შეცდომა";
      alert(errorMessage);
      console.error("Discrepancy approval error:", err);
    });
}

// Helper function to determine the status icon based on the status field
function getStatusIcon(item) {
  switch (item.status) {
    case "approved":
      return <CheckIcon style={{ color: "green" }} />;
    case "rejected":
      return <ErrorIcon style={{ color: "red" }} />;
    case "pending":
    default:
      return <CachedIcon style={{ color: "orange" }} />;
  }
}

export default function ScheduleDiscrepancy({ isBank, loan_information }) {
  //If loan_information.schedule_discrepancy is not true, return null
  if (!loan_information.schedule_discrepancy) {
    return null;
  }
  //For loan_information.schedule_discrepancies.missing_dates create horizontal timeline with arrows
  const missing_dates = loan_information.schedule_discrepancies.missing_dates.map((date, index) => {
    const dateToDisplay = date.date ? date.date : date;
    return (
      <Grid item xs={12} key={index}>
        <Card>
          <CardHeader title={dateFormat(dateToDisplay, "mmmm dS Z, yyyy", true)} subheader="გამოტოვებული თარიღები" />
        </Card>
      </Grid>
    );
  });
  //For loan_information.schedule_discrepancies.mismatched_rows create horizontal timeline of tables with following columns: payment_date, payment_amount, schedule_amount.
  const mismatched_rows = loan_information.schedule_discrepancies.mismatched_rows.map((row, index) => {
    return (
      <Grid item xs={12} key={index}>
        <Card>
          <CardHeader subheader="გრაფიკის შეუსაბამისობა გადარიცხვებთან" />
          <CardContent>
            <TableContainer
              style={{
                //Give yellowish background
                backgroundColor: "#fff9c4",
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>გადახდის თარიღი</TableCell>
                    <TableCell align="right">რაოდენობა</TableCell>
                    <TableCell align="right">გრაფიკში არსებული რაოდენობა</TableCell>
                    <TableCell align="right">განსხვავება</TableCell>
                    <TableCell align="right">სტატუსი</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {dateFormat(row.payment_date, "mmmm dS, yyyy", true)}
                    </TableCell>
                    <TableCell align="right">{row.payment_amount}</TableCell>
                    <TableCell align="right">{row.schedule_amount}</TableCell>
                    <TableCell align="right">{row.payment_amount - row.schedule_amount}</TableCell>
                    <TableCell align="right">{getStatusIcon(row)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Grid>
    );
  });

  return (
    <Grid
      container
      spacing={3}
      style={{
        margin: "70px 0",
      }}
    >
      {missing_dates}
      {mismatched_rows}
      {/* Create a green and red buttons one for approval and another for rejection */}
      {!isBank && (
        <Grid
          item
          xs={12}
          style={{
            textAlign: "right",
          }}
        >
          <Button
            onClick={() => approveDiscrepancy(loan_information._id, true)}
            variant="contained"
            color="success"
            style={{
              marginRight: "20px",
            }}
          >
            დადასტურება
          </Button>
          <Button onClick={() => approveDiscrepancy(loan_information._id, false)} variant="contained" color="error">
            უარყოფა
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
