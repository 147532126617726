import { FC, ReactNode, SetStateAction, Dispatch, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

interface Txt {
    cancelButton: string;
    triggerButton: string;
    dialogTextTitle?: string;
    dialogTextBody: string;
}

interface IDialogWindowProps {
    txt: Txt;
    openDialogWindow: boolean;
    callback?: () => void;
    children: ReactNode;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const DialogWindow: FC<IDialogWindowProps> = ({
    openDialogWindow = false,
    txt,
    callback,
    children,
    setOpen
}) => {
    const [open, setOpenState] = useState(openDialogWindow);

    useEffect(() => {
        setOpenState(openDialogWindow);
    }, [openDialogWindow]);

    const handleClose = () => {
        setOpenState(false);
        setOpen(false);
    };

    const handleTrigger = () => {
        handleClose();
        if (callback) {
            callback();
        }
    };

    return (
        <div >
            {children}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ fontSize: '12px' }}
            >
                <DialogTitle
                    id="alert-dialog-title"
                >
                    <Typography variant="body1" style={{ fontSize: '14px' }}>
                        {txt?.dialogTextTitle ?? ''}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography
                            variant="body1"
                            sx={{ fontSize: '12px' }}>
                            {txt.dialogTextBody}
                        </Typography>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={{
                        fontSize: '11px',
                        backgroundColor: 'fff',
                        margin: 1,
                        width: '100px',
                        color: 'fff'
                    }} variant="contained"
                        onClick={handleTrigger}
                        autoFocus
                    >
                        {txt.triggerButton}
                    </Button>
                    <Button sx={{
                        fontSize: '11px',
                        backgroundColor: 'fff',
                        margin: 1,
                        width: '100px',
                        color: 'fff'
                    }}
                        variant="contained"
                        onClick={handleClose}
                    >
                        {txt.cancelButton}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DialogWindow;
